import {
  Button,
  Dialog,
  DialogProps,
  experimentalStyled as styled,
  Skeleton,
  Stack,
} from '@mui/material';
import { handleLogin } from 'common/auth0';
import { formatMoney } from 'common/utils';
import Box from 'components/Box';

import Text from 'components/Text';
import PrivacyPolicy from 'features/PrivacyPolicy';
import { useGetPublicInvoiceDetailsByRefQuery } from 'generated/graphql';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const BlurryDialog = styled(Dialog)<DialogProps>(() => ({
  backdropFilter: 'blur(5px)',
  // other styles here...
}));

function LoginCard() {
  const { isMobile } = useMediaQuery();
  const [showModal, setShowModal] = useState(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const invoice = params.get('ref') || '';
  const { loading, data } = useGetPublicInvoiceDetailsByRefQuery({
    variables: {
      internalRef: invoice,
    },
  });

  const invoiceData = data?.reports_publicTransferTransactionByRef;
  localStorage.setItem('invoice', invoiceData?.id || '');
  const LoadingState = () => {
    return (
      <Box width="60%">
        <Skeleton animation="wave" />
        <Skeleton animation="wave" width={200} />
        <Skeleton animation="wave" width={200} />
        <Skeleton animation="wave" width={200} />
        <Skeleton
          variant="rectangular"
          height={118}
          style={{ margin: '10px 0' }}
        />
        <Skeleton animation="wave" width={150} />
        <Skeleton
          variant="rectangular"
          height={118}
          style={{ margin: '10px 0' }}
        />
      </Box>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingState />
      ) : (
        <Box
          width={{ _: '90%', sm: '70%', md: '60%', lg: '40%', xl: '36%' }}
          p={{
            _: '20px 20px 20px 20px',
            sm: '15px 15px 15px 15px',
            md: '30px 30px 30px 30px',
            lg: '50px 60px 50px 60px',
          }}
          mb={{
            _: 20,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0,
          }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          borderRadius={16}
          height={{
            _: '70%',
            sm: '80%',
            md: '80%',
            lg: '80%',
            xl: '80%',
          }}
          maxHeight={600}
          alignItems="center"
          backgroundColor={isMobile ? '' : '#fff'}
        >
          <BlurryDialog
            onClose={() => setShowModal(false)}
            open={showModal}
            style={{ overflow: 'hidden' }}
          >
            <PrivacyPolicy setShowTermsModal={setShowModal} />
          </BlurryDialog>
          <Text
            color="primary"
            fontSize={{ _: 24, xl: 28, lg: 24, md: 20, sm: 16 }}
            fontWeight={600}
          >
            Payment Request
          </Text>
          {/* <Box style={{marginBottom:30}}></Box> */}
          <Box alignItems="center" margin="auto" display="block">
            <Text mb={4}>
              <Text as="span" fontWeight={600}>
                {invoiceData?.receiver?.firstName}{' '}
                {invoiceData?.receiver?.lastName}
              </Text>{' '}
              is a trusted provider on the Payyd App. All provider`s raise and
              settle their invoices directly with Payyd at no cost.
            </Text>
            <Text mb={6}>
              Please accept & pay this invoice{' '}
              <Text as="span" fontWeight={600}>
                {invoiceData?.internalRef}
              </Text>{' '}
              for{' '}
              <Text as="span" fontWeight={600} color="primary">
                {formatMoney(invoiceData?.amount)} {invoiceData?.currencyCode}
              </Text>{' '}
              at your earliest convenience.
            </Text>
            <Stack direction="column" justifyContent="center" width="100%">
              <Button variant="contained" size="large" onClick={handleLogin}>
                Accept and login
              </Button>
            </Stack>
            <Box
              mx="auto"
              mt={4}
              width={isMobile ? '70%' : '40%'}
              alignItems="center"
              justifyContent="space-between"
              display="flex"
            >
              <img height="100%" width="18%" src="/securelogo2.jpg" />
              <img height="100%" width="18%" src="/securelogo4.png" />
              <img height="100%" width="18%" src="/securelogo3.jpg" />
              <img height="100%" width="18%" src="/securelogo1.png" />
            </Box>
          </Box>

          <Text fontSize={14} mt={4} textAlign="center">
            By continuing, you agree with our
            <br />
            <Box cursor="pointer">
              <Text
                color="#0060FF"
                fontSize={14}
                fontWeight={600}
                onClick={() => setShowModal(true)}
              >
                Policy & Terms
              </Text>
            </Box>
          </Text>
        </Box>
      )}
    </>
  );
}

export default LoginCard;
