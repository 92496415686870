import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField as MTextField,
} from '@mui/material';
import { message } from 'antd';
import Box from 'components/Box';
import PageLayout from 'components/PageLayout';
import Text from 'components/Text';
import { selectWalletUser } from 'features/InvoicePay/slice';
import {
  ContactSupportInput,
  useAddUserSupportMutation,
} from 'generated/graphql';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/svg/back.svg';

function Support() {
  const [userSupport] = useAddUserSupportMutation();
  const [selectedSupport, setSelectedSupport] = useState('');
  const walletUser = useSelector(selectWalletUser);
  const [notes, setNotes] = useState('');
  const history = useHistory();
  const handleSubmit = async () => {
    const createPayload: ContactSupportInput = {
      email: walletUser.emailAddress,
      name: walletUser.firstName,
      subject: selectedSupport,
      body: notes,
    };
    const data = await userSupport({
      variables: { data: createPayload },
    });
    // @ts-ignore
    if (data?.data?.subscription_contactSupport.data) {
      message.info(
        'Thank you for submitting your inquiry. We will get back to you within a few hours.',
      );
      history.push('/dashboard');
    } else {
      message.error('Something went Wrong, Try again.');
    }
  };
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSupport(event.target.value as string);
  };

  return (
    <PageLayout>
      <Box maxWidth={1500} margin="auto">
        <Box
          onClick={() => history.goBack()}
          display="flex"
          cursor="pointer"
          mb={20}
          mt={20}
          ml={40}
          alignItems="center"
        >
          <BackIcon />
          <Text fontWeight={500} ml={15}>
            Back
          </Text>
        </Box>
        <Box width="100%" p="20px" display="flex">
          <Box textAlign="center" maxWidth={550} width="100%" m="0 auto">
            <Text fontSize={27} mt={6} mb={4} fontWeight={500}>
              Support
            </Text>
            <Text mb={8}>
              Please tell us your issue as brief as possible so we can respond
              via email or phone when necessary.
            </Text>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                Select subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedSupport}
                onChange={handleChange}
                label="Age"
              >
                <MenuItem value={'Technical Support'}>
                  Technical Support
                </MenuItem>
                <MenuItem value={'Transactional Support'}>
                  Transactional Support
                </MenuItem>
              </Select>
            </FormControl>
            <Box mt={4}></Box>
            <MTextField
              // component={TextField}
              type="text"
              label="Notes"
              name="Notes"
              multiline
              fullWidth
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              id="outlined-multiline-static"
              rows={12}
            />
            <Stack
              spacing={2}
              direction="column"
              width={240}
              margin="20px auto"
            >
              <Button
                variant="contained"
                size="large"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
}

export default Support;
