import { Divider, Stack } from '@mui/material';
// import animationData from 'assets/lottie/success.json';
import Box from 'components/Box';
import Text from 'components/Text';
import { useGetInvoiceDetailsByRefQuery } from 'generated/graphql';
import { createRef, useState } from 'react';
// import Lottie from 'react-lottie';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from '../../assets/svg/successful.svg';
// import Loader from 'common/loader';
import { message } from 'antd';
import ReceiverAppLoader from 'common/ReceiverAppLoader';

const ref = createRef();
const PaymentReceipt = () => {
  const { search } = useLocation();
  const [showDetails, setShowDetails] = useState(false);
  const params = new URLSearchParams(search);
  const merchantId = params.get('merchantReferenceId') || '';

  const { data, loading } = useGetInvoiceDetailsByRefQuery({
    variables: { internalRef: merchantId },
  });

  const invoice = data?.reports_transferTransactionByRef;

  return (
    <Box width="100%" display="flex">
      {loading && <ReceiverAppLoader disableScroll={loading} />}
      <Box textAlign="center" p="20px" maxWidth={880} width="100%" m="0 auto">
        <div
          // @ts-ignore
          ref={ref}
          style={{ backgroundColor: '#fff' }}
        >
          <Box width={120} height={80} m="0 auto">
            <SuccessIcon />
          </Box>
          <Text fontSize={32} my={4} color="#20294C" fontWeight={600}>
            Payment Successful!
          </Text>
          <Text fontSize={18} mb={4} color="#697386" fontWeight={500}>
            Your transaction has been processed successfully. Thank you for your
            transacting with Payyd!
          </Text>
          <Box
            mx="auto"
            onClick={() => {
              if (invoice?.amount) return setShowDetails(!showDetails);
              return message.error('Transaction data not found!');
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '300px',
              padding: '10px',
              borderRadius: '50px',
              cursor: 'pointer',
              backgroundColor: '#F3F8FD',
            }}
          >
            <Text fontSize={18} color="#20294C" mr={2} fontWeight={600}>
              {showDetails ? 'Hide' : 'View'} Transaction Details
            </Text>
            {showDetails ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </Box>
          {showDetails && (
            <Stack
              style={{
                backgroundColor: '#F3F8FD',
                padding: '16px',
                borderRadius: '10px',
              }}
              mt={3}
              direction="column"
              maxWidth={550}
              mx="auto"
              width="90%"
              spacing="10px"
            >
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} fontWeight={500} color="#697386">
                  Status:
                </Text>
                <Text
                  fontSize={16}
                  fontWeight={700}
                  color="#20294C"
                  textAlign="right"
                >
                  Completed
                </Text>
              </Box>
              <Divider />
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} fontWeight={500} color="#697386">
                  Payment Date:
                </Text>
                <Text
                  fontSize={16}
                  fontWeight={700}
                  textAlign="right"
                  color="#20294C"
                >
                  {invoice?.modifiedOn?.split('T')[0]}
                </Text>
              </Box>
              <Divider />
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} fontWeight={500} color="#697386">
                  Reference ID:
                </Text>
                <Text fontSize={16} fontWeight={700} textAlign="right">
                  {
                    // @ts-ignore
                    invoice?.internalRef
                  }
                </Text>
              </Box>
              <Divider />
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} color="#697386">
                  Name:
                </Text>
                <Text
                  fontSize={16}
                  textAlign="right"
                  fontWeight={700}
                  color="#20294C"
                >
                  {`${
                    // @ts-ignore
                    invoice?.receiver?.firstName
                  } ${
                    // @ts-ignore
                    invoice?.receiver?.lastName
                  }`}
                </Text>
              </Box>
              <Divider />
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} color="#697386">
                  Email:
                </Text>
                <Text
                  fontSize={16}
                  textAlign="right"
                  fontWeight={700}
                  color="#20294C"
                >
                  {
                    // @ts-ignore
                    invoice?.receiver?.emailAddress
                  }
                </Text>
              </Box>
              <Divider />
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} color="#697386">
                  Settlement Amount:
                </Text>
                <Text
                  fontSize={16}
                  textAlign="right"
                  fontWeight={700}
                  color="#20294C"
                >
                  {`${parseFloat(
                    // @ts-ignore
                    invoice?.amount ? invoice?.amount.toString() : '0.00',
                  ).toFixed(2)} `}
                  {
                    // @ts-ignore
                    invoice?.currencyCode
                  }
                </Text>
              </Box>
              <Divider />
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} color="#697386">
                  Processing Fee:
                </Text>
                <Text
                  fontSize={16}
                  textAlign="right"
                  fontWeight={700}
                  color="#20294C"
                >
                  {`${parseFloat(
                    // @ts-ignore
                    invoice?.totalAmount
                      ? // @ts-ignore
                        invoice?.processingFee
                      : '0.00',
                  ).toFixed(2)} `}
                  {
                    // @ts-ignore
                    invoice?.currencyCode
                  }
                </Text>
              </Box>
              <Divider />
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} color="#697386">
                  Tax:
                </Text>
                <Text
                  fontSize={16}
                  textAlign="right"
                  fontWeight={700}
                  color="#20294C"
                >
                  {`${parseFloat(
                    // @ts-ignore
                    invoice?.totalAmount ? invoice?.tax?.toString() : '0.00',
                  ).toFixed(2)} `}
                  {
                    // @ts-ignore
                    invoice?.currencyCode
                  }
                </Text>
              </Box>
              <Divider />
              <Box justifyContent="space-between" display="flex">
                <Text fontSize={16} fontWeight={500} color="#697386">
                  Total:
                </Text>
                <Text
                  fontSize={16}
                  textAlign="right"
                  color="#20294C"
                  fontWeight={700}
                >
                  {`${parseFloat(
                    // @ts-ignore
                    invoice?.totalAmount
                      ? // @ts-ignore
                        invoice?.totalAmount.toString()
                      : '0.00',
                  ).toFixed(2)} `}
                  {
                    // @ts-ignore
                    invoice?.currencyCode
                  }
                </Text>
              </Box>
            </Stack>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default PaymentReceipt;
