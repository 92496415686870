import Box from 'components/Box';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const PageLayout = ({ children }: Props) => {
  return (
    <Box
      width="100%"
      // height= "80vh"
      minHeight="75vh"
      px={{ _: 20, md: 40, lg: 100 }}
      py={{ _: 20, md: 40 }}
    >
      <Box maxWidth={1500} minHeight="100%" width="100%" margin="auto">
        {children}
      </Box>
    </Box>
  );
};

export default PageLayout;
