import {
  Autocomplete,
  Box as MBox,
  Button,
  Stack,
  TextField as MTextField,
  TextField,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { message } from 'antd';
import { handleGetSession } from 'common/auth0';
import Box from 'components/Box';
import PageLayout from 'components/PageLayout';
import Text from 'components/Text';
import {
  GetwalletCountriesIso,
  LegalEntity,
  UpdateUserInput,
  useGetWalletUserBySubQuery,
  User,
  useUpdateWalletUserMutation,
} from 'generated/graphql';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

function EditProfile() {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profileDetails, setProfileDetails] = useState<User>();
  const currentSession = handleGetSession();
  const [countryCodeForPayment, setCountryCodeForPayment] = useState('');
  const [taxId, setTaxId] = useState('');

  const [legalEntityName, setlegalEntityName] = useState<LegalEntity>();

  const [updateWalletUser] = useUpdateWalletUserMutation();

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneCode, setPhoneCode] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const [userCountry, setUserCountry] = useState({
    name: '',
    CountryIsoCode3: '',
    phoneCountryCode: '',
  });

  let IsoCode3 = {
    nodes: [
      {
        name: '',
        countryIsoCode3: '',
        phoneCountryCode: '',
      },
    ],
  };

  const handleChangePhoneNumber = (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
    country,
  ) => {
    const temp = value.toString().slice(country.dialCode.toString().length + 1);
    setPhoneCode(country.dialCode.toString());
    setPhoneNumber(temp);
  };
  const history = useHistory();
  const CountryIsoCode3 = GetwalletCountriesIso({
    // @ts-ignore
    variables: { first: 300 },
  });
  const { data } = CountryIsoCode3;
  if (data) {
    // @ts-ignore
    IsoCode3 = data?.wallet_sendCountries;
  }
  const { data: walletUserData } = useGetWalletUserBySubQuery({
    // @ts-ignore
    variables: { sub: currentSession!.sub },
  });

  useEffect(() => {
    if (walletUserData?.wallet_userBySub && IsoCode3.nodes.length) {
      const data = walletUserData?.wallet_userBySub as User;
      setProfileDetails(data);
      // @ts-ignore
      setFirstName(data.firstName);
      // @ts-ignore
      setLastName(data.lastName);
      // @ts-ignore
      setMiddleName(data.middleName);
      // @ts-ignore
      setEmail(data.emailAddress);
      // @ts-ignore
      setPhoneNumber(data.phoneNumber?.toString());
      // @ts-ignore
      setCountryCodeForPayment(data.countryIsoCode3);
      // @ts-ignore
      setPhoneCode(data.phoneCountryCode);
      // @ts-ignore
      setTaxId(data.taxID);
      // @ts-ignore
      setlegalEntityName(data?.legalEntity);
      const country = IsoCode3.nodes.find(
        (country) => country.countryIsoCode3 === data.countryIsoCode3,
      );
      // @ts-ignore
      setUserCountry(country);
      setRefresh(true);
    }
  }, [walletUserData, data?.wallet_sendCountries]);

  const handleUpdateWalletUser = async () => {
    // update Wallet

    const updatePayload: UpdateUserInput = {
      // @ts-ignore
      id: walletUserData.wallet_userBySub.id,
      // @ts-ignore
      user: {
        firstName,
        middleName,
        // @ts-ignore
        emailAddress: walletUserData?.wallet_userBySub?.emailAddress,
        lastName,
        countryIsoCode3: countryCodeForPayment,
        phoneNumber: phoneNumber.replace(/\D/g, ''),
        phoneCountryCode: phoneCode,
        taxID: taxId,
      },
    };
    try {
      const data = await updateWalletUser({
        variables: { data: updatePayload },
      });
      // @ts-ignore
      if (data.data.wallet_updateUser.data.id) {
        message.info('Profile Updated Successfully.');
        history.go(0);
      } else {
        message.info('Something Went Wrong.');
      }
    } catch (error) {
      // @ts-ignore
      message.error(error?.message);
    }
  };

  return (
    <PageLayout>
      <Box width="100%" p="20px" display="flex">
        <Box textAlign="center" maxWidth={550} width="100%" m="0 auto">
          <Text fontSize={27} mt={6} mb={4} fontWeight={500} color="#000">
            Edit Profile
          </Text>

          <Box justifyContent="center" alignItems="center" display="flex">
            <Avatar // @ts-ignore
              alt={profileDetails?.firstName}
              sx={{ width: 80, height: 80, marginRight: '20px' }}
              src="/static/images/avatar/1.jpg"
            />
            {/* <Stack spacing={2} direction="column" width={150} >
              <input
                accept="image/*"
                style={{ display: 'none', marginBottom: '10px', marginLeft: "20px", zIndex: 1 }}
                id="raised-button-file"
                multiple
                onChange={handleCapture}
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" size="large" sx={{ backgroundColor: '#F5F5F5', color: '#000' }}>
                  Change Photo
                </Button>
              </label>
            </Stack> */}
          </Box>
          <Text
            mt={18}
            fontSize={18}
            fontWeight={500}
            textAlign="left"
            color="#000"
          >
            Personal Information
          </Text>
          {legalEntityName?.id && (
            <TextField
              type="text"
              value={legalEntityName.legalName}
              fullWidth
              sx={{ marginBottom: '18px' }}
              disabled
              label="Business Name"
              variant="standard"
            />
          )}

          <TextField
            type="text"
            value={firstName}
            fullWidth
            sx={{ marginBottom: '18px' }}
            onChange={(e) => setFirstName(e.target.value)}
            label="First Name"
            variant="standard"
          />
          <TextField
            type="text"
            fullWidth
            value={middleName}
            sx={{ marginBottom: '18px' }}
            onChange={(e) => setMiddleName(e.target.value)}
            label="Middle Name"
            variant="standard"
          />
          <TextField
            type="text"
            value={lastName}
            sx={{ marginBottom: '18px' }}
            fullWidth
            onChange={(e) => setLastName(e.target.value)}
            label="Last Name"
            variant="standard"
          />
          <TextField
            type="text"
            fullWidth
            disabled
            value={email}
            sx={{ marginBottom: '18px' }}
            label="Email Address"
            variant="standard"
          />
          {legalEntityName?.id ? (
            <TextField
              type="text"
              value={legalEntityName?.registrationNumber}
              fullWidth
              disabled
              sx={{ marginBottom: '18px' }}
              label="Tax ID"
              variant="standard"
            />
          ) : (
            <TextField
              type="text"
              value={taxId}
              fullWidth
              onChange={(e) => setTaxId(e.target.value)}
              sx={{ marginBottom: '18px' }}
              label="Tax ID"
              variant="standard"
            />
          )}
          {refresh && (
            <Autocomplete
              id="country-select-demo"
              // @ts-ignore
              options={IsoCode3.nodes}
              defaultValue={{
                name: userCountry?.name,
                countryIsoCode3: userCountry?.CountryIsoCode3,
                phoneCountryCode: userCountry?.phoneCountryCode,
              }}
              sx={{ marginBottom: '18px' }}
              autoHighlight
              // @ts-ignore
              getOptionLabel={(option) => option?.name || ''}
              renderOption={(props, option) => (
                <MBox
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    // @ts-ignore
                    src={`https://flagcdn.com/w20/${option.countryIsoCode2.toLowerCase()}.png`}
                    // @ts-ignore
                    srcSet={`https://flagcdn.com/w40/${option.countryIsoCode2.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  <button
                    style={{
                      border: '0px',
                      backgroundColor: 'transparent',
                    }}
                    onClick={(e) => {
                      const selectedCountry = e.currentTarget.innerText.slice(
                        -3,
                      );
                      // @ts-ignore
                      setCountryCodeForPayment(selectedCountry);

                      // setFieldValue(
                      //   'country',
                      //   e.currentTarget.innerText,
                      // );
                    }}
                    // @ts-ignore
                  >
                    {option?.name || ''} (+
                    {option?.phoneCountryCode || ''}){' '}
                    {option?.countryIsoCode3 || ''}
                  </button>
                </MBox>
              )}
              renderInput={(params) => (
                <MTextField
                  variant="standard"
                  {...params}
                  label="Select country"
                  value={userCountry?.name}
                  inputProps={{
                    ...params.inputProps,

                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )}
          {/* <TextField
            type="text"
            value={userCountry.name}
            sx={{ marginBottom: '18px' }}
            fullWidth
            label="Selected Country"
            disabled
            variant="standard"
          /> */}

          {phoneCode.length > 0 && (
            <MuiPhoneNumber
              // component={MuiPhoneNumber}
              label="Mobile Number"
              // defaultCountry={userCountry.CountryIsoCode3?.slice(0, -1) || 'us'}
              fullWidth
              value={'+' + phoneCode + phoneNumber}
              sx={{ marginBottom: '18px' }}
              disableAreaCodes
              // @ts-ignore
              onChange={(value, country) => {
                if (country.dialCode === phoneCode) {
                  handleChangePhoneNumber(value, country);
                } else {
                  handleChangePhoneNumber('', country);
                }
              }}
            />
          )}
          <Stack spacing={2} direction="column" width={240} margin="20px auto">
            <Button
              variant="contained"
              size="large"
              onClick={async () => await handleUpdateWalletUser()}
            >
              Save Changes
            </Button>
          </Stack>
        </Box>
      </Box>
    </PageLayout>
  );
}

export default EditProfile;
