import Box from 'components/Box';
import Text from 'components/Text';
import { useMediaQuery } from 'hooks/useMediaQuery';
import heroBg from '../../assets/backgound2.png';
import LoginCard from './LoginCard';

function PayForm() {
  const { isMedium, isLarge, isSmall, isMobileOrTab } = useMediaQuery();
  return (
    <Box
      // width="100%"
      maxWidth="1500px"
      margin="auto"
      height={isLarge ? '90vh' : isMedium ? '90vh' : isSmall ? '90vh' : '85vh'}
      display="flex"
      alignItems="center"
      zIndex={2}
      justifyContent={{
        _: 'flex-start',
        md: 'center',
        lg: 'center',
        xl: 'center',
        sm: 'flex-start',
      }}
      flexDirection={{
        _: 'column',
        sm: 'column',
        md: 'row',
        lg: 'row',
        xl: 'row',
      }}
    >
      <Box width="60%">
        {!isMobileOrTab && (
          <Text
            fontSize={{ _: 34, sm: 30, md: 30, lg: 30, xl: 40 }}
            style={{ fontWeight: 'normal' }}
            color="#fff"
            textAlign={'left'}
          >
            Next-Generation invoicing for the open world.
          </Text>
        )}
        {!isMobileOrTab && (
          <Box
            mb={{ _: 20, md: 30, lg: 16 }}
            height="50px"
            display="flex"
            alignItems="center"
            justifyItems="center"
          >
            <Text
              fontSize={{ _: 16, sm: 16, md: 16, lg: 18, xl: 24 }}
              fontWeight={400}
              color="#fff"
            >
              4000+ Businesses & Entrepreneurs trust Payyd for their
              international invoices
            </Text>
          </Box>
        )}
        {!isMobileOrTab && (
          <img
            style={
              isMedium
                ? { width: '90%' }
                : isLarge
                ? { width: '90%' }
                : { width: '90%' }
            }
            src={heroBg}
            alt="hero"
          />
        )}
      </Box>
      <LoginCard />
    </Box>
  );
}

export default PayForm;
