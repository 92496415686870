import { Button, Skeleton } from '@mui/material';
import Box from 'components/Box';
import Text from 'components/Text';
import { useGetTransferTransactionPaidByIdLazyQuery } from 'generated/graphql';
import MUIDataTable from 'mui-datatables';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import heroBg from '../../assets/no-record.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { formatMoney } from 'common/utils';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

interface PendingProps {
  walletUserData?: any;
}
const Transactions = ({ walletUserData }: PendingProps) => {
  // const getMuiTheme = () =>
  //   createTheme({
  //     components: {
  //       MuiTableCell: {
  //         styleOverrides: {
  //           root: {
  //             // padding: '8px',
  //             // fontWeight:800,
  //             // color:"red",
  //             backgroundColor: '#F8F8F8',
  //           },
  //         },
  //       },
  //       MuiTableHead: {
  //         styleOverrides: {
  //           root: {
  //             backgroundColor: 'red',
  //           },
  //         },
  //       },
  //       MuiToolbar: {
  //         styleOverrides: {
  //           regular: {
  //             minHeight: '8px',
  //           },
  //         },
  //       },
  //     },
  //   });
  const history = useHistory();
  const [
    getPaidPayments,
    { data: paidData, loading },
  ] = useGetTransferTransactionPaidByIdLazyQuery({
    // @ts-ignore
    variables: { userId: walletUserData?.wallet_userBySub?.id, first: 40 },
  });

  useEffect(() => {
    try {
      getPaidPayments();
    } catch (e) {
      console.log(e);
    }
  }, []);
  const columns = [
    {
      name: 'id',
      label: '',
      options: {
        display: false,
      },
    },
    {
      name: 'fullName',
      label: 'Paid to',

      options: {
        customBodyRender: (value) => {
          return <Text style={{ fontWeight: 400 }}>{value}</Text>;
        },

        filter: false,
        sort: true,
      },
    },
    {
      name: 'date',
      label: 'Paid Date',
      options: {
        customBodyRender: (value) => {
          return <Text style={{ fontWeight: 400 }}>{value}</Text>;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: 'totalAmount',
      label: 'Amount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Text style={{ fontWeight: 400 }}>
              {formatMoney(value.split(' ')[0])} {value.split(' ')[1]}
            </Text>
          );
        },
      },
    },

    {
      name: 'transferTransactionStatusDescription',
      label: 'Status',

      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Text
              style={{
                color:
                  value === 'Funds_Collected'
                    ? '#6BBE2C'
                    : value === 'EXPIRED'
                    ? '#3D4251'
                    : value === 'INITIATED'
                    ? '#3D4251'
                    : value === 'ERROR'
                    ? '#FA527A'
                    : '#FA527A',
                fontWeight: 600,
              }}
            >
              {value === 'Funds_Collected'
                ? 'Completed'
                : value === 'EXPIRED'
                ? 'Cancelled'
                : value === 'INITIATED'
                ? 'Processing'
                : value === 'ERROR'
                ? 'Rejected'
                : value}
            </Text>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Action',

      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Button
              variant="text"
              onClick={() =>
                history.push({
                  pathname: `/transaction/${value}`,
                  state: {
                    data: paidData?.reports_transferTransactionsByUserId?.nodes?.filter(
                      (e) => e.id === value,
                    )[0],
                  },
                })
              }
              endIcon={<ArrowForwardIosIcon />}
            >
              <strong>View</strong>
            </Button>
          );
        },
      },
    },
  ];
  const LoadingState = () => {
    return (
      <Box width="80%">
        <Skeleton animation="wave" />
        <Skeleton animation="wave" width={200} />
        <Skeleton
          variant="rectangular"
          height={118}
          style={{ margin: '10px 0' }}
        />
        <Skeleton animation="wave" width={150} />
      </Box>
    );
  };
  const columnVisible = {
    transferTransactionStatusDescription: true,
    amount: false,
  };
  const options = {
    // filterType: 'checkbox',
    selectableRows: false,
    onRowClick: (e) => {
      history.push({
        pathname: `/transaction/${e[0]}`,
        state: {
          data: paidData?.reports_transferTransactionsByUserId?.nodes?.filter(
            (ev) => ev.id === e[0],
          )[0],
        },
      });
    },
    responsive: 'stacked',

    downloadOptions: {
      filename: 'PayydPaidInvoices.csv',
      separator: ',',
    },
  };
  return (
    // <ThemeProvider theme={getMuiTheme()}>
    <Box>
      {
        // @ts-ignore
        paidData?.reports_transferTransactionsByUserId.nodes.length > 0 ? (
          <MUIDataTable
            title={<h2 style={{ fontWeight: 700 }}>Payment History</h2>}
            // @ts-ignore
            data={paidData?.reports_transferTransactionsByUserId.nodes.map(
              (item) => {
                return {
                  ...item,
                  // @ts-ignore
                  fullName:
                    // @ts-ignore
                    item?.receiver.firstName + ' ' + item?.receiver.lastName,
                  totalAmount: item.totalAmount + ' ' + item.currencyCode,
                  date: item.modifiedOn?.split('T')[0],
                };
              },
            )}
            columns={columns}
            options={options}
            columnVisibilityModel={columnVisible}
          />
        ) : (
          <>
            {!loading ? (
              <>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img src={heroBg} alt="No transaction Found" />
                </Box>
                <Text
                  fontSize={16}
                  color="#6C7694"
                  fontWeight={600}
                  textAlign="center"
                >
                  No Payment History
                </Text>
              </>
            ) : (
              <LoadingState />
            )}
          </>
        )
      }
    </Box>
    // </ThemeProvider>
  );
};

export default Transactions;
