import Box from 'components/Box';
import PageLayout from 'components/PageLayout';
import processingBg from '../../assets/processingBg.png';
import PaymentReceipt from './PaymentReceipt';
import Logo from 'components/Logo';

function CheckoutProcessing() {
  return (
    <PageLayout>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Logo invoice width={150} height={30} />
        <Box
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <img src={processingBg} alt="Invoice" />
        </Box>
      </Box>
      <Box
        maxWidth={{ _: 600, lg: 2280 }}
        width="100%"
        display="flex"
        justifyContent="space-between"
        mx="auto"
        flexDirection={{ _: 'column-reverse', lg: 'row' }}
      >
        <PaymentReceipt />
      </Box>
    </PageLayout>
  );
}

export default CheckoutProcessing;
