import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/app.less';
import 'styles/global.less';
import App from 'app';
import store from 'app/store';
import reportWebVitals from 'reportWebVitals';
import styledTheme from 'styles/theme';
import muiTheme from 'styles/mui-theme';
import { Provider } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'apolloClient';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <StyledThemeProvider theme={styledTheme}>
          <MuiThemeProvider theme={muiTheme}>
            <App />
          </MuiThemeProvider>
        </StyledThemeProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
