import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  {
    [SubKey in K]?: Maybe<T[SubKey]>;
  };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  {
    [SubKey in K]: Maybe<T[SubKey]>;
  };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: any;
  UUID: any;
};

export type AccountBalance = Node & {
  __typename?: 'AccountBalance';
  userId: Scalars['ID'];
  currencyCode?: Maybe<Scalars['String']>;
  currentBalance: Scalars['Float'];
  availableBalance: Scalars['Float'];
  priceInUSDC: Scalars['Float'];
  priceSpreadInUSDC: Scalars['Float'];
  currencyType: CurrencyType;
  user?: Maybe<User>;
  createdById: Scalars['ID'];
  modifiedById?: Maybe<Scalars['ID']>;
  createdOn: Scalars['DateTime'];
  modifiedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  currencyTypeDescription?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
};

export type AccountBalanceFilterInput = {
  and?: Maybe<Array<AccountBalanceFilterInput>>;
  or?: Maybe<Array<AccountBalanceFilterInput>>;
  userId?: Maybe<IdOperationFilterInput>;
  currencyCode?: Maybe<StringOperationFilterInput>;
  currentBalance?: Maybe<ComparableDoubleOperationFilterInput>;
  availableBalance?: Maybe<ComparableDoubleOperationFilterInput>;
  priceInUSDC?: Maybe<ComparableDoubleOperationFilterInput>;
  priceSpreadInUSDC?: Maybe<ComparableDoubleOperationFilterInput>;
  currencyType?: Maybe<CurrencyTypeOperationFilterInput>;
  user?: Maybe<UserFilterInput>;
  createdById?: Maybe<IdOperationFilterInput>;
  modifiedById?: Maybe<IdOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOffsetOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
};

export type AccountBalanceSortInput = {
  userId?: Maybe<SortEnumType>;
  currencyCode?: Maybe<SortEnumType>;
  currentBalance?: Maybe<SortEnumType>;
  availableBalance?: Maybe<SortEnumType>;
  priceInUSDC?: Maybe<SortEnumType>;
  priceSpreadInUSDC?: Maybe<SortEnumType>;
  currencyType?: Maybe<SortEnumType>;
  user?: Maybe<UserSortInput>;
  createdById?: Maybe<SortEnumType>;
  modifiedById?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  modifiedOn?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export type Activity = {
  __typename?: 'Activity';
  activityStatus: ActivityStatus;
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
  shortDesc?: Maybe<Scalars['String']>;
  longDesc?: Maybe<Scalars['String']>;
};

export type ActivityFilterInput = {
  and?: Maybe<Array<ActivityFilterInput>>;
  or?: Maybe<Array<ActivityFilterInput>>;
  userId?: Maybe<IdOperationFilterInput>;
  shortDesc?: Maybe<StringOperationFilterInput>;
  longDesc?: Maybe<StringOperationFilterInput>;
  activityStatus?: Maybe<ActivityStatusOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
};

export type ActivitySortInput = {
  userId?: Maybe<SortEnumType>;
  shortDesc?: Maybe<SortEnumType>;
  longDesc?: Maybe<SortEnumType>;
  activityStatus?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export enum ActivityStatus {
  Unread = 'UNREAD',
  Read = 'READ',
  Deleted = 'DELETED',
}

export type ActivityStatusOperationFilterInput = {
  eq?: Maybe<ActivityStatus>;
  neq?: Maybe<ActivityStatus>;
  in?: Maybe<Array<ActivityStatus>>;
  nin?: Maybe<Array<ActivityStatus>>;
};

export type AddBankAccountInput = {
  userId: Scalars['ID'];
  bankAccount?: Maybe<BankAccountInput>;
};

export type AddLegalEntityAddressInput = {
  userId: Scalars['ID'];
  address?: Maybe<AddressInput>;
};

export type AddLegalEntityInput = {
  userId: Scalars['ID'];
  legalEntity: LegalEntityInput;
  address?: Maybe<AddressInput>;
};

export type AddSenderUserInput = {
  sub: Scalars['String'];
  user: UserInput;
  transferTransaction: TransferTransactionInput;
  address?: Maybe<SenderAddressInput>;
};

export type AddTransferTransactionStatusHistoryInput = {
  transferTransactionStatusHistoryType: TransferTransactionStatusHistoryType;
  valueDate?: Maybe<Scalars['DateTime']>;
  transferTransactionId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
};

export type AddUserAddressInput = {
  userId: Scalars['ID'];
  address?: Maybe<AddressInput>;
};

export type AddUserInput = {
  sub: Scalars['String'];
  user: UserInput;
  otp?: Maybe<OtpInput>;
  address?: Maybe<AddressInput>;
};

export type AddressInput = {
  buildingNumber?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateIsoCode2?: Maybe<Scalars['String']>;
  countryIsoCode3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SenderAddressInput = {
  buildingNumber?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateIsoCode2?: Maybe<Scalars['String']>;
  countryIsoCode3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export enum AddressType {
  Permanent = 'PERMANENT',
  Current = 'CURRENT',
  Correspondence = 'CORRESPONDENCE',
  Other = 'OTHER',
}

export type AmountRangePerCurrency = {
  __typename?: 'AmountRangePerCurrency';
  currency?: Maybe<Scalars['String']>;
  maximumAmount?: Maybe<Scalars['Int']>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER',
}

export type AvailableBook = {
  __typename?: 'AvailableBook';
  book?: Maybe<Scalars['String']>;
  minimumAmount?: Maybe<Scalars['String']>;
  maximumAmount?: Maybe<Scalars['String']>;
  minimumPrice?: Maybe<Scalars['String']>;
  maximumPrice?: Maybe<Scalars['String']>;
  minimumValue?: Maybe<Scalars['String']>;
  maximumValue?: Maybe<Scalars['String']>;
};

export type AvailableBookFilterInput = {
  and?: Maybe<Array<AvailableBookFilterInput>>;
  or?: Maybe<Array<AvailableBookFilterInput>>;
  book?: Maybe<StringOperationFilterInput>;
  minimumAmount?: Maybe<StringOperationFilterInput>;
  maximumAmount?: Maybe<StringOperationFilterInput>;
  minimumPrice?: Maybe<StringOperationFilterInput>;
  maximumPrice?: Maybe<StringOperationFilterInput>;
  minimumValue?: Maybe<StringOperationFilterInput>;
  maximumValue?: Maybe<StringOperationFilterInput>;
};

export type AvailableBookSortInput = {
  book?: Maybe<SortEnumType>;
  minimumAmount?: Maybe<SortEnumType>;
  maximumAmount?: Maybe<SortEnumType>;
  minimumPrice?: Maybe<SortEnumType>;
  maximumPrice?: Maybe<SortEnumType>;
  minimumValue?: Maybe<SortEnumType>;
  maximumValue?: Maybe<SortEnumType>;
};

export type BankAccount = Node & {
  __typename?: 'BankAccount';
  userId: Scalars['ID'];
  accountNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  bankAccountType: BankAccountType;
  currencyCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  branchCode?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  iFSCCode?: Maybe<Scalars['String']>;
  userBankAccountType?: Maybe<UserBankAccountType>;
  id: Scalars['ID'];
  bankAccountTypeDescription?: Maybe<Scalars['String']>;
  userBankAccountTypeDescription?: Maybe<Scalars['String']>;
};

export type BankAccountFilterInput = {
  and?: Maybe<Array<BankAccountFilterInput>>;
  or?: Maybe<Array<BankAccountFilterInput>>;
  userId?: Maybe<IdOperationFilterInput>;
  accountNumber?: Maybe<StringOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  businessName?: Maybe<StringOperationFilterInput>;
  bankAccountType?: Maybe<BankAccountTypeOperationFilterInput>;
  currencyCode?: Maybe<StringOperationFilterInput>;
  bankName?: Maybe<StringOperationFilterInput>;
  branchCode?: Maybe<StringOperationFilterInput>;
  swiftCode?: Maybe<StringOperationFilterInput>;
  iFSCCode?: Maybe<StringOperationFilterInput>;
  userBankAccountType?: Maybe<NullableOfUserBankAccountTypeOperationFilterInput>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
};

export type BankAccountInput = {
  accountNumber: Scalars['String'];
  currencyCode: Scalars['String'];
  bankName: Scalars['String'];
  bankAccountType: BankAccountType;
  userBankAccountType: UserBankAccountType;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  branchCode?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  iFSCCode?: Maybe<Scalars['String']>;
};

export type BankAccountSortInput = {
  userId?: Maybe<SortEnumType>;
  accountNumber?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  businessName?: Maybe<SortEnumType>;
  bankAccountType?: Maybe<SortEnumType>;
  currencyCode?: Maybe<SortEnumType>;
  bankName?: Maybe<SortEnumType>;
  branchCode?: Maybe<SortEnumType>;
  swiftCode?: Maybe<SortEnumType>;
  iFSCCode?: Maybe<SortEnumType>;
  userBankAccountType?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export enum BankAccountType {
  Savings = 'SAVINGS',
  Current = 'CURRENT',
}

export type BankAccountTypeOperationFilterInput = {
  eq?: Maybe<BankAccountType>;
  neq?: Maybe<BankAccountType>;
  in?: Maybe<Array<BankAccountType>>;
  nin?: Maybe<Array<BankAccountType>>;
};

export type BankTransfer = Node & {
  __typename?: 'BankTransfer';
  messageId?: Maybe<Scalars['String']>;
  internalRef?: Maybe<Scalars['String']>;
  externalRef?: Maybe<Scalars['String']>;
  bankTransferStatus: BankTransferStatus;
  fxRate: Scalars['Float'];
  serviceFeeCurrencyCode?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['Float']>;
  deliveryFeeCurrencyCode?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<Scalars['Float']>;
  createdById: Scalars['ID'];
  modifiedById?: Maybe<Scalars['ID']>;
  createdOn: Scalars['DateTime'];
  modifiedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  sender?: Maybe<BankTransferSender>;
  receiver?: Maybe<BankTransferReceiver>;
};

export type BankTransferFeeAndRateInput = {
  fromCurrencyCode?: Maybe<Scalars['String']>;
  toCountryIsoCode3?: Maybe<Scalars['String']>;
  toCurrencyCode?: Maybe<Scalars['String']>;
  fromAmount: Scalars['Float'];
};

export type BankTransferFilterInput = {
  and?: Maybe<Array<BankTransferFilterInput>>;
  or?: Maybe<Array<BankTransferFilterInput>>;
  messageId?: Maybe<StringOperationFilterInput>;
  internalRef?: Maybe<StringOperationFilterInput>;
  externalRef?: Maybe<StringOperationFilterInput>;
  bankTransferStatus?: Maybe<BankTransferStatusOperationFilterInput>;
  fxRate?: Maybe<ComparableDoubleOperationFilterInput>;
  serviceFeeCurrencyCode?: Maybe<StringOperationFilterInput>;
  serviceFee?: Maybe<ComparableNullableOfDoubleOperationFilterInput>;
  deliveryFeeCurrencyCode?: Maybe<StringOperationFilterInput>;
  deliveryFee?: Maybe<ComparableNullableOfDoubleOperationFilterInput>;
  createdById?: Maybe<IdOperationFilterInput>;
  modifiedById?: Maybe<IdOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOffsetOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
};

export type BankTransferReceiver = Node & {
  __typename?: 'BankTransferReceiver';
  userId?: Maybe<Scalars['ID']>;
  userBankAccountType?: Maybe<UserBankAccountType>;
  infoName?: Maybe<Scalars['String']>;
  infoPostalAddress?: Maybe<Scalars['String']>;
  infoCountryIsoCode2OfBirth?: Maybe<Scalars['String']>;
  infoProvinceOfBirth?: Maybe<Scalars['String']>;
  infoCityOfBirth?: Maybe<Scalars['String']>;
  infoProprietary?: Maybe<Scalars['String']>;
  infoId?: Maybe<Scalars['String']>;
  bankAccountId?: Maybe<Scalars['ID']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountBankAccountType?: Maybe<BankAccountType>;
  accountCurrencyCode?: Maybe<Scalars['String']>;
  accountBankName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  userBankAccountTypeDescription?: Maybe<Scalars['String']>;
  accountBankAccountTypeDescription?: Maybe<Scalars['String']>;
  bankTransfer?: Maybe<BankTransfer>;
};

export type BankTransferSender = Node & {
  __typename?: 'BankTransferSender';
  userId?: Maybe<Scalars['ID']>;
  userBankAccountType?: Maybe<UserBankAccountType>;
  infoName?: Maybe<Scalars['String']>;
  infoPostalAddress?: Maybe<Scalars['String']>;
  infoCountryIsoCode2OfBirth?: Maybe<Scalars['String']>;
  infoProvinceOfBirth?: Maybe<Scalars['String']>;
  infoCityOfBirth?: Maybe<Scalars['String']>;
  infoProprietary?: Maybe<Scalars['String']>;
  infoId?: Maybe<Scalars['String']>;
  bankAccountId?: Maybe<Scalars['ID']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountBankAccountType?: Maybe<BankAccountType>;
  accountCurrencyCode?: Maybe<Scalars['String']>;
  accountBankName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  userBankAccountTypeDescription?: Maybe<Scalars['String']>;
  accountBankAccountTypeDescription?: Maybe<Scalars['String']>;
  bankTransfer?: Maybe<BankTransfer>;
};

export type BankTransferSortInput = {
  messageId?: Maybe<SortEnumType>;
  internalRef?: Maybe<SortEnumType>;
  externalRef?: Maybe<SortEnumType>;
  bankTransferStatus?: Maybe<SortEnumType>;
  fxRate?: Maybe<SortEnumType>;
  serviceFeeCurrencyCode?: Maybe<SortEnumType>;
  serviceFee?: Maybe<SortEnumType>;
  deliveryFeeCurrencyCode?: Maybe<SortEnumType>;
  deliveryFee?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  modifiedById?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  modifiedOn?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export enum BankTransferStatus {
  None = 'NONE',
  Rcvd = 'RCVD',
  Acpt = 'ACPT',
  Rjct = 'RJCT',
  Stdb = 'STDB',
  Stcd = 'STCD',
  Stld = 'STLD',
  Stlf = 'STLF',
  Cmpt = 'CMPT',
  Pdng = 'PDNG',
}

export type BankTransferStatusOperationFilterInput = {
  eq?: Maybe<BankTransferStatus>;
  neq?: Maybe<BankTransferStatus>;
  in?: Maybe<Array<BankTransferStatus>>;
  nin?: Maybe<Array<BankTransferStatus>>;
};

export type BankWithdrawalInput = {
  amountUSD: Scalars['Float'];
  receiver: BankWithdrawalReceiverInput;
  otp: OtpInput;
};

export type BankWithdrawalReceiverInput = {
  userId: Scalars['ID'];
  bankAccountId: Scalars['ID'];
};

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

export enum BuySell {
  Buy = 'BUY',
  Sell = 'SELL',
}

export type BuySellOperationFilterInput = {
  eq?: Maybe<BuySell>;
  neq?: Maybe<BuySell>;
  in?: Maybe<Array<BuySell>>;
  nin?: Maybe<Array<BuySell>>;
};

export type CancelTransferTransactionInput = {
  transferTransactionId: Scalars['ID'];
  userType: TransferTransactionUserType;
};

export type ComparableDateTimeOffsetOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  nin?: Maybe<Array<Scalars['DateTime']>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  nin?: Maybe<Array<Scalars['Float']>>;
  gt?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  nlt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: Maybe<Scalars['UUID']>;
  neq?: Maybe<Scalars['UUID']>;
  in?: Maybe<Array<Scalars['UUID']>>;
  nin?: Maybe<Array<Scalars['UUID']>>;
  gt?: Maybe<Scalars['UUID']>;
  ngt?: Maybe<Scalars['UUID']>;
  gte?: Maybe<Scalars['UUID']>;
  ngte?: Maybe<Scalars['UUID']>;
  lt?: Maybe<Scalars['UUID']>;
  nlt?: Maybe<Scalars['UUID']>;
  lte?: Maybe<Scalars['UUID']>;
  nlte?: Maybe<Scalars['UUID']>;
};

export type ComparableInt64OperationFilterInput = {
  eq?: Maybe<Scalars['Long']>;
  neq?: Maybe<Scalars['Long']>;
  in?: Maybe<Array<Scalars['Long']>>;
  nin?: Maybe<Array<Scalars['Long']>>;
  gt?: Maybe<Scalars['Long']>;
  ngt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  ngte?: Maybe<Scalars['Long']>;
  lt?: Maybe<Scalars['Long']>;
  nlt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  nlte?: Maybe<Scalars['Long']>;
};

export type ComparableNullableOfDateTimeOffsetOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  gt?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  nlt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export type ComparableNullableOfInt64OperationFilterInput = {
  eq?: Maybe<Scalars['Long']>;
  neq?: Maybe<Scalars['Long']>;
  in?: Maybe<Array<Maybe<Scalars['Long']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Long']>>>;
  gt?: Maybe<Scalars['Long']>;
  ngt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  ngte?: Maybe<Scalars['Long']>;
  lt?: Maybe<Scalars['Long']>;
  nlt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  nlte?: Maybe<Scalars['Long']>;
};

export type ContactSupportInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export type ConvertCryptoInput = {
  fromAmount: Scalars['Float'];
  fromCurrency: Scalars['String'];
  toCurrency: Scalars['String'];
  otp: OtpInput;
  userId: Scalars['ID'];
};

export type Country = {
  __typename?: 'Country';
  countryIsoCode3?: Maybe<Scalars['String']>;
  countryIsoCode2?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  localFiatCurrencyCode?: Maybe<Scalars['String']>;
  countryIdfy?: Maybe<CountryKyc>;
};

export type wallet_Country = {
  __typename?: 'wallet_Country';
  countryIsoCode3?: Maybe<Scalars['String']>;
  countryIsoCode2?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  localFiatCurrencyCode?: Maybe<Scalars['String']>;
  countryIdfy?: Maybe<CountryKyc>;
};

export type CountryFilterInput = {
  and?: Maybe<Array<CountryFilterInput>>;
  or?: Maybe<Array<CountryFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  isoCode2?: Maybe<StringOperationFilterInput>;
  phoneCountryCode?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  localFiatCurrencyCode?: Maybe<StringOperationFilterInput>;
  countryIdfy?: Maybe<CountryKycFilterInput>;
};

export type CountryKyc = {
  __typename?: 'CountryKYC';
  id?: Maybe<Scalars['String']>;
  configId?: Maybe<Scalars['String']>;
  countryKYCType: CountryKycType;
  country?: Maybe<Country>;
};

export type CountryKycFilterInput = {
  and?: Maybe<Array<CountryKycFilterInput>>;
  or?: Maybe<Array<CountryKycFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  configId?: Maybe<StringOperationFilterInput>;
  countryKYCType?: Maybe<CountryKycTypeOperationFilterInput>;
  country?: Maybe<CountryFilterInput>;
};

export type CountryKycSortInput = {
  id?: Maybe<SortEnumType>;
  configId?: Maybe<SortEnumType>;
  countryKYCType?: Maybe<SortEnumType>;
  country?: Maybe<CountrySortInput>;
};

export enum CountryKycType {
  User = 'USER',
  LegalEntity = 'LEGAL_ENTITY',
}

export type CountryKycTypeOperationFilterInput = {
  eq?: Maybe<CountryKycType>;
  neq?: Maybe<CountryKycType>;
  in?: Maybe<Array<CountryKycType>>;
  nin?: Maybe<Array<CountryKycType>>;
};

export type CountrySortInput = {
  countryIsoCode3?: Maybe<SortEnumType>;
  countryIsoCode2?: Maybe<SortEnumType>;
  phoneCountryCode?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  localFiatCurrencyCode?: Maybe<SortEnumType>;
  countryIdfy?: Maybe<CountryKycSortInput>;
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  currencyType: CurrencyType;
  currencyTypeDescription?: Maybe<Scalars['String']>;
};

export type CurrencyFilterInput = {
  and?: Maybe<Array<CurrencyFilterInput>>;
  or?: Maybe<Array<CurrencyFilterInput>>;
  code?: Maybe<StringOperationFilterInput>;
  symbol?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  currencyType?: Maybe<CurrencyTypeOperationFilterInput>;
};

export type CurrencyRate = {
  __typename?: 'CurrencyRate';
  currencyPair?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
  spread: Scalars['Float'];
  rateSpread: Scalars['Float'];
};

export type CurrencySortInput = {
  code?: Maybe<SortEnumType>;
  symbol?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  currencyType?: Maybe<SortEnumType>;
};

export enum CurrencyType {
  Fiat = 'FIAT',
  Crypto = 'CRYPTO',
}

export type CurrencyTypeOperationFilterInput = {
  eq?: Maybe<CurrencyType>;
  neq?: Maybe<CurrencyType>;
  in?: Maybe<Array<CurrencyType>>;
  nin?: Maybe<Array<CurrencyType>>;
};

export type EditBankAccountInput = {
  bankAccountId: Scalars['ID'];
  bankAccount?: Maybe<BankAccountInput>;
};

export type FeeRate = {
  __typename?: 'FeeRate';
  fee?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

export type IdOperationFilterInput = {
  eq?: Maybe<Scalars['ID']>;
  neq?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type KeyValuePairOfStringAndListOfKeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndListOfKeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Array<KeyValuePairOfStringAndString>;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LegalEntity = Node & {
  __typename?: 'LegalEntity';
  legalName?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  uid: Scalars['UUID'];
  id: Scalars['ID'];
  primaryAddress?: Maybe<LegalEntityAddress>;
  primaryKYC?: Maybe<UserKyc>;
};

export type LegalEntityAddress = Node & {
  __typename?: 'LegalEntityAddress';
  legalEntityId: Scalars['ID'];
  legalEntity?: Maybe<LegalEntity>;
  addressType: AddressType;
  buildingNumber?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  countryIsoCode3?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type LegalEntityInput = {
  legalName: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  natureOfBusiness?: Maybe<Scalars['String']>;
};

export type LegalEntityKyc = Node & {
  __typename?: 'LegalEntityKYC';
  legalEntityId: Scalars['ID'];
  referenceId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  configId?: Maybe<Scalars['String']>;
  profileStatus?: Maybe<ProfileStatus>;
  profileStatusReason?: Maybe<Scalars['String']>;
  sessionStatus?: Maybe<SessionStatus>;
  sessionReason?: Maybe<Scalars['String']>;
  reviewerAction?: Maybe<ReviewerAction>;
  landCount?: Maybe<Scalars['Int']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  scheduleStarted?: Maybe<Scalars['DateTime']>;
  scheduleEnded?: Maybe<Scalars['DateTime']>;
  captureLink?: Maybe<Scalars['String']>;
  captureLinkExpiredAt?: Maybe<Scalars['DateTime']>;
  emailAdress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nSDLName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  profileStatusDescription?: Maybe<Scalars['String']>;
  sessionStatusDescription?: Maybe<Scalars['String']>;
  reviewerActionDescription?: Maybe<Scalars['String']>;
  legalEntity?: Maybe<LegalEntity>;
};

export type MessageHeader = {
  __typename?: 'MessageHeader';
  instructingParty?: Maybe<Scalars['String']>;
  originalInstructingParty?: Maybe<Scalars['String']>;
  messageIdentification?: Maybe<Scalars['String']>;
  creationDateTime?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  reports_rapydCompleteCheckout?: Maybe<RegisterGenericPayloadOfTransferTransaction>;
  reports_rapydCancelCheckout?: Maybe<RegisterGenericPayloadOfTransferTransaction>;
  reports_payTransferTransaction?: Maybe<RegisterGenericPayloadOfTransferTransaction>;
  reports_verifyTransferTransaction?: Maybe<RegisterGenericPayloadOfTransferTransaction>;
  reports_cancelTransferTransaction?: Maybe<RegisterGenericPayloadOfTransferTransaction>;
  /** Send sms verification code */
  messagebird_createSmsVerify?: Maybe<VerifyModel>;
  /** Verify sms token */
  messagebird_sendVerifyToken?: Maybe<VerifyModel>;
  subscription_updateActivityStatus?: Maybe<RegisterGenericPayloadOfActivity>;
  subscription_contactSupport?: Maybe<RegisterGenericPayloadOfBoolean>;
  wallet_addLegalEntity?: Maybe<RegisterGenericPayloadOfLegalEntity>;
  wallet_updateLegalEntity?: Maybe<RegisterGenericPayloadOfLegalEntity>;
  wallet_addSenderUser?: Maybe<RegisterGenericPayloadOfUser>;
  /** Update user, verifcation service will be called when Opt is present */
  wallet_updateUser?: Maybe<RegisterGenericPayloadOfUser>;
  /** add user's address */
  wallet_addUserAddress?: Maybe<RegisterGenericPayloadOfUserAddress>;
  wallet_updateUserPayConfig?: Maybe<RegisterGenericPayloadOfUser>;
  wallet_updateUserNotification?: Maybe<RegisterGenericPayloadOfUser>;
};

export type MutationReports_RapydCompleteCheckoutArgs = {
  internalRef?: Maybe<Scalars['String']>;
};

export type MutationReports_RapydCancelCheckoutArgs = {
  internalRef?: Maybe<Scalars['String']>;
};

export type MutationReports_PayTransferTransactionArgs = {
  input?: Maybe<PayTransferTransactionInput>;
};

export type MutationReports_VerifyTransferTransactionArgs = {
  input?: Maybe<VerifyTransferTransactionInput>;
};

export type MutationReports_CancelTransferTransactionArgs = {
  input?: Maybe<CancelTransferTransactionInput>;
  userId: Scalars['ID'];
};

export type MutationMessagebird_CreateSmsVerifyArgs = {
  recipient: Scalars['Long'];
  payydEnvironment: PayydEnvironment;
};

export type MutationMessagebird_SendVerifyTokenArgs = {
  input?: Maybe<SendVerifyTokenInput>;
};

export type MutationSubscription_UpdateActivityStatusArgs = {
  input?: Maybe<UpdateActivityStatusInput>;
};

export type MutationSubscription_ContactSupportArgs = {
  input?: Maybe<ContactSupportInput>;
};

export type MutationWallet_AddLegalEntityArgs = {
  input?: Maybe<AddLegalEntityInput>;
};

export type MutationWallet_UpdateLegalEntityArgs = {
  input?: Maybe<UpdateLegalEntityInput>;
};

export type MutationWallet_AddSenderUserArgs = {
  input?: Maybe<AddSenderUserInput>;
};

export type MutationWallet_UpdateUserArgs = {
  input?: Maybe<UpdateUserInput>;
};

export type MutationWallet_AddUserAddressArgs = {
  input?: Maybe<AddUserAddressInput>;
};

export type MutationWallet_UpdateUserPayConfigArgs = {
  input?: Maybe<TransactionPayConfigInput>;
};

export type MutationWallet_UpdateUserNotificationArgs = {
  input?: Maybe<UpdateUserNotificationInput>;
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID'];
};

export type NullableOfUserBankAccountTypeOperationFilterInput = {
  eq?: Maybe<UserBankAccountType>;
  neq?: Maybe<UserBankAccountType>;
  in?: Maybe<Array<Maybe<UserBankAccountType>>>;
  nin?: Maybe<Array<Maybe<UserBankAccountType>>>;
};

export type OrderTrade = Node & {
  __typename?: 'OrderTrade';
  userId: Scalars['ID'];
  originalBook?: Maybe<Scalars['String']>;
  book?: Maybe<Scalars['String']>;
  side: BuySell;
  makerSide: BuySell;
  major: Scalars['Float'];
  minor: Scalars['Float'];
  feesAmount: Scalars['Float'];
  feesCurrency?: Maybe<Scalars['String']>;
  minorCurrency?: Maybe<Scalars['String']>;
  majorCurrency?: Maybe<Scalars['String']>;
  oID?: Maybe<Scalars['String']>;
  tID?: Maybe<Scalars['String']>;
  originId?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceSpread: Scalars['Float'];
  priceInUSDC: Scalars['Float'];
  priceSpreadInUSDC: Scalars['Float'];
  originalAmount: Scalars['Float'];
  spread: Scalars['Float'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type OrderTradeFilterInput = {
  and?: Maybe<Array<OrderTradeFilterInput>>;
  or?: Maybe<Array<OrderTradeFilterInput>>;
  userId?: Maybe<IdOperationFilterInput>;
  originalBook?: Maybe<StringOperationFilterInput>;
  book?: Maybe<StringOperationFilterInput>;
  side?: Maybe<BuySellOperationFilterInput>;
  makerSide?: Maybe<BuySellOperationFilterInput>;
  major?: Maybe<ComparableDoubleOperationFilterInput>;
  minor?: Maybe<ComparableDoubleOperationFilterInput>;
  feesAmount?: Maybe<ComparableDoubleOperationFilterInput>;
  feesCurrency?: Maybe<StringOperationFilterInput>;
  minorCurrency?: Maybe<StringOperationFilterInput>;
  majorCurrency?: Maybe<StringOperationFilterInput>;
  oID?: Maybe<StringOperationFilterInput>;
  tID?: Maybe<StringOperationFilterInput>;
  originId?: Maybe<StringOperationFilterInput>;
  price?: Maybe<ComparableDoubleOperationFilterInput>;
  priceSpread?: Maybe<ComparableDoubleOperationFilterInput>;
  priceInUSDC?: Maybe<ComparableDoubleOperationFilterInput>;
  priceSpreadInUSDC?: Maybe<ComparableDoubleOperationFilterInput>;
  originalAmount?: Maybe<ComparableDoubleOperationFilterInput>;
  spread?: Maybe<ComparableDoubleOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
};

export type OrderTradeSortInput = {
  userId?: Maybe<SortEnumType>;
  originalBook?: Maybe<SortEnumType>;
  book?: Maybe<SortEnumType>;
  side?: Maybe<SortEnumType>;
  makerSide?: Maybe<SortEnumType>;
  major?: Maybe<SortEnumType>;
  minor?: Maybe<SortEnumType>;
  feesAmount?: Maybe<SortEnumType>;
  feesCurrency?: Maybe<SortEnumType>;
  minorCurrency?: Maybe<SortEnumType>;
  majorCurrency?: Maybe<SortEnumType>;
  oID?: Maybe<SortEnumType>;
  tID?: Maybe<SortEnumType>;
  originId?: Maybe<SortEnumType>;
  price?: Maybe<SortEnumType>;
  priceSpread?: Maybe<SortEnumType>;
  priceInUSDC?: Maybe<SortEnumType>;
  priceSpreadInUSDC?: Maybe<SortEnumType>;
  originalAmount?: Maybe<SortEnumType>;
  spread?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export type OtpInput = {
  id: Scalars['String'];
  payydEnvironment: PayydEnvironment;
  token: Scalars['String'];
};

export type PaymentMethodInput = {
  paymentMethodSource: PaymentMethodSource;
  type: Scalars['String'];
  walletId: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PayTransferTransactionInput = {
  senderUserId: Scalars['ID'];
  internalRef: Scalars['String'];
  paymentMethod?: PaymentMethodInput;
  otp?: OtpInput;
  escrowDate?: Scalars['DateTime'];
};

export type Payment = {
  __typename?: 'Payment';
  messageIdentification?: Maybe<Scalars['String']>;
  instructingParty?: Maybe<Scalars['String']>;
  instructedParty?: Maybe<Scalars['String']>;
  paymentIdentification?: Maybe<PaymentIdentification>;
  status: BankTransferStatus;
  dateTime?: Maybe<Scalars['String']>;
};

export type PaymentIdentification = {
  __typename?: 'PaymentIdentification';
  endToEndId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export enum PaymentMethodSource {
  RAPYD = 'RAPYD',
  STRIPE = 'STRIPE',
  UNLIMINT = 'UNLIMINT',
  GO_CARDLESS = 'GO_CARDLESS',
  PAYYD_BC = 'PAYYD_BC',
  NONE = 'NONE',
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  paymentFlowType?: Maybe<Scalars['String']>;
  currencies?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['Int'];
  isCancelable: Scalars['Boolean'];
  paymentOptions?: Maybe<Array<Maybe<PaymentOption>>>;
  isExpirable: Scalars['Boolean'];
  isOnline: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  minimumExpirationSeconds: Scalars['Int'];
  maximumExpirationSeconds: Scalars['Int'];
  virtualPaymentMethodType?: Maybe<Scalars['String']>;
  isVirtual: Scalars['Boolean'];
  multipleOverageAllowed: Scalars['Boolean'];
  amountRangePerCurrencies?: Maybe<Array<Maybe<AmountRangePerCurrency>>>;
  isTokenizable: Scalars['Boolean'];
  paymentMethodSource: Maybe<PaymentMethodSource>;
  supportedDigitalWalletProviders?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentOption = {
  __typename?: 'PaymentOption';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  regex?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isRequired: Scalars['Boolean'];
  isUpdatable: Scalars['Boolean'];
};

export enum PaymentPurpose {
  None = 'NONE',
  WebDesign = 'WEB_DESIGN',
  SoftwareDevelopment = 'SOFTWARE_DEVELOPMENT',
  SalesRelatedWork = 'SALES_RELATED_WORK',
  SupportRelatedWork = 'SUPPORT_RELATED_WORK',
  VacationHomeRental = 'VACATION_HOME_RENTAL',
  TravelAgentCommission = 'TRAVEL_AGENT_COMMISSION',
  MarketingEngagement = 'MARKETING_ENGAGEMENT',
  Consultation = 'CONSULTATION',
  SaleofGoods = 'SALEOF_GOODS',
  Other = 'OTHER',
}

export type PaymentPurposeOperationFilterInput = {
  eq?: Maybe<PaymentPurpose>;
  neq?: Maybe<PaymentPurpose>;
  in?: Maybe<Array<PaymentPurpose>>;
  nin?: Maybe<Array<PaymentPurpose>>;
};

export enum PayydEnvironment {
  Prod = 'PROD',
  Stg = 'STG',
  Dev = 'DEV',
  Mock = 'MOCK',
}

export enum ProfileStatus {
  Pending = 'PENDING',
  CapturePending = 'CAPTURE_PENDING',
  Initiated = 'INITIATED',
  InProgress = 'IN_PROGRESS',
  Processed = 'PROCESSED',
  RecapturePending = 'RECAPTURE_PENDING',
  ReviewRequired = 'REVIEW_REQUIRED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Purged = 'PURGED',
}

export type Query = {
  __typename?: 'Query';
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  reports_enums?: Maybe<
    Array<KeyValuePairOfStringAndListOfKeyValuePairOfStringAndString>
  >;
  reports_transferTransactionsByUserId?: Maybe<Reports_TransferTransactionsByUserIdConnection>;

  reports_transferTransactionByRef?: Maybe<TransferTransaction>;
  reports_publicTransferTransactionByRef?: Maybe<TransferTransaction>;

  reports_transferTransactionById?: Maybe<TransferTransaction>;
  reports_transferTransactionsById?: Maybe<Array<Maybe<TransferTransaction>>>;
  reports_transferTransactionStatusHistoriesById?: Maybe<
    Array<Maybe<TransferTransactionStatusHistory>>
  >;
  reports_feeById?: Maybe<TransferTransactionFee>;
  /** Fetches an object given its ID. */
  subscription_node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  subscription_nodes: Array<Maybe<Node>>;
  subscription_activitiesByUserId?: Maybe<Subscription_activitiesByUserIdConnection>;
  subscription_enums?: Maybe<
    Array<KeyValuePairOfStringAndListOfKeyValuePairOfStringAndString>
  >;
  /** Fetches an object given its ID. */
  wallet_node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  wallet_nodes: Array<Maybe<Node>>;
  wallet_enums?: Maybe<
    Array<KeyValuePairOfStringAndListOfKeyValuePairOfStringAndString>
  >;
  reports_paymentMethodsByCountryIsoCode2?: Maybe<Array<Maybe<PaymentMethod>>>;
  reports_paymentMethodsByCountryIsoCode3?: Maybe<Array<Maybe<PaymentMethod>>>;
  wallet_legalEntityPrimaryKYCByLegalEntityId?: Maybe<LegalEntityKyc>;
  wallet_sendCountries?: Maybe<Wallet_SendCountriesConnection>;
  wallet_countries?: Maybe<Wallet_CountriesConnection>;
  wallet_receiveCountries?: Maybe<Wallet_receiveCountriesConnection>;
  wallet_sendCurrencies?: Maybe<Wallet_SendCurrenciesConnection>;
  wallet_sendCurrenciesByCountryIsoCode3?: Maybe<Wallet_SendCurrenciesByCountryIsoCode3Connection>;
  wallet_userBySub?: Maybe<User>;
  wallet_userById?: Maybe<User>;
  wallet_usersById?: Maybe<Array<Maybe<User>>>;
  wallet_userPrimaryKYCByUserId?: Maybe<UserKyc>;
  wallet_isMobileNumberExists: Scalars['Boolean'];
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryReports_TransferTransactionsByUserIdArgs = {
  userId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<TransferTransactionFilterInput>;
  order?: Maybe<Array<TransferTransactionSortInput>>;
};

export type QueryReports_TransferTransactionByRefArgs = {
  internalRef?: Maybe<Scalars['String']>;
};

export type QueryReports_TransferTransactionByIdArgs = {
  id: Scalars['ID'];
};

export type QueryReports_TransferTransactionsByIdArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryReports_TransferTransactionStatusHistoriesByIdArgs = {
  transferTransactionId: Scalars['ID'];
};

export type QueryReports_FeeByIdArgs = {
  transferTransactionId: Scalars['ID'];
};

export type QuerySubscription_NodeArgs = {
  id: Scalars['ID'];
};

export type QuerySubscription_NodesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QuerySubscription_ActivitiesByUserIdArgs = {
  userId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ActivityFilterInput>;
  order?: Maybe<Array<ActivitySortInput>>;
};

export type QueryWallet_NodeArgs = {
  id: Scalars['ID'];
};

export type QueryWallet_NodesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryWallet_PaymentMethodsByCountryIsoCode2Args = {
  countryIsoCode2?: Maybe<Scalars['String']>;
};

export type QueryWallet_LegalEntityPrimaryKycByLegalEntityIdArgs = {
  legalEntityId: Scalars['ID'];
};

export type QueryWallet_SendCountriesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CountryFilterInput>;
  order?: Maybe<Array<CountrySortInput>>;
};

export type QueryWallet_CountriesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CountryFilterInput>;
  order?: Maybe<Array<CountrySortInput>>;
};

export type QueryWallet_SendCurrenciesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CurrencyFilterInput>;
  order?: Maybe<Array<CurrencySortInput>>;
};

export type QueryWallet_SendCurrenciesByCountryIsoCode3Args = {
  countryIsoCode3?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CurrencyFilterInput>;
  order?: Maybe<Array<CurrencySortInput>>;
};

export type QueryWallet_UserBySubArgs = {
  sub?: Maybe<Scalars['String']>;
};

export type QueryWallet_UserByIdArgs = {
  id: Scalars['ID'];
};

export type QueryWallet_UsersByIdArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type QueryWallet_UserPrimaryKycByUserIdArgs = {
  userId: Scalars['ID'];
};

export type QueryWallet_IsMobileNumberExistsArgs = {
  phoneNumber?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
};
export type Step = {
  step1: Maybe<Scalars['String']>;
};
export type Instruction = {
  name: Maybe<Scalars['String']>;
  steps: [Step];
};

export type GoCardlessTransferTransaction = {
  __typename?: 'GoCardlessTransferTransaction';
  billingRequestFlowId: String;
  billingRequestId: String;
  authorisationUrl: String;
  createdById: Scalars['Long'];
  createdOn: Scalars['DateTime'];
  modifiedById?: Maybe<Scalars['Long']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Long'];
};

export type RapydTransferTransaction = {
  __typename?: 'RapydTransferTransaction';
  checkOutId?: Maybe<Scalars['String']>;
  checkOutStatus?: Maybe<Scalars['String']>;
  checkoutRedirectUrl?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  // jsonInstructions?: Maybe<Scalars['String']>;
  nextAction?: Maybe<Scalars['String']>;
  instructions?: [Instruction];
  paymentStatus?: Maybe<Scalars['String']>;
  // paymentMethod?: Maybe<Scalars['String']>;
  createdById: Scalars['Long'];
  createdOn: Scalars['DateTime'];
  modifiedById?: Maybe<Scalars['Long']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Long'];
};

export type UnlimintTransferTransaction = {
  __typename?: 'UnlimintTransferTransaction';
  id: Scalars['Long'];
  requestId?: Maybe<Scalars['String']>;
  checkoutUrl?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  declineReason?: Maybe<Scalars['String']>;
  declineCode?: Maybe<Scalars['String']>;
  // paymentMethod?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
  createdById: Scalars['Long'];
  createdOn: Scalars['DateTime'];
  modifiedById?: Maybe<Scalars['Long']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
};

export type StripeTransferTransaction = {
  __typename?: 'StripeTransferTransaction';
  checkOutId?: Maybe<Scalars['String']>;
  checkoutStatus?: Maybe<Scalars['String']>;
  checkoutUrl?: Maybe<Scalars['String']>;
  createdById: Scalars['Long'];
  createdOn: Scalars['DateTime'];
  id: Scalars['Long'];
  // paymentMethod?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  modifiedById?: Maybe<Scalars['Long']>;
  modifiedOn?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentInput = {
  recurringPaymentType: RecurringPaymentType;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export enum RecurringPaymentType {
  None = 'NONE',
  OneTime = 'ONE_TIME',
  Every1St = 'EVERY1ST',
  Every15Th = 'EVERY15TH',
  Every30Th = 'EVERY30TH',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
}

export type RegisterGenericPayloadOfActivity = {
  __typename?: 'RegisterGenericPayloadOfActivity';
  data?: Maybe<Activity>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfBankAccount = {
  __typename?: 'RegisterGenericPayloadOfBankAccount';
  data?: Maybe<BankAccount>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfBankTransfer = {
  __typename?: 'RegisterGenericPayloadOfBankTransfer';
  data?: Maybe<BankTransfer>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfBoolean = {
  __typename?: 'RegisterGenericPayloadOfBoolean';
  data: Scalars['Boolean'];
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfFeeRate = {
  __typename?: 'RegisterGenericPayloadOfFeeRate';
  data?: Maybe<FeeRate>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfLegalEntity = {
  __typename?: 'RegisterGenericPayloadOfLegalEntity';
  data?: Maybe<LegalEntity>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfListOfTransferTransaction = {
  __typename?: 'RegisterGenericPayloadOfListOfTransferTransaction';
  data?: Maybe<Array<Maybe<TransferTransaction>>>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfOrderTrade = {
  __typename?: 'RegisterGenericPayloadOfOrderTrade';
  data?: Maybe<OrderTrade>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfSubscriptionCurrencyRate = {
  __typename?: 'RegisterGenericPayloadOfSubscriptionCurrencyRate';
  data?: Maybe<SubscriptionCurrencyRate>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfTransactionFlowResponse = {
  __typename?: 'RegisterGenericPayloadOfTransactionFlowResponse';
  data?: Maybe<TransactionFlowResponse>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfTransferTransaction = {
  __typename?: 'RegisterGenericPayloadOfTransferTransaction';
  data?: Maybe<TransferTransaction>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfTupleOfInt64AndSubscriptionActivityType = {
  __typename?: 'RegisterGenericPayloadOfTupleOfInt64AndSubscriptionActivityType';
  data?: Maybe<TupleOfInt64AndSubscriptionActivityType>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfUser = {
  __typename?: 'RegisterGenericPayloadOfUser';
  data?: Maybe<User>;
  errors?: Maybe<Array<UserError>>;
};

export type RegisterGenericPayloadOfUserAddress = {
  __typename?: 'RegisterGenericPayloadOfUserAddress';
  data?: Maybe<UserAddress>;
  errors?: Maybe<Array<UserError>>;
};

/** A connection to a list of items. */
export type Reports_AvailableBooksConnection = {
  __typename?: 'Reports_availableBooksConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Reports_AvailableBooksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<AvailableBook>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Reports_AvailableBooksEdge = {
  __typename?: 'Reports_availableBooksEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AvailableBook>;
};

/** A connection to a list of items. */
export type Reports_BankTransfersByUserIdConnection = {
  __typename?: 'Reports_bankTransfersByUserIdConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Reports_BankTransfersByUserIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<BankTransfer>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Reports_BankTransfersByUserIdEdge = {
  __typename?: 'Reports_bankTransfersByUserIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BankTransfer>;
};

/** A connection to a list of items. */
export type Reports_OrderTradesByUserIdConnection = {
  __typename?: 'Reports_orderTradesByUserIdConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Reports_OrderTradesByUserIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<OrderTrade>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Reports_OrderTradesByUserIdEdge = {
  __typename?: 'Reports_orderTradesByUserIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderTrade>;
};

/** A connection to a list of items. */
export type Reports_SendersByReceiverIdConnection = {
  __typename?: 'Reports_sendersByReceiverIdConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Reports_SendersByReceiverIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<TransferTransactionSender>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Reports_SendersByReceiverIdEdge = {
  __typename?: 'Reports_sendersByReceiverIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TransferTransactionSender>;
};

/** A connection to a list of items. */
export type Reports_TransferTransactionsByUserIdConnection = {
  __typename?: 'Reports_transferTransactionsByUserIdConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Reports_TransferTransactionsByUserIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<TransferTransaction>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Reports_TransferTransactionsByUserIdEdge = {
  __typename?: 'Reports_transferTransactionsByUserIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TransferTransaction>;
};

export type RequestTransferTransactionInput = {
  paymentPurpose: PaymentPurpose;
  currencyCode: Scalars['String'];
  amount: Scalars['Decimal'];
  smsPayydEnvironment: PayydEnvironment;
  payor: RequestTransferTransactionPayorInput;
  recurringPayment?: Maybe<RecurringPaymentInput>;
  paymentPurposeOther?: Maybe<Scalars['String']>;
  billedHours?: Maybe<Scalars['Float']>;
  receiverUserId: Scalars['ID'];
};

export type RequestTransferTransactionPayorInput = {
  phoneNumber: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export enum ReviewerAction {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export type SendVerifyTokenInput = {
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  payydEnvironment: PayydEnvironment;
};

export enum SessionStatus {
  Incomplete = 'INCOMPLETE',
  Complete = 'COMPLETE',
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  subscription_rateChanged?: Maybe<CurrencyRate>;
};

export type SubscriptionSubscription_RateChangedArgs = {
  currencyPair?: Maybe<Scalars['String']>;
};

export enum SubscriptionActivityType {
  None = 'NONE',
  DeviceLogin = 'DEVICE_LOGIN',
  PersonalInformationChange = 'PERSONAL_INFORMATION_CHANGE',
  IdentityVerification = 'IDENTITY_VERIFICATION',
  BankInformation = 'BANK_INFORMATION',
  DeletedBankInformation = 'DELETED_BANK_INFORMATION',
  NewAtPayyd = 'NEW_AT_PAYYD',
  AppMaintenance = 'APP_MAINTENANCE',
  NewRequest = 'NEW_REQUEST',
  RequestCancelled = 'REQUEST_CANCELLED',
  RequestCompleted = 'REQUEST_COMPLETED',
  WithdrawToBank = 'WITHDRAW_TO_BANK',
  ConvertToCrypto = 'CONVERT_TO_CRYPTO',
  CashoutCrypto = 'CASHOUT_CRYPTO',
}

export type SubscriptionCurrencyRate = {
  __typename?: 'SubscriptionCurrencyRate';
  baseCurrency?: Maybe<Currency>;
  qouteCurrency?: Maybe<Currency>;
  currencyPair?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
  spread: Scalars['Float'];
  rateSpread: Scalars['Float'];
};

/** A connection to a list of items. */
export type Subscription_activitiesByUserIdConnection = {
  __typename?: 'Subscription_activitiesByUserIdConnection';
  /** Information to aid in pagination. */
  // pageInfo: PageInfo;
  /** A list of edges. */
  // edges?: Maybe<Array<Subscription_ActivitiesByUserIdEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<Activity>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Subscription_ActivitiesByUserIdEdge = {
  __typename?: 'Subscription_activitiesByUserIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Activity>;
};

export type SupplementaryData = {
  __typename?: 'SupplementaryData';
  transactionId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  nodeCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
};

export type TransactionFlowData = {
  __typename?: 'TransactionFlowData';
  messageHeader?: Maybe<MessageHeader>;
  reportOrError?: Maybe<TransactionFlowReportOrError>;
};

export type TransactionFlowReportOrError = {
  __typename?: 'TransactionFlowReportOrError';
  payment?: Maybe<Payment>;
  supplementaryData?: Maybe<Array<Maybe<SupplementaryData>>>;
};

export type TransactionFlowResponse = {
  __typename?: 'TransactionFlowResponse';
  data?: Maybe<TransactionFlowData>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type TransactionPayConfigInput = {
  userId: Scalars['ID'];
  bulkPay?: Maybe<Scalars['Boolean']>;
  payNow?: Maybe<Scalars['Boolean']>;
};

export type TransferTransaction = Node & {
  __typename?: 'TransferTransaction';
  internalRef?: Maybe<Scalars['String']>;
  transferTransactionType: TransferTransactionType;
  country: Country;
  transferTransactionStatus: TransferTransactionStatus;
  paymentPurpose: PaymentPurpose;
  paymentPurposeOther?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  paymentMethodSourceDescription: Maybe<Scalars['String']>;
  paymentMethodSource: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  receivableAmount: Scalars['Float'];
  recurringInstance?: Maybe<Scalars['Int']>;
  countryIsoCode3: Scalars['String'];
  recurringDate?: Maybe<Scalars['DateTime']>;
  escrowDate?: Maybe<Scalars['DateTime']>;
  recurringTransferTransactionId?: Maybe<Scalars['Long']>;
  billedHours?: Maybe<Scalars['Float']>;
  createdById: Scalars['ID'];
  modifiedById?: Maybe<Scalars['ID']>;
  createdOn: Scalars['DateTime'];
  modifiedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  primaryAddress: Maybe<UserAddress>;
  transferTransactionTypeDescription?: Maybe<Scalars['String']>;
  transferTransactionStatusDescription?: Maybe<Scalars['String']>;
  paymentPurposeDescription?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  reference: Maybe<Scalars['String']>;
  rapyd: Maybe<RapydTransferTransaction>;
  goCardless: Maybe<GoCardlessTransferTransaction>;
  unlimint: Maybe<UnlimintTransferTransaction>;
  stripe: Maybe<StripeTransferTransaction>;
  checkOutUrl?: Maybe<Scalars['String']>;
  sender?: Maybe<TransferTransactionSender>;
  receiver?: Maybe<TransferTransactionReceiver>;
  processingFee?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  totalAmount: Maybe<Scalars['Float']>;
  transferTransactionStatusHistories?: Maybe<
    Array<Maybe<TransferTransactionStatusHistory>>
  >;
};

export type PublicTransferTransaction = Node & {
  __typename?: 'PublicTransferTransaction';
  internalRef?: Maybe<Scalars['String']>;

  currencyCode?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  id: Scalars['ID'];
  receiver?: Maybe<PublicTransferTransactionReceiver>;
};

export type TransferTransactionFee = Node & {
  __typename?: 'TransferTransactionFee';
  processingFee: Scalars['Float'];
  tax: Scalars['Float'];
  id: Scalars['ID'];
};

export type TransferTransactionFilterInput = {
  and?: Maybe<Array<TransferTransactionFilterInput>>;
  or?: Maybe<Array<TransferTransactionFilterInput>>;
  internalRef?: Maybe<StringOperationFilterInput>;
  transferTransactionType?: Maybe<TransferTransactionTypeOperationFilterInput>;
  transferTransactionStatus?: Maybe<TransferTransactionStatusOperationFilterInput>;
  paymentPurpose?: Maybe<PaymentPurposeOperationFilterInput>;
  paymentPurposeOther?: Maybe<StringOperationFilterInput>;
  currencyCode?: Maybe<StringOperationFilterInput>;
  amount?: Maybe<ComparableDoubleOperationFilterInput>;
  recurringInstance?: Maybe<ComparableNullableOfInt32OperationFilterInput>;
  recurringDate?: Maybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  recurringTransferTransactionId?: Maybe<ComparableNullableOfInt64OperationFilterInput>;
  billedHours?: Maybe<ComparableNullableOfDoubleOperationFilterInput>;
  createdById?: Maybe<IdOperationFilterInput>;
  modifiedById?: Maybe<IdOperationFilterInput>;
  createdOn?: Maybe<ComparableDateTimeOffsetOperationFilterInput>;
  modifiedOn?: Maybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
};

export type TransferTransactionInput = {
  internalRef: Scalars['String'];
  userType: TransferTransactionUserType;
};

export type TransferTransactionReceiver = Node & {
  __typename?: 'TransferTransactionReceiver';
  userId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  countryIsoCode3?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  transferTransaction?: Maybe<TransferTransaction>;
};

export type PublicTransferTransactionReceiver = Node & {
  __typename?: 'PublicTransferTransactionReceiver';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type TransferTransactionSender = Node & {
  __typename?: 'TransferTransactionSender';
  userId?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryKYC?: Maybe<UserKyc>;
  primaryAddress?: Maybe<UserAddress>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legalEntity?: Maybe<LegalEntity>;
  transferTransaction?: Maybe<TransferTransaction>;
};

export type TransferTransactionSenderFilterInput = {
  and?: Maybe<Array<TransferTransactionSenderFilterInput>>;
  or?: Maybe<Array<TransferTransactionSenderFilterInput>>;
  userId?: Maybe<IdOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  middleName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  businessName?: Maybe<StringOperationFilterInput>;
  emailAddress?: Maybe<StringOperationFilterInput>;
  phoneNumber?: Maybe<StringOperationFilterInput>;
  phoneCountryCode?: Maybe<StringOperationFilterInput>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
};

export type TransferTransactionSenderSortInput = {
  userId?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  middleName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  businessName?: Maybe<SortEnumType>;
  emailAddress?: Maybe<SortEnumType>;
  phoneNumber?: Maybe<SortEnumType>;
  phoneCountryCode?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export type TransferTransactionSortInput = {
  internalRef?: Maybe<SortEnumType>;
  transferTransactionType?: Maybe<SortEnumType>;
  transferTransactionStatus?: Maybe<SortEnumType>;
  paymentPurpose?: Maybe<SortEnumType>;
  paymentPurposeOther?: Maybe<SortEnumType>;
  currencyCode?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  recurringInstance?: Maybe<SortEnumType>;
  recurringDate?: Maybe<SortEnumType>;
  recurringTransferTransactionId?: Maybe<SortEnumType>;
  billedHours?: Maybe<SortEnumType>;
  createdById?: Maybe<SortEnumType>;
  modifiedById?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  modifiedOn?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export enum TransferTransactionStatus {
  None = 'NONE',
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  LINK_SENT = 'LINK_SENT',
  Declined = 'DECLINED',
  LINK_DECLINED = 'LINK_DECLINED',
  Paid = 'PAID',
  Cancelled = 'CANCELLED',
  Link_Viewed = 'Link_Viewed',
  Link_Declined = 'Link_Declined',
  Awaiting_Funds = 'Awaiting_Funds',
  Link_Abandoned = 'Link_Abandoned',
  Funds_Collected = 'Funds_Collected',
  Funds_Received = 'Funds_Received',
  Error = 'ERROR',
  Expired = 'EXPIRED',
  InProgress = 'IN_PROGRESS',
  Initiated = 'INITIATED',
}

export type TransferTransactionStatusHistory = Node & {
  __typename?: 'TransferTransactionStatusHistory';
  transferTransactionId: Scalars['ID'];
  transferTransactionStatusHistoryType: TransferTransactionStatusHistoryType;
  note?: Maybe<Scalars['String']>;
  valueDate?: Maybe<Scalars['DateTime']>;
  createdOn: Scalars['DateTime'];
  transferTransaction?: Maybe<TransferTransaction>;
  id: Scalars['ID'];
  transferTransactionStatusHistoryTypeDescription?: Maybe<Scalars['String']>;
};

export enum TransferTransactionStatusHistoryType {
  None = 'NONE',
  RequestSubmitted = 'REQUEST_SUBMITTED',
  Verifying = 'VERIFYING',
  Paid = 'PAID',
  Ready = 'READY',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
}

export type TransferTransactionStatusOperationFilterInput = {
  eq?: Maybe<TransferTransactionStatus>;
  neq?: Maybe<TransferTransactionStatus>;
  in?: Maybe<Array<TransferTransactionStatus>>;
  nin?: Maybe<Array<TransferTransactionStatus>>;
};

export enum TransferTransactionType {
  None = 'NONE',
  Request = 'REQUEST',
  Send = 'SEND',
}

export type TransferTransactionTypeOperationFilterInput = {
  eq?: Maybe<TransferTransactionType>;
  neq?: Maybe<TransferTransactionType>;
  in?: Maybe<Array<TransferTransactionType>>;
  nin?: Maybe<Array<TransferTransactionType>>;
};

export enum TransferTransactionUserType {
  Sender = 'SENDER',
  Receiver = 'RECEIVER',
}

export type TupleOfInt64AndSubscriptionActivityType = {
  __typename?: 'TupleOfInt64AndSubscriptionActivityType';
  item1: Scalars['Long'];
  item2: SubscriptionActivityType;
};

export type UpdateActivityStatusInput = {
  activtyId: Scalars['ID'];
  activityStatus: ActivityStatus;
};

export type UpdateLegalEntityAddressInput = {
  legalEntityAddressId: Scalars['ID'];
  address?: Maybe<AddressInput>;
};

export type UpdateLegalEntityInput = {
  id: Scalars['ID'];
  legalEntity?: Maybe<LegalEntityInput>;
  legalEntityAddress?: Maybe<UpdateLegalEntityAddressInput>;
};

export type UpdateUserAddressInput = {
  userAddressId: Scalars['ID'];
  address?: Maybe<AddressInput>;
};

export type UpdateUserInput = {
  user: UserInput;
  id: Scalars['ID'];
  otp?: Maybe<OtpInput>;
  userAddress?: Maybe<UpdateUserAddressInput>;
};

export type UpdateUserNotificationInput = {
  userId: Scalars['ID'];
  emailNotification: Scalars['Boolean'];
  smsNotification: Scalars['Boolean'];
};

export type User = Node & {
  __typename?: 'User';
  sub?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  countryIsoCode3?: Maybe<Scalars['String']>;
  dOB?: Maybe<Scalars['DateTime']>;
  taxID?: Maybe<Scalars['String']>;
  uID: Scalars['UUID'];
  bulkPay?: Maybe<Scalars['Boolean']>;
  payNow?: Maybe<Scalars['Boolean']>;
  emailNotification: Scalars['Boolean'];
  smsNotification: Scalars['Boolean'];
  legalEntityId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  legalEntity?: Maybe<LegalEntity>;
  primaryAddress?: Maybe<UserAddress>;
  primaryBankAccount?: Maybe<BankAccount>;
  bankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  primaryKYC?: Maybe<UserKyc>;
  accountBalances?: Maybe<Array<Maybe<AccountBalance>>>;
};

export type UserAddress = Node & {
  __typename?: 'UserAddress';
  userId: Scalars['ID'];
  user?: Maybe<User>;
  addressType: AddressType;
  buildingNumber?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateIsoCode2?: Maybe<Scalars['String']>;
  countryIsoCode3?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum UserBankAccountType {
  Personal = 'PERSONAL',
  Business = 'BUSINESS',
}

export type UserError = {
  __typename?: 'UserError';
  message?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UserFilterInput = {
  and?: Maybe<Array<UserFilterInput>>;
  or?: Maybe<Array<UserFilterInput>>;
  sub?: Maybe<StringOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  middleName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  emailAddress?: Maybe<StringOperationFilterInput>;
  phoneNumber?: Maybe<StringOperationFilterInput>;
  phoneCountryCode?: Maybe<StringOperationFilterInput>;
  countryIsoCode3?: Maybe<StringOperationFilterInput>;
  dOB?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>;
  taxID?: Maybe<StringOperationFilterInput>;
  uID?: Maybe<ComparableGuidOperationFilterInput>;
  bulkPay?: Maybe<BooleanOperationFilterInput>;
  payNow?: Maybe<BooleanOperationFilterInput>;
  emailNotification?: Maybe<BooleanOperationFilterInput>;
  smsNotification?: Maybe<BooleanOperationFilterInput>;
  legalEntityId?: Maybe<IdOperationFilterInput>;
  id?: Maybe<ComparableInt64OperationFilterInput>;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  countryIsoCode3: Scalars['String'];
  userEntityType: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  dOB?: Maybe<Scalars['DateTime']>;
  taxID?: Maybe<Scalars['String']>;
};

export type UserKyc = Node & {
  __typename?: 'UserKYC';
  userId: Scalars['ID'];
  dob?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  fatherName?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  configId?: Maybe<Scalars['String']>;
  profileStatus?: Maybe<ProfileStatus>;
  profileStatusReason?: Maybe<Scalars['String']>;
  sessionStatus?: Maybe<SessionStatus>;
  sessionReason?: Maybe<Scalars['String']>;
  reviewerAction?: Maybe<ReviewerAction>;
  landCount?: Maybe<Scalars['Int']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  scheduleStarted?: Maybe<Scalars['DateTime']>;
  scheduleEnded?: Maybe<Scalars['DateTime']>;
  captureLink?: Maybe<Scalars['String']>;
  captureLinkExpiredAt?: Maybe<Scalars['DateTime']>;
  emailAdress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nSDLName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  profileStatusDescription?: Maybe<Scalars['String']>;
  sessionStatusDescription?: Maybe<Scalars['String']>;
  reviewerActionDescription?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type UserSortInput = {
  sub?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  middleName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  emailAddress?: Maybe<SortEnumType>;
  phoneNumber?: Maybe<SortEnumType>;
  phoneCountryCode?: Maybe<SortEnumType>;
  countryIsoCode3?: Maybe<SortEnumType>;
  dOB?: Maybe<SortEnumType>;
  taxID?: Maybe<SortEnumType>;
  uID?: Maybe<SortEnumType>;
  bulkPay?: Maybe<SortEnumType>;
  payNow?: Maybe<SortEnumType>;
  emailNotification?: Maybe<SortEnumType>;
  smsNotification?: Maybe<SortEnumType>;
  legalEntityId?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
};

export type VerifyModel = {
  __typename?: 'VerifyModel';
  id?: Maybe<Scalars['String']>;
  status: VerifyStatus;
  createdDatetime?: Maybe<Scalars['DateTime']>;
  validUntilDatetime?: Maybe<Scalars['DateTime']>;
  statusDescription?: Maybe<Scalars['String']>;
};

export enum VerifyStatus {
  Sent = 'SENT',
  Verified = 'VERIFIED',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
}

export type VerifyTransferTransactionInput = {
  senderUserId: Scalars['ID'];
  internalRef?: Maybe<Scalars['String']>;
};

export type PlaidUpdateTransferTransactionStatusInput = {
  __typename?: 'PlaidUpdateTransferTransactionStatusInput';
  publicToken?: Maybe<Scalars['String']>;
  metadataJson?: Maybe<Scalars['String']>;
};

export type UpdateTransferTransactionStatusInput = {
  internalRef?: Maybe<Scalars['String']>;
  transferTransactionStatus?: Maybe<Scalars['String']>;
  plaid: PlaidUpdateTransferTransactionStatusInput;
};

export type DeclineTransferTransactionInput = {
  reason: Maybe<Scalars['String']>;
  senderEmailAddress: Maybe<Scalars['String']>;
  transferTransactionId: Scalars['ID'];
};

export type WalletCurrencyRate = {
  __typename?: 'WalletCurrencyRate';
  baseCurrency?: Maybe<Currency>;
  qouteCurrency?: Maybe<Currency>;
  currencyPair?: Maybe<Scalars['String']>;
  rate: Scalars['Float'];
  spread: Scalars['Float'];
  rateSpread: Scalars['Float'];
};

/** A connection to a list of items. */
export type Wallet_BankAccountsByUserIdConnection = {
  __typename?: 'Wallet_bankAccountsByUserIdConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_BankAccountsByUserIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<BankAccount>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Wallet_BankAccountsByUserIdEdge = {
  __typename?: 'Wallet_bankAccountsByUserIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BankAccount>;
};

/** A connection to a list of items. */
export type Wallet_CountriesConnection = {
  __typename?: 'Wallet_countriesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_CountriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Country>>>;
  totalCount: Scalars['Int'];
};

export type Wallet_receiveCountriesConnection = {
  __typename?: 'Wallet_receiveCountriesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_receiveCountriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<wallet_Country>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Wallet_CountriesEdge = {
  __typename?: 'Wallet_countriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};
export type Wallet_receiveCountriesEdge = {
  __typename?: 'Wallet_receiveCountriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};

/** A connection to a list of items. */
export type Wallet_ReceiveCountriesConnection = {
  __typename?: 'Wallet_receiveCountriesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_ReceiveCountriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Country>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Wallet_ReceiveCountriesEdge = {
  __typename?: 'Wallet_receiveCountriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};

/** A connection to a list of items. */
export type Wallet_ReceiveCurrenciesConnection = {
  __typename?: 'Wallet_receiveCurrenciesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_ReceiveCurrenciesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Currency>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Wallet_ReceiveCurrenciesEdge = {
  __typename?: 'Wallet_receiveCurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Currency>;
};

/** A connection to a list of items. */
export type Wallet_RetreiveCurrenciesByCountryIsoCode3Connection = {
  __typename?: 'Wallet_retreiveCurrenciesByCountryIsoCode3Connection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_RetreiveCurrenciesByCountryIsoCode3Edge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Currency>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Wallet_RetreiveCurrenciesByCountryIsoCode3Edge = {
  __typename?: 'Wallet_retreiveCurrenciesByCountryIsoCode3Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Currency>;
};

/** A connection to a list of items. */
export type Wallet_SendCountriesConnection = {
  __typename?: 'Wallet_sendCountriesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_SendCountriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Country>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Wallet_SendCountriesEdge = {
  __typename?: 'Wallet_sendCountriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};

/** A connection to a list of items. */
export type Wallet_SendCurrenciesByCountryIsoCode3Connection = {
  __typename?: 'Wallet_sendCurrenciesByCountryIsoCode3Connection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_SendCurrenciesByCountryIsoCode3Edge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Currency>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Wallet_SendCurrenciesByCountryIsoCode3Edge = {
  __typename?: 'Wallet_sendCurrenciesByCountryIsoCode3Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Currency>;
};

/** A connection to a list of items. */
export type Wallet_SendCurrenciesConnection = {
  __typename?: 'Wallet_sendCurrenciesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<Wallet_SendCurrenciesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Currency>>>;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type Wallet_SendCurrenciesEdge = {
  __typename?: 'Wallet_sendCurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Currency>;
};

export type VerifyTransferTransactionMutationVariables = Exact<{
  data?: Maybe<VerifyTransferTransactionInput>;
}>;

export type VerifyTransferTransactionMutation = { __typename?: 'Mutation' } & {
  reports_verifyTransferTransaction?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfTransferTransaction' } & {
      data?: Maybe<
        { __typename?: 'TransferTransaction' } & Pick<
          TransferTransaction,
          'id' | 'checkOutUrl'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type PayTransferTransactionMutationVariables = Exact<{
  data?: Maybe<PayTransferTransactionInput>;
}>;

export type UpdateWalletUserMutationVariables = Exact<{
  data?: Maybe<UpdateUserInput>;
}>;

export type UpdateWalletUserMutation = { __typename?: 'Mutation' } & {
  wallet_updateUser?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfUser' } & {
      data?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type VerifySmsMutationVariables = Exact<{
  phoneCountryCode: Scalars['Int'];
  phoneNumber: Scalars['Long'];
  payydEnvironment: PayydEnvironment;
}>;

export type VerifySmsMutation = { __typename?: 'Mutation' } & {
  messagebird_createSmsVerify?: Maybe<
    { __typename?: 'VerifyModel' } & Pick<
      VerifyModel,
      | 'id'
      | 'createdDatetime'
      | 'status'
      | 'statusDescription'
      | 'validUntilDatetime'
    >
  >;
};

export type PayTransferTransactionMutation = { __typename?: 'Mutation' } & {
  reports_payTransferTransaction?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfTransferTransaction' } & {
      data?: Maybe<
        { __typename?: 'TransferTransaction' } & Pick<
          TransferTransaction,
          'id' | 'rapyd' | 'checkOutUrl' | 'stripe' | 'unlimint' | 'goCardless'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type RapydCompleteMutation = { __typename?: 'Mutation' } & {
  reports_rapydCompleteCheckout?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfTransferTransaction' } & {
      data?: Maybe<
        { __typename?: 'TransferTransaction' } & Pick<
          TransferTransaction,
          'id' | 'rapyd' | 'stripe' | 'unlimint' | 'goCardless'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type RapydCompleteMutationVariables = Exact<{
  internalRef?: Maybe<Scalars['String']>;
}>;

export type AddWalletUserLegalEntityMutationVariables = Exact<{
  data?: Maybe<AddLegalEntityInput>;
}>;

export type AddWalletUserLegalEntityMutation = { __typename?: 'Mutation' } & {
  wallet_addLegalEntity?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfLegalEntity' } & {
      data?: Maybe<{ __typename?: 'LegalEntity' } & Pick<LegalEntity, 'id'>>;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type GetInvoiceByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetNotificationByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GetInvoiceByIdQuery = { __typename?: 'Query' } & {
  reports_transferTransactionById?: Maybe<
    { __typename?: 'TransferTransaction' } & Pick<
      TransferTransaction,
      | 'id'
      | 'amount'
      | 'currencyCode'
      | 'country'
      | 'paymentPurpose'
      | 'paymentPurposeOther'
      | 'paymentPurposeDescription'
      | 'countryIsoCode3'
      | 'createdOn'
      | 'processingFee'
      | 'escrowDate'
      | 'checkOutUrl'
      | 'tax'
      | 'totalAmount'
      | 'transferTransactionTypeDescription'
      | 'transferTransactionStatusDescription'
      | 'billedHours'
      | 'internalRef'
      | 'transferTransactionStatus'
      | 'transferTransactionType'
      | 'reference'
    > & {
        rapyd?: Maybe<
          { __typename?: 'RapydTransferTransaction' } & Pick<
            RapydTransferTransaction,
            | 'checkoutRedirectUrl'
            | 'checkOutId'
            | 'checkOutStatus'
            | 'paymentStatus'
            | ('nextAction' & {
                instructions?: Maybe<
                  { __typename?: 'Instruction' } & Pick<
                    Instruction,
                    'name' | 'steps'
                  >
                >;
              })
          >
        >;
      }
  > & {
    unlimint?: Maybe<
      { __typename?: 'UnlimintTransferTransaction' } & Pick<
        UnlimintTransferTransaction,
        'checkoutUrl' | 'paymentStatus' | 'walletId'
      >
    >;
  } & {
    stripe?: Maybe<
      { __typename?: 'StripeTransferTransaction' } & Pick<
        StripeTransferTransaction,
        'checkoutStatus' | 'checkoutUrl'
      >
    >;
  } & {
    goCardless?: Maybe<
      { __typename?: 'GoCardlessTransferTransaction' } & Pick<
        GoCardlessTransferTransaction,
        'billingRequestId' | 'authorisationUrl'
      >
    >;
  } & {
    receiver?: Maybe<
      { __typename?: 'TransferTransactionReceiver' } & Pick<
        TransferTransactionReceiver,
        | 'emailAddress'
        | 'firstName'
        | 'middleName'
        | 'countryIsoCode3'
        | 'lastName'
        | 'phoneNumber'
        | 'businessName'
        | 'userId'
      >
    >;
    rapyd?: Maybe<
      { __typename?: 'Rapyd' } & Pick<
        RapydTransferTransaction,
        'checkOutStatus'
      >
    >;
    unlimint?: Maybe<
      { __typename?: 'Rapyd' } & Pick<UnlimintTransferTransaction, 'walletId'>
    >;
    stripe?: Maybe<
      { __typename?: 'Stripe' } & Pick<
        StripeTransferTransaction,
        'checkoutStatus'
      >
    >;
    sender?: Maybe<
      { __typename?: 'TransferTransactionSender' } & Pick<
        TransferTransactionSender,
        | 'id'
        | 'userId'
        | 'firstName'
        | 'middleName'
        | 'lastName'
        | 'businessName'
        | 'emailAddress'
        | 'phoneNumber'
        | ('primaryKYC' & {
            primaryKYC?: Maybe<
              { __typename?: 'primaryKYC' } & Pick<
                UserKyc,
                'id' | 'captureLink' | 'captureLinkExpiredAt' | 'profileStatus'
              >
            >;
          })
        | ('legalEntity' & {
            legalEntity?: Maybe<
              { __typename?: 'LegalEntity' } & Pick<
                LegalEntity,
                'primaryKYC' | 'primaryAddress'
              >
            >;
          })
        | ('phoneCountryCode' & {
            user?: Maybe<
              { __typename?: 'User' } & Pick<
                User,
                | 'lastName'
                | (('firstName' & {
                    primaryAddress?: Maybe<
                      { __typename?: 'UserAddress' } & Pick<
                        UserAddress,
                        'countryName' | 'city' | 'countryIsoCode3'
                      >
                    >;
                  }) & {
                    legalEntity?: Maybe<
                      { __typename?: 'LegalEntity' } & Pick<LegalEntity, 'id'>
                    >;
                  })
              >
            >;
          })
      >
    >;
  };
};

export type GetNotificationByIdQuery = { __typename?: 'Query' } & {
  subscription_activitiesByUserId?: Maybe<
    { __typename?: 'Subscription_activitiesByUserIdConnection' } & Pick<
      Subscription_activitiesByUserIdConnection,
      'totalCount'
    > & {
        nodes: Maybe<
          Array<
            { __typename?: 'Activity' } & Pick<
              Activity,
              'id' | 'userId' | 'shortDesc' | 'longDesc' | 'createdOn'
            >
          >
        >;
      }
  >;
};

export type GetInvoiceDetailsByRefQueryVariables = Exact<{
  internalRef?: Maybe<Scalars['String']>;
}>;
export type GetTransferTransactionByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
  where?: Maybe<TransferTransactionFilterInput>;
}>;
export type GetInvoiceDetailsByRefQuery = { __typename?: 'Query' } & {
  reports_transferTransactionByRef?: Maybe<
    { __typename?: 'TransferTransaction' } & Pick<
      TransferTransaction,
      | 'internalRef'
      | 'id'
      | 'checkOutUrl'
      | 'currencyCode'
      | 'amount'
      | 'totalAmount'
      | 'processingFee'
      | 'tax'
      | 'createdOn'
      | 'modifiedOn'
    > & {
        receiver?: Maybe<
          { __typename?: 'TransferTransactionReceiver' } & Pick<
            TransferTransactionReceiver,
            'firstName' | 'lastName' | 'countryIsoCode3'
          >
        >;
      }
  >;
};
export type GetPublicInvoiceDetailsByRefQuery = { __typename?: 'Query' } & {
  reports_publicTransferTransactionByRef?: Maybe<
    { __typename?: 'PublicTransferTransaction' } & Pick<
      PublicTransferTransaction,
      'internalRef' | 'id' | 'amount' | 'currencyCode'
    > & {
        receiver?: Maybe<
          { __typename?: 'PublicTransferTransactionReceiver' } & Pick<
            PublicTransferTransactionReceiver,
            'firstName' | 'lastName'
          >
        >;
      }
  >;
};

export type GetTransferTransactionById = { __typename?: 'Query' } & {
  reports_transferTransactionsByUserId?: Maybe<
    { __typename?: 'Reports_TransferTransactionsByUserIdConnection' } & Pick<
      Reports_TransferTransactionsByUserIdConnection,
      'totalCount'
    > & {
        pageInfo?: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  > & {
    nodes?: Maybe<
      Array<
        { __typename?: 'TransferTransaction' } & Pick<
          TransferTransaction,
          | 'amount'
          | 'id'
          | 'internalRef'
          | 'transferTransactionStatus'
          | 'transferTransactionStatusDescription'
          | 'processingFee'
          | 'tax'
          | 'paymentMethodSource'
          | 'totalAmount'
          | 'currencyCode'
          | 'createdOn'
          | 'modifiedOn'
        >
      >
    >;
  } & {
    stripe?: Maybe<
      { __typename?: 'StripeTransferTransaction' } & Pick<
        StripeTransferTransaction,
        'checkoutUrl'
      >
    >;
  } & {
    receiver?: Maybe<
      { __typename?: 'TransferTransactionReceiver' } & Pick<
        TransferTransactionReceiver,
        'firstName' | 'lastName' | 'emailAddress' | 'countryIsoCode3'
      >
    >;
  } & {
    sender?: Maybe<
      { __typename?: 'TransferTransactionSender' } & Pick<
        TransferTransactionSender,
        'firstName' | 'lastName'
      >
    >;
  };
};

export type GetWalletEnumsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWalletEnumsQuery = { __typename?: 'Query' } & {
  wallet_enums?: Maybe<
    Array<
      {
        __typename?: 'KeyValuePairOfStringAndListOfKeyValuePairOfStringAndString';
      } & Pick<
        KeyValuePairOfStringAndListOfKeyValuePairOfStringAndString,
        'key'
      > & {
          value: Array<
            { __typename?: 'KeyValuePairOfStringAndString' } & Pick<
              KeyValuePairOfStringAndString,
              'key' | 'value'
            >
          >;
        }
    >
  >;
};

export type GetWalletUserBySubQueryVariables = Exact<{
  sub?: Maybe<Scalars['String']>;
}>;

export type GetWalletGetIsMobileNumberExistsVariables = Exact<{
  phoneNumber?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
}>;

export type GetWalletUserBySubQuery = { __typename?: 'Query' } & {
  wallet_userBySub?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'lastName'
      | 'firstName'
      | 'middleName'
      | 'emailAddress'
      | 'countryIsoCode3'
      | 'phoneNumber'
      | 'phoneCountryCode'
      | 'bulkPay'
      | 'payNow'
      | 'countryIsoCode3'
      | 'primaryAddress'
      | 'emailNotification'
      | 'smsNotification'
      | 'uID'
      | 'taxID'
      | 'legalEntityId'
    > & {
        legalEntity?: Maybe<
          { __typename?: 'LegalEntity' } & Pick<
            LegalEntity,
            'id' | 'legalName' | 'registrationNumber'
          >
        >;
        primaryAddress?: Maybe<
          { __typename?: 'UserAddress' } & Pick<
            UserAddress,
            | 'id'
            | 'streetAddress'
            | 'city'
            | 'state'
            | 'zipCode'
            | 'stateIsoCode2'
          >
        >;
        primaryKYC?: Maybe<
          { __typename?: 'UserKYC' } & Pick<
            UserKyc,
            | 'id'
            | 'captureLink'
            | 'captureLinkExpiredAt'
            | 'profileId'
            | 'profileStatusDescription'
            | 'profileStatusReason'
          >
        >;
      }
  >;
};
export type GetWalletGetIsMobileNumberExists = { __typename?: 'Query' } & {
  wallet_isMobileNumberExists?: { __typename?: 'Scalars' } & Pick<
    Scalars,
    'Boolean'
  >;
};

export type GetWalletUserByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetWalletUserByIdQuery = { __typename?: 'Query' } & {
  wallet_userById?: Maybe<
    { __typename?: 'User' } & Pick<
      User,
      | 'id'
      | 'firstName'
      | 'middleName'
      | 'lastName'
      | 'phoneNumber'
      | 'emailAddress'
    > & {
        primaryAddress?: Maybe<
          { __typename?: 'UserAddress' } & Pick<
            UserAddress,
            | 'countryName'
            | 'streetAddress'
            | 'city'
            | 'state'
            | 'zipCode'
            | 'stateIsoCode2'
          >
        >;
        legalEntity?: Maybe<
          { __typename?: 'LegalEntity' } & Pick<
            LegalEntity,
            'id' | 'legalName' | 'registrationNumber'
          >
        >;
      }
  >;
};

export const VerifyTransferTransactionDocument = gql`
  mutation verifyTransferTransaction($data: VerifyTransferTransactionInput!) {
    reports_verifyTransferTransaction(input: $data) {
      data {
        id
        checkOutUrl
      }
      errors {
        code
        message
      }
    }
  }
`;

export const GetwalletCountries = gql`
  query wallet_sendCountries($first: Int!) {
    wallet_sendCountries(first: $first) {
      nodes {
        countryIsoCode3
        countryIsoCode2
        phoneCountryCode
        name
      }
    }
  }
`;

export type ListOfCountries = Node & {
  __typename?: 'ListOfCountries';
  countryIsoCode3?: Maybe<Scalars['String']>;
  countryIsoCode2?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
};

export type GetwalletCountriesIsoCode = { __typename?: 'Query' } & {
  wallet_sendCountries?: Maybe<
    { __typename?: 'ListOfCountries' } & Pick<
      ListOfCountries,
      'countryIsoCode3' | 'countryIsoCode2' | 'phoneCountryCode'
    >
  >;
};

export type GetwalletCountriesIsoCodeVaraibles = Exact<{
  countryIsoCode2: Scalars['ID'];
}>;
export type AddWalletUserMutationVariables = Exact<{
  data?: Maybe<AddSenderUserInput>;
}>;
export type AddUserSupportDocumentVariables = Exact<{
  data?: Maybe<ContactSupportInput>;
}>;
export type UpdateUserConfigMutationVariables = Exact<{
  data?: Maybe<TransactionPayConfigInput>;
}>;
export type UpdateUserNotificationMutationVariables = Exact<{
  data?: Maybe<UpdateUserNotificationInput>;
}>;

export type AddWalletUserMutation = { __typename?: 'Mutation' } & {
  wallet_addSenderUser?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfUser' } & {
      data?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'primaryAddress'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type AddUserSupportDocument = { __typename?: 'Mutation' } & {
  subscription_contactSupport?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfBoolean' } & {
      data?: Maybe<{ __typename?: 'User' }>;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type UpdateUserConfigMutation = { __typename?: 'Mutation' } & {
  wallet_updateUserNotification?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfUser' } & {
      data?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type UpdateUserNotificatonMutation = { __typename?: 'Mutation' } & {
  wallet_updateUserPayConfig?: Maybe<
    { __typename?: 'RegisterGenericPayloadOfUser' } & {
      data?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export function GetwalletCountriesIso(
  baseOptions: Apollo.QueryHookOptions<
    GetwalletCountriesIsoCode,
    GetwalletCountriesIsoCodeVaraibles
  >,
) {
  return Apollo.useQuery<
    GetwalletCountriesIsoCode,
    GetwalletCountriesIsoCodeVaraibles
  >(GetwalletCountries, baseOptions);
}

export function useAddWalletUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWalletUserMutation,
    AddWalletUserMutationVariables
  >,
) {
  return Apollo.useMutation<
    AddWalletUserMutation,
    AddWalletUserMutationVariables
  >(AddWalletUserDocument, baseOptions);
}

export function useAddUserSupportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserSupportDocument,
    AddUserSupportDocumentVariables
  >,
) {
  return Apollo.useMutation<
    AddUserSupportDocument,
    AddUserSupportDocumentVariables
  >(AddUserSupport, baseOptions);
}

export function useUpdateUserConfig(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserConfigMutation,
    UpdateUserConfigMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateUserConfigMutation,
    UpdateUserConfigMutationVariables
  >(UpdateUserConfig, baseOptions);
}

export function useUpdateUserNotifications(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserNotificatonMutation,
    UpdateUserNotificationMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateUserNotificatonMutation,
    UpdateUserNotificationMutationVariables
  >(UpdateUserNotification, baseOptions);
}

export type AddWalletUserMutationHookResult = ReturnType<
  typeof useAddWalletUserMutation
>;

export const AddWalletUserDocument = gql`
  mutation addSenderWalletUser($data: AddSenderUserInput!) {
    wallet_addSenderUser(input: $data) {
      data {
        id
        primaryAddress {
          city
          countryIsoCode3
          state
          stateIsoCode2
          streetAddress
          zipCode
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

export const AddUserSupport = gql`
  mutation addSenderWalletUser($data: ContactSupportInput) {
    subscription_contactSupport(input: $data) {
      data
      errors {
        code
        message
      }
    }
  }
`;

export const UpdateUserConfig = gql`
  mutation updateUserConfig($data: TransactionPayConfigInput) {
    wallet_updateUserPayConfig(input: $data) {
      data {
        id
      }
      errors {
        code
        message
      }
    }
  }
`;

export const UpdateUserNotification = gql`
  mutation updateUserNotification($data: UpdateUserNotificationInput) {
    wallet_updateUserNotification(input: $data) {
      data {
        id
      }
      errors {
        code
        message
      }
    }
  }
`;

export const reports_paymentMethodsByCountryIsoCode2 = gql`
  query WalletPaymentMethodsByCountryIsoCode2($countryIsoCode2: String!) {
    reports_paymentMethodsByCountryIsoCode2(countryIsoCode2: $countryIsoCode2) {
      type
      name
    }
  }
`;

export const reports_paymentMethodsByCountryIsoCode3 = gql`
  query WalletPaymentMethodsByCountryIsoCode3(
    $countryIsoCode3: CountryIsoCode3!
  ) {
    reports_paymentMethodsByCountryIsoCode3(countryIsoCode3: $countryIsoCode3) {
      type
      name
      category
      image
      paymentMethodSource
      walletId
    }
  }
`;

export type Method = Node & {
  __typename?: 'Method';
  type?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};
export type GetPaymentMethodsByCountryIsoCode2 = { __typename?: 'Query' } & {
  reports_paymentMethodsByCountryIsoCode2?: Maybe<
    { __typename?: 'Method' } & Pick<Method, 'name' | 'type'>
  >;
};

export type GetPaymentMethodsByCountryIsoCode3 = { __typename?: 'Query' } & {
  reports_paymentMethodsByCountryIsoCode3?: Maybe<
    { __typename?: 'Method' } & Pick<
      PaymentMethod,
      | 'name'
      | 'type'
      | 'category'
      | 'image'
      | 'paymentMethodSource'
      | 'walletId'
    >
  >;
};

export type GetPaymentMethodsByCountryIsoCode2Variables = Exact<{
  countryIsoCode2: Scalars['ID'];
}>;
export type GetPaymentMethodsByCountryIsoCode3Variables = Exact<{
  countryIsoCode3: Scalars['ID'];
}>;

export function usePaymentMethodsByCountryIsoCode2(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodsByCountryIsoCode2,
    GetPaymentMethodsByCountryIsoCode2Variables
  >,
) {
  return Apollo.useQuery<
    GetPaymentMethodsByCountryIsoCode2,
    GetPaymentMethodsByCountryIsoCode2Variables
  >(reports_paymentMethodsByCountryIsoCode2, baseOptions);
}

export function usePaymentMethodsByCountryIsoCode2Lazy(
  baseOptions: Apollo.LazyQueryHookOptions<
    GetPaymentMethodsByCountryIsoCode2,
    GetPaymentMethodsByCountryIsoCode2Variables
  >,
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodsByCountryIsoCode2,
    GetPaymentMethodsByCountryIsoCode2Variables
  >(reports_paymentMethodsByCountryIsoCode2, baseOptions);
}

export function usePaymentMethodsByCountryIsoCode3Lazy(
  baseOptions: Apollo.LazyQueryHookOptions<
    GetPaymentMethodsByCountryIsoCode3,
    GetPaymentMethodsByCountryIsoCode3Variables
  >,
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodsByCountryIsoCode3,
    GetPaymentMethodsByCountryIsoCode3Variables
  >(reports_paymentMethodsByCountryIsoCode3, baseOptions);
}

export type VerifyTransferTransactionMutationFn = Apollo.MutationFunction<
  VerifyTransferTransactionMutation,
  VerifyTransferTransactionMutationVariables
>;

/**
 * __useVerifyTransferTransactionMutation__
 *
 * To run a mutation, you first call `useVerifyTransferTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTransferTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTransferTransactionMutation, { data, loading, error }] = useVerifyTransferTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyTransferTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyTransferTransactionMutation,
    VerifyTransferTransactionMutationVariables
  >,
) {
  return Apollo.useMutation<
    VerifyTransferTransactionMutation,
    VerifyTransferTransactionMutationVariables
  >(VerifyTransferTransactionDocument, baseOptions);
}
export type VerifyTransferTransactionMutationHookResult = ReturnType<
  typeof useVerifyTransferTransactionMutation
>;
export type VerifyTransferTransactionMutationResult = Apollo.MutationResult<VerifyTransferTransactionMutation>;
export type VerifyTransferTransactionMutationOptions = Apollo.BaseMutationOptions<
  VerifyTransferTransactionMutation,
  VerifyTransferTransactionMutationVariables
>;

// https://wonderful-wave-005537910.1.azurestaticapps.net/rapyd_complete_checkout_url?merchantReferenceId=1652676149999
export type PayTransferTransactionMutationFn = Apollo.MutationFunction<
  PayTransferTransactionMutation,
  PayTransferTransactionMutationVariables
>;

/**
 * __usePayTransferTransactionMutation__
 *
 * To run a mutation, you first call `usePayTransferTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayTransferTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payTransferTransactionMutation, { data, loading, error }] = usePayTransferTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePayTransferTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayTransferTransactionMutation,
    PayTransferTransactionMutationVariables
  >,
) {
  return Apollo.useMutation<
    PayTransferTransactionMutation,
    PayTransferTransactionMutationVariables
  >(PayTransferTransactionDocument, baseOptions);
}
export type PayTransferTransactionMutationHookResult = ReturnType<
  typeof usePayTransferTransactionMutation
>;
export type PayTransferTransactionMutationResult = Apollo.MutationResult<PayTransferTransactionMutation>;
export type PayTransferTransactionMutationOptions = Apollo.BaseMutationOptions<
  PayTransferTransactionMutation,
  PayTransferTransactionMutationVariables
>;
export const UpdateWalletUserDocument = gql`
  mutation updateWalletUser($data: UpdateUserInput) {
    wallet_updateUser(input: $data) {
      data {
        id
      }
      errors {
        code
        message
      }
    }
  }
`;
export const walletAddUserAddress = gql`
  mutation addUserAddress($data: AddUserAddressInput!) {
    wallet_addUserAddress(input: $data) {
      data {
        id
        user {
          primaryKYC {
            captureLink
          }
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

export type UpdateWalletUserMutationFn = Apollo.MutationFunction<
  UpdateWalletUserMutation,
  UpdateWalletUserMutationVariables
>;

/**
 * __useUpdateWalletUserMutation__
 *
 * To run a mutation, you first call `useUpdateWalletUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWalletUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWalletUserMutation, { data, loading, error }] = useUpdateWalletUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWalletUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWalletUserMutation,
    UpdateWalletUserMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateWalletUserMutation,
    UpdateWalletUserMutationVariables
  >(UpdateWalletUserDocument, baseOptions);
}
export type UpdateWalletUserMutationHookResult = ReturnType<
  typeof useUpdateWalletUserMutation
>;
export type UpdateWalletUserMutationResult = Apollo.MutationResult<UpdateWalletUserMutation>;
export type UpdateWalletUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateWalletUserMutation,
  UpdateWalletUserMutationVariables
>;

export type VerifySmsMutationFn = Apollo.MutationFunction<
  VerifySmsMutation,
  VerifySmsMutationVariables
>;

/**
 * __useVerifySmsMutation__
 *
 * To run a mutation, you first call `useVerifySmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifySmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifySmsMutation, { data, loading, error }] = useVerifySmsMutation({
 *   variables: {
 *      recipient: // value for 'recipient'
 *      payydEnvironment: // value for 'payydEnvironment'
 *   },
 * });
 */

export function useRapydCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RapydCompleteMutation,
    RapydCompleteMutationVariables
  >,
) {
  return Apollo.useMutation<
    RapydCompleteMutation,
    RapydCompleteMutationVariables
  >(RapyydCompleteTransaction, baseOptions);
}

export const VerifySmsDocument = gql`
  mutation verifySMS(
    $phoneCountryCode: Int!
    $phoneNumber: Long!
    $payydEnvironment: PayydEnvironment!
  ) {
    messagebird_createSmsVerify(
      payydEnvironment: $payydEnvironment
      phoneCountryCode: $phoneCountryCode
      phoneNumber: $phoneNumber
    ) {
      id
      createdDatetime
      status
      statusDescription
      validUntilDatetime
    }
  }
`;

export function useVerifySmsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifySmsMutation,
    VerifySmsMutationVariables
  >,
) {
  return Apollo.useMutation<VerifySmsMutation, VerifySmsMutationVariables>(
    VerifySmsDocument,
    baseOptions,
  );
}

export const RapyydCompleteTransaction = gql`
  mutation rapydComplete($internalRef: String) {
    reports_rapydCompleteCheckout(internalRef: $internalRef) {
      data {
        id
        rapyd {
          checkOutStatus
        }
        stripe {
          checkoutUrl
          paymentMethod
        }
        unlimint {
          checkoutUrl
          paymentMethod
          walletId
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

export const PayTransferTransactionDocument = gql`
  mutation payTransferTransaction($data: PayTransferTransactionInput!) {
    reports_payTransferTransaction(input: $data) {
      data {
        id
        checkOutUrl
        rapyd {
          checkoutRedirectUrl
        }
        stripe {
          checkoutUrl
        }
        unlimint {
          checkoutUrl
          walletId
        }
        plaid {
          linkToken
          expiration
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

export const AddWalletUserLegalEntityDocument = gql`
  mutation addWalletUserLegalEntity($data: AddLegalEntityInput) {
    wallet_addLegalEntity(input: $data) {
      data {
        id
      }
      errors {
        code
        message
      }
    }
  }
`;
export type VerifySmsMutationHookResult = ReturnType<
  typeof useVerifySmsMutation
>;
export type VerifySmsMutationResult = Apollo.MutationResult<VerifySmsMutation>;
export type VerifySmsMutationOptions = Apollo.BaseMutationOptions<
  VerifySmsMutation,
  VerifySmsMutationVariables
>;

export type AddWalletUserLegalEntityMutationFn = Apollo.MutationFunction<
  AddWalletUserLegalEntityMutation,
  AddWalletUserLegalEntityMutationVariables
>;

/**
 * __useAddWalletUserLegalEntityMutation__
 *
 * To run a mutation, you first call `useAddWalletUserLegalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWalletUserLegalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWalletUserLegalEntityMutation, { data, loading, error }] = useAddWalletUserLegalEntityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddWalletUserLegalEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWalletUserLegalEntityMutation,
    AddWalletUserLegalEntityMutationVariables
  >,
) {
  return Apollo.useMutation<
    AddWalletUserLegalEntityMutation,
    AddWalletUserLegalEntityMutationVariables
  >(AddWalletUserLegalEntityDocument, baseOptions);
}
export type AddWalletUserLegalEntityMutationHookResult = ReturnType<
  typeof useAddWalletUserLegalEntityMutation
>;
export type AddWalletUserLegalEntityMutationResult = Apollo.MutationResult<AddWalletUserLegalEntityMutation>;
export type AddWalletUserLegalEntityMutationOptions = Apollo.BaseMutationOptions<
  AddWalletUserLegalEntityMutation,
  AddWalletUserLegalEntityMutationVariables
>;
export const GetInvoiceByIdDocument = gql`
  query getInvoiceById($id: ID!) {
    reports_transferTransactionById(id: $id) {
      id
      amount
      currencyCode
      paymentPurpose
      processingFee
      tax
      totalAmount
      country {
        name
      }
      countryIsoCode3
      escrowDate
      checkOutUrl
      rate
      rateSpread
      reference
      receivableAmount
      receivableCurrencyCode
      paymentPurposeOther
      # paymentPurposeDescription
      createdOn
      billedHours
      internalRef
      rapyd {
        checkOutStatus
        checkoutRedirectUrl
        paymentStatus
        instructions {
          name
          steps {
            step1
          }
        }
        nextAction
      }
      stripe {
        checkoutStatus
        checkoutUrl
      }
      unlimint {
        paymentStatus
        checkoutUrl
        walletId
      }
      transferTransactionStatus
      transferTransactionType
      receiver {
        emailAddress
        firstName
        middleName
        lastName
        countryIsoCode3
        phoneNumber
        businessName
        userId
      }
      sender {
        id
        userId
        firstName
        middleName
        lastName
        emailAddress
        businessName
        phoneNumber
        legalEntity {
          id
        }
        phoneCountryCode
        primaryKYC {
          id
          captureLink
          profileStatus
        }
        primaryAddress {
          countryName
          city
          countryIsoCode3
          streetAddress
          state
          stateIsoCode2
          zipCode
        }
      }
    }
  }
`;
export const GetNotificationByIdDocument = gql`
  query getNotificationById($userId: ID!) {
    subscription_activitiesByUserId(first: 20, userId: $userId) {
      totalCount
      nodes {
        userId
        shortDesc
        longDesc
        createdOn
        id
      }
    }
  }
`;

/**
 * __useGetInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useGetInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceByIdQuery,
    GetInvoiceByIdQueryVariables
  >,
) {
  return Apollo.useQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(
    GetInvoiceByIdDocument,
    baseOptions,
  );
}

export function useGetNotificationeByIdQuery(
  baseOptions: Apollo.LazyQueryHookOptions<
    GetNotificationByIdQuery,
    GetNotificationByIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetNotificationByIdQuery,
    GetNotificationByIdQueryVariables
  >(GetNotificationByIdDocument, baseOptions);
}

export function useGetInvoiceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceByIdQuery,
    GetInvoiceByIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(
    GetInvoiceByIdDocument,
    baseOptions,
  );
}
export type GetInvoiceByIdQueryHookResult = ReturnType<
  typeof useGetInvoiceByIdQuery
>;
export type GetInvoiceByIdLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceByIdLazyQuery
>;
export type GetInvoiceByIdQueryResult = Apollo.QueryResult<
  GetInvoiceByIdQuery,
  GetInvoiceByIdQueryVariables
>;
export const GetInvoiceDetailsByRefDocument = gql`
  query getInvoiceDetailsByRef($internalRef: String!) {
    reports_transferTransactionByRef(internalRef: $internalRef) {
      internalRef
      id
      currencyCode
      processingFee
      tax
      totalAmount
      amount
      checkOutUrl
      modifiedOn
      createdOn
      sender {
        id
        emailAddress
        firstName
        userId
        lastName
        phoneNumber
        phoneCountryCode
      }
      receiver {
        firstName
        lastName
        emailAddress
      }
    }
  }
`;

export const GetPublicInvoiceDetailsByRefDocument = gql`
  query getInvoiceDetailsByRef($internalRef: String!) {
    reports_transferTransactionByRef(internalRef: $internalRef) {
      internalRef
      id
      currencyCode
      createdOn
      amount
      receiver {
        firstName
        lastName
      }
    }
  }
`;

export const GetpaymentPurposesDocument = gql`
  query paymentPurposesConnection {
    wallet_paymentPurposes {
      nodes {
        id
        name
      }
    }
  }
`;
export const GetTransferTransactionByIdDocument = gql`
  query getTransferTransactionById($userId: ID!, $first: Int, $after: String) {
    reports_transferTransactionsByUserId(
      userId: $userId
      first: $first
      after: $after
      order: { createdOn: DESC }
      where: {
        and: {
          transferTransactionStatus: {
            in: [NONE, VERIFIED, INITIATED, IN_PROGRESS, PENDING]
          }
        }
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        amount
        id
        processingFee
        currencyCode
        createdOn
        internalRef
        modifiedOn
        tax
        rapyd {
          checkOutStatus
          checkoutRedirectUrl
          paymentId
          paymentStatus
          instructions {
            name
            steps {
              step1
            }
          }
          nextAction
        }
        stripe {
          checkoutUrl
        }
        unlimint {
          walletId
          checkoutUrl
        }

        totalAmount
        transferTransactionStatusDescription
        paymentMethodSource
        transferTransactionStatus
        sender {
          firstName
          lastName
        }
        receiver {
          firstName
          lastName
          emailAddress
        }
        __typename
      }
    }
  }
`;

export const GetTransferTransactionPaidByIdDocument = gql`
  query getTransferTransactionById($userId: ID!, $first: Int, $after: String) {
    reports_transferTransactionsByUserId(
      userId: $userId
      first: $first
      after: $after
      order: { createdOn: DESC }
      where: {
        and: {
          transferTransactionStatus: { in: [PAID, CANCELLED, EXPIRED, ERROR] }
        }
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        amount
        id
        processingFee
        currencyCode
        createdOn
        internalRef
        modifiedOn
        tax
        totalAmount
        stripe {
          checkoutUrl
        }
        unlimint {
          checkoutUrl
          walletId
        }
        rapyd {
          checkOutStatus
          checkoutRedirectUrl
          paymentId
          paymentStatus
          instructions {
            name
            steps {
              step1
            }
          }
          nextAction
        }
        transferTransactionStatusDescription
        transferTransactionStatus
        sender {
          firstName
          lastName
        }
        receiver {
          firstName
          lastName
          emailAddress
        }
        __typename
      }
    }
  }
`;

/**
 * __useGetInvoiceDetailsByRefQuery__
 *
 * To run a query within a React component, call `useGetInvoiceDetailsByRefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceDetailsByRefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceDetailsByRefQuery({
 *   variables: {
 *      internalRef: // value for 'internalRef'
 *   },
 * });
 */
export function useGetInvoiceDetailsByRefQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInvoiceDetailsByRefQuery,
    GetInvoiceDetailsByRefQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetInvoiceDetailsByRefQuery,
    GetInvoiceDetailsByRefQueryVariables
  >(GetInvoiceDetailsByRefDocument, baseOptions);
}

export function useGetPublicInvoiceDetailsByRefQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPublicInvoiceDetailsByRefQuery,
    GetInvoiceDetailsByRefQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetPublicInvoiceDetailsByRefQuery,
    GetInvoiceDetailsByRefQueryVariables
  >(GetPublicInvoiceDetailsByRefDocument, baseOptions);
}

export function useGetTransferTransactionByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransferTransactionById,
    GetTransferTransactionByIdQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetTransferTransactionById,
    GetTransferTransactionByIdQueryVariables
  >(GetTransferTransactionByIdDocument, baseOptions);
}

export function useGetTransferTransactionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransferTransactionById,
    GetTransferTransactionByIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetTransferTransactionById,
    GetTransferTransactionByIdQueryVariables
  >(GetTransferTransactionByIdDocument, baseOptions);
}

export function useGetTransferTransactionPaidByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransferTransactionById,
    GetTransferTransactionByIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetTransferTransactionById,
    GetTransferTransactionByIdQueryVariables
  >(GetTransferTransactionPaidByIdDocument, baseOptions);
}

export function useGetInvoiceDetailsByRefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceDetailsByRefQuery,
    GetInvoiceDetailsByRefQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetInvoiceDetailsByRefQuery,
    GetInvoiceDetailsByRefQueryVariables
  >(GetInvoiceDetailsByRefDocument, baseOptions);
}
export type GetInvoiceDetailsByRefQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsByRefQuery
>;
export type GetInvoiceDetailsByRefLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceDetailsByRefLazyQuery
>;
export type GetInvoiceDetailsByRefQueryResult = Apollo.QueryResult<
  GetInvoiceDetailsByRefQuery,
  GetInvoiceDetailsByRefQueryVariables
>;
export const GetWalletEnumsDocument = gql`
  query getWalletEnums {
    wallet_enums {
      key
      value {
        key
        value
      }
    }
  }
`;

/**
 * __useGetWalletEnumsQuery__
 *
 * To run a query within a React component, call `useGetWalletEnumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletEnumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletEnumsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWalletEnumsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWalletEnumsQuery,
    GetWalletEnumsQueryVariables
  >,
) {
  return Apollo.useQuery<GetWalletEnumsQuery, GetWalletEnumsQueryVariables>(
    GetWalletEnumsDocument,
    baseOptions,
  );
}
export function useGetWalletEnumsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletEnumsQuery,
    GetWalletEnumsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<GetWalletEnumsQuery, GetWalletEnumsQueryVariables>(
    GetWalletEnumsDocument,
    baseOptions,
  );
}
export type GetWalletEnumsQueryHookResult = ReturnType<
  typeof useGetWalletEnumsQuery
>;
export type GetWalletEnumsLazyQueryHookResult = ReturnType<
  typeof useGetWalletEnumsLazyQuery
>;
export type GetWalletEnumsQueryResult = Apollo.QueryResult<
  GetWalletEnumsQuery,
  GetWalletEnumsQueryVariables
>;
export const GetWalletUserBySubDocument = gql`
  query getWalletUserBySub($sub: String) {
    wallet_userBySub(sub: $sub) {
      id
      lastName
      firstName
      middleName
      emailAddress
      phoneNumber
      phoneCountryCode
      countryIsoCode3
      bulkPay
      payNow
      taxID
      emailNotification
      smsNotification
      uID
      primaryKYC {
        id
        captureLink
        captureLinkExpiredAt
        profileStatus
        profileId
        profileStatusDescription
      }
      legalEntity {
        id
        legalName
        registrationNumber
      }
      primaryAddress {
        id
        streetAddress
        city
        state
        stateIsoCode2
        zipCode
      }
    }
  }
`;

export const GetIsMobileNumberExistsQuery = gql`
  query getIsMobileNumberExists(
    $phoneNumber: String!
    $phoneCountryCode: String!
  ) {
    user_isMobileNumberExists(
      input: { phoneNumber: $phoneNumber, phoneCountryCode: $phoneCountryCode }
    )
  }
`;

/**
 * __useGetWalletUserBySubQuery__
 *
 * To run a query within a React component, call `useGetWalletUserBySubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletUserBySubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletUserBySubQuery({
 *   variables: {
 *      sub: // value for 'sub'
 *   },
 * });
 */
export function useGetWalletUserBySubQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWalletUserBySubQuery,
    GetWalletUserBySubQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetWalletUserBySubQuery,
    GetWalletUserBySubQueryVariables
  >(GetWalletUserBySubDocument, baseOptions);
}

export function useGetIsMobileNumberExistsQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletGetIsMobileNumberExists,
    GetWalletGetIsMobileNumberExistsVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetWalletGetIsMobileNumberExists,
    GetWalletGetIsMobileNumberExistsVariables
  >(GetIsMobileNumberExistsQuery, baseOptions);
}

export function useGetWalletUserBySubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletUserBySubQuery,
    GetWalletUserBySubQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetWalletUserBySubQuery,
    GetWalletUserBySubQueryVariables
  >(GetWalletUserBySubDocument, baseOptions);
}
export type GetWalletUserBySubQueryHookResult = ReturnType<
  typeof useGetWalletUserBySubQuery
>;
export type GetWalletUserBySubLazyQueryHookResult = ReturnType<
  typeof useGetWalletUserBySubLazyQuery
>;
export type GetWalletUserBySubQueryResult = Apollo.QueryResult<
  GetWalletUserBySubQuery,
  GetWalletUserBySubQueryVariables
>;
export const GetWalletUserByIdDocument = gql`
  query getWalletUserById($id: ID!) {
    wallet_userById(id: $id) {
      id
      firstName
      middleName
      lastName
      phoneNumber
      emailAddress
      primaryAddress {
        countryName
        streetAddress
        city
        state
        stateIsoCode2
        zipCode
      }
      legalEntity {
        id
        legalName
        registrationNumber
      }
    }
  }
`;

/**
 * __useGetWalletUserByIdQuery__
 *
 * To run a query within a React component, call `useGetWalletUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWalletUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWalletUserByIdQuery,
    GetWalletUserByIdQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetWalletUserByIdQuery,
    GetWalletUserByIdQueryVariables
  >(GetWalletUserByIdDocument, baseOptions);
}
export function useGetWalletUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletUserByIdQuery,
    GetWalletUserByIdQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetWalletUserByIdQuery,
    GetWalletUserByIdQueryVariables
  >(GetWalletUserByIdDocument, baseOptions);
}
export type GetWalletUserByIdQueryHookResult = ReturnType<
  typeof useGetWalletUserByIdQuery
>;
export type GetWalletUserByIdLazyQueryHookResult = ReturnType<
  typeof useGetWalletUserByIdLazyQuery
>;
export type GetWalletUserByIdQueryResult = Apollo.QueryResult<
  GetWalletUserByIdQuery,
  GetWalletUserByIdQueryVariables
>;

export const UPDATE_TRANSFER_TRANSACTION_STATUS = gql`
  mutation updateTransferTransferStatus(
    $data: UpdateTransferTransactionStatusInput
  ) {
    reports_updateTransferTransferStatus(input: $data) {
      data {
        id
      }
      errors {
        message
        code
      }
    }
  }
`;

export const DECLINE_TRANSFER_TRANSACTION = gql`
  mutation reports_declineTransferTransaction(
    $input: DeclineTransferTransactionInput!
  ) {
    reports_declineTransferTransaction(input: $input) {
      data {
        id
        transferTransactionStatus
      }
      errors {
        message
        code
      }
    }
  }
`;

export const GET_IDFY_STATUS = gql`
  subscription subscription_kycChanged($referenceId: String!) {
    subscription_kycChanged(referenceId: $referenceId) {
      referenceId
      profileStatus
    }
  }
`;
export const GET_COUNTRY_STATES = gql`
  query wallet_states($countryIsoCode2: String!) {
    wallet_states(countryIsoCode2: $countryIsoCode2, first: 50) {
      nodes {
        isoCode2
        name
      }
      totalCount
    }
  }
`;
