import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Skeleton } from '@mui/material';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from 'components/Box';
import Text from 'components/Text';
import { useGetTransferTransactionByIdLazyQuery } from 'generated/graphql';
import MUIDataTable from 'mui-datatables';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import heroBg from '../../assets/no-record.png';
import { formatMoney } from 'common/utils';

interface PendingProps {
  walletUserData?: any;
  props?: any;
}

const PaymentRequests = ({ walletUserData, props }: PendingProps) => {
  const history = useHistory();

  const [
    getPendingPayments,
    { data: pendingData, loading },
  ] = useGetTransferTransactionByIdLazyQuery({
    // @ts-ignore
    variables: { userId: walletUserData?.wallet_userBySub?.id, first: 40 },
  });

  useEffect(() => {
    try {
      getPendingPayments();
    } catch (e) {
      console.log(e);
    }
  }, [walletUserData, props]);
  // const getMuiTheme = () =>
  //   createTheme({
  //     components: {
  //       MuiTableCell: {
  //         styleOverrides: {
  //           root: {
  //             backgroundColor: '#F8F8F8',
  //           },
  //         },
  //       },
  //       MuiTableHead: {
  //         styleOverrides: {
  //           root: {
  //             backgroundColor: 'red',
  //           },
  //         },
  //       },
  //       MuiToolbar: {
  //         styleOverrides: {
  //           regular: {
  //             minHeight: '8px',
  //           },
  //         },
  //       },
  //     },
  //   });
  const columns = [
    {
      name: 'id',
      label: '',
      options: {
        display: false,
      },
    },
    {
      name: 'transferTransactionStatusDescription',
      label: '',
      options: {
        display: false,
      },
    },
    {
      name: 'fullName',
      label: 'From',

      options: {
        customBodyRender: (value) => {
          return <Text style={{ fontWeight: 400 }}>{value}</Text>;
        },

        filter: false,
        sort: true,
      },
    },
    {
      name: 'date',
      label: 'Due on',
      options: {
        customBodyRender: (value) => {
          return <Text style={{ fontWeight: 400 }}>{value}</Text>;
        },
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "amount",
    // },
    {
      name: 'amount',
      label: 'Amount Due',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Text style={{ fontWeight: 400 }}>
              {formatMoney(value.split(' ')[0])} {value.split(' ')[1]}
            </Text>
          );
        },
      },
    },

    {
      name: 'transferTransactionStatus',
      label: 'Status',

      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Text
              style={{
                color:
                  value === 'Processing'
                    ? '#EE4E64'
                    : value === 'Initiated'
                    ? '#4EC5EE'
                    : '#EEA54E',
                fontWeight: 600,
              }}
            >
              {value}
            </Text>
          );
        },
      },
    },
    {
      name: 'internalRef',
      label: '',
      options: {
        display: false,
      },
    },
    {
      name: 'totalAmount',
      label: '',
      options: {
        display: false,
      },
    },
    {
      name: 'id',
      label: 'Action',

      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, row) => {
          return (
            <Button
              variant="text"
              onClick={() => {
                if (
                  row.rowData[1] === 'In Progress' &&
                  (row.rowData[11] !== 'GO_CARDLESS' ||
                    row.rowData[11] !== 'UNLIMINT' ||
                    row.rowData[11] !== 'STRIPE')
                ) {
                  return history.push(
                    `/payment/success_payment?merchantReferenceId=${row.rowData[6]}`,
                  );
                }
                if (row.rowData[1] === 'In Progress') {
                  return history.push(
                    `/payment/inProcessing?merchantReferenceId=${row.rowData[6]}`,
                  );
                }
                if (row.rowData[1] === 'Initiated' && row.rowData[9]) {
                  return history.push(`/checkout?invoice=${value}`);
                } else {
                  history.push(`/checkout?invoice=${value}`);
                }
              }}
              endIcon={<ArrowForwardIosIcon />}
            >
              <strong>View</strong>
            </Button>
          );
        },
      },
    },
    {
      name: 'stripe',
      label: '',
      options: {
        display: false,
      },
    },
    {
      name: 'currencyCode',
      label: '',
      options: {
        display: false,
      },
    },
    {
      name: 'paymentMethodSource',
      label: '',
      options: {
        display: false,
      },
    },
  ];
  const LoadingState = () => {
    return (
      <Box width="80%">
        <Skeleton animation="wave" />
        <Skeleton animation="wave" width={200} />
        <Skeleton
          variant="rectangular"
          height={118}
          style={{ margin: '10px 0' }}
        />
        <Skeleton animation="wave" width={150} />
      </Box>
    );
  };
  const columnVisible = {
    transferTransactionStatusDescription: true,
    amount: false,
  };

  const options = {
    selectableRows: false,
    responsive: 'stacked',
    onRowClick: (e) => {
      if (
        (e[1] === 'In Progress' && e[11] === 'GO_CARDLESS') ||
        e[11] === 'UNLIMINT' ||
        e[11] === 'STRIPE'
      ) {
        return history.push(
          `/payment/success_payment?merchantReferenceId=${e[6]}`,
        );
      }
      if (e[1] === 'In Progress') {
        return history.push(
          `/payment/inProcessing?merchantReferenceId=${e[6]}`,
        );
      }
      if (e[1] === 'Initiated' && e[9]) {
        return history.push(`/checkout?invoice=${e[0]}`);
      } else {
        history.push(`/checkout?invoice=${e[0]}`);
      }
    },
    downloadOptions: {
      filename: 'PayydPendingInvoices.csv',
      separator: ',',
    },
  };
  return (
    // <ThemeProvider theme={getMuiTheme()}>
    <Box>
      {
        // @ts-ignore
        pendingData?.reports_transferTransactionsByUserId?.nodes?.length > 0 ? (
          <>
            <MUIDataTable
              // title={"Pending Payments"}
              title={
                <h2 style={{ zIndex: -10000, fontWeight: 700 }}>
                  Pending Payments
                </h2>
              }
              // @ts-ignore
              data={pendingData.reports_transferTransactionsByUserId.nodes.map(
                (item) => {
                  return {
                    ...item,
                    // @ts-ignore
                    fullName:
                      // @ts-ignore
                      item?.receiver.firstName +
                      ' ' +
                      // @ts-ignore
                      item?.receiver.lastName,
                    amount: item.totalAmount + ' ' + item.currencyCode,
                    // @ts-ignore
                    stripe: item?.stripe?.checkoutUrl,
                    date: item.createdOn?.split('T')[0],
                    // @ts-ignore
                    transferTransactionStatus:
                      // @ts-ignore
                      item?.transferTransactionStatus === 'IN_PROGRESS'
                        ? 'Processing'
                        : // @ts-ignore
                        item?.transferTransactionStatus === 'INITIATED'
                        ? 'Initiated'
                        : 'Pending',
                    paymentMethodSource: item.paymentMethodSource,
                  };
                },
              )}
              // data={pendingData.reports_transferTransactionsByUserId.nodes}
              columns={columns}
              options={options}
              columnVisibilityModel={columnVisible}
            />
          </>
        ) : (
          // <Text fontSize={16} fontWeight={600} marginTop={20} textAlign="center">
          //   No pending payments
          // </Text>
          <>
            {!loading ? (
              <>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img src={heroBg} alt="No transaction Found" />
                </Box>
                <Text
                  fontSize={16}
                  color="#6C7694"
                  fontWeight={600}
                  textAlign="center"
                >
                  No Pending Payments
                </Text>
              </>
            ) : (
              <LoadingState />
            )}
          </>
        )
      }{' '}
    </Box>
    // </ThemeProvider>
  );
};

export default PaymentRequests;
