import animationData from 'assets/lottie/fail.json';
import Box from 'components/Box';
import Text from 'components/Text';
import Lottie from 'react-lottie';

const PaymentReceipt = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box width="100%">
      <Box textAlign="center" maxWidth={550} width="100%" m="0 auto">
        <Box width={120} height={120} m="0 auto">
          <Lottie options={defaultOptions} height={120} width={120} />
        </Box>
        <Text fontSize={32} mt={6} mb={4} color="#20294C" fontWeight={600}>
          The transaction has now been declined. We have notified your supplier.
          If you wish to pay again please ask your supplier to create a new
          link.
        </Text>
      </Box>
    </Box>
  );
};

export default PaymentReceipt;
