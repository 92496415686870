import { Button, Stack } from '@mui/material';
import animationData from 'assets/lottie/fail.json';
import Box from 'components/Box';
import Text from 'components/Text';
import Lottie from 'react-lottie';

const PaymentReceipt = () => {
  const ref = 'PaymentReceipt';
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box width="100%">
      <Box textAlign="center" maxWidth={550} width="100%" m="0 auto">
        <Box width={120} height={120} m="0 auto">
          <Lottie options={defaultOptions} height={120} width={120} />
        </Box>
        <Text fontSize={32} mt={6} mb={4} color="#20294C" fontWeight={600}>
          Opps! Something went wrong
        </Text>
        <Text color="#697386">
          We are not able to process your payment at the moment, Please try
          again.
        </Text>

        <Stack spacing={2} direction="column" width={240} margin="20px auto">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              window.location.href =
                window.location.origin + `/checkout?ref=${ref}`;
            }}
          >
            Try again
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default PaymentReceipt;
