import Box from 'components/Box';
import PageLayout from 'components/PageLayout';
import errorBg from '../../assets/errorBg.png';
import PaymentReceipt from './PaymentReceipt';
import Logo from 'components/Logo';

function CheckoutFailed() {
  return (
    <PageLayout>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Logo invoice width={150} height={30} />
        <Box
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <img src={errorBg} alt="Invoice" />
        </Box>
      </Box>
      <Box>
        <PaymentReceipt />
      </Box>
    </PageLayout>
  );
}

export default CheckoutFailed;
