import colors from 'styles/colors';

interface Breakpoints extends Array<string> {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
}

let breakpoints: Breakpoints = ['600px', '900px', '1200px', '1600px'];

breakpoints = {
  ...breakpoints,
  ...{
    sm: breakpoints[0],
    md: breakpoints[1],
    lg: breakpoints[2],
    xl: breakpoints[3],
  },
};

export default {
  breakpoints,
  colors,
  space: [0, 5, 10, 15, 20, 25, 30],
};
