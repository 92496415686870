import { Button, Stack } from '@mui/material';
import animationData from 'assets/lottie/fail.json';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';

const PaymentReceipt = () => {
  const history = useHistory();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box width="100%" p="20px" display="flex">
      <Box textAlign="center" maxWidth={380} width="100%" m="0 auto">
        <Box width={120} height={120} m="0 auto">
          <Lottie options={defaultOptions} height={120} width={120} />
        </Box>
        <Text fontSize={27} mt={6} mb={4} fontWeight={500}>
          Payment failed
        </Text>
        <Text>We apologize, your payment could not be processed.</Text>
        <Text>Please try again.</Text>

        <Stack spacing={2} direction="column" width={240} margin="20px auto">
          <Button
            variant="contained"
            size="large"
            onClick={() => history.push('/dashboard')}
          >
            Try again
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default PaymentReceipt;
