import {
  Button,
  Dialog,
  DialogProps,
  Stack,
  experimentalStyled as styled,
} from '@mui/material';
import Box from 'components/Box';
import Text from 'components/Text';
import PrivacyPolicy from 'features/PrivacyPolicy';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useState } from 'react';
import { ReactComponent as StarLogo } from '../../assets/svg/starLogoBlue.svg';
import Logo from 'components/Logo';

const BlurryDialog = styled(Dialog)<DialogProps>(() => ({
  backdropFilter: 'blur(5px)',
  // other styles here...
}));

function LoginCard() {
  const { isMobile } = useMediaQuery();
  const [showModal, setShowModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  return (
    <Box
      width={{ _: '90%', sm: '90%', md: '60%', lg: '35%', xl: '30%' }}
      my={{
        _: 30,
        sm: 100,
        md: 0,
        lg: 0,
        xl: 0,
      }}
      p={{
        _: '10px 0px 10px 0px',
        sm: '30px 46px 30px 46px',
        md: '30px 46px 30px 46px',
        lg: '30px 46px 30px 46px',
        xl: '30px 46px 30px 46px',
      }}
      // margin={'auto'}
      display="flex"
      flexDirection="column"
      borderRadius={16}
      alignItems="center"
      backgroundColor={isMobile ? '' : '#fff'}
    >
      <BlurryDialog onClose={() => setShowModal(false)} open={showModal}>
        <Box maxWidth={375} padding={40} borderRadius="26px">
          <Stack spacing={2} direction="column" alignItems="center">
            <Box display="flex" alignItems="center">
              <Text ml={2} fontSize={18}>
                Getting an account
              </Text>
            </Box>
            <Text lineHeight="20px" textAlign="center" pb={2}>
              In order to create your account, you must receive an invoice to
              pay from one of our trusted providers on the Payyd network.
            </Text>

            <Button
              color="info"
              variant="contained"
              size="large"
              onClick={() => setShowModal(false)}
            >
              Got it
            </Button>
          </Stack>
        </Box>
      </BlurryDialog>

      <BlurryDialog
        onClose={() => setShowTermsModal(false)}
        open={showTermsModal}
        style={{ overflow: 'hidden' }}
      >
        <PrivacyPolicy setShowTermsModal={setShowTermsModal} />
      </BlurryDialog>

      <Box>
        {isMobile && (
          <Box
            display="flex"
            mx="auto"
            alignItems="center"
            justifyContent="center"
            mb={50}
          >
            <Logo />
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <StarLogo width={60} height={60} />
          <Text
            ml={5}
            fontSize={{ _: 28, sm: 22, md: 22, lg: 28, xl: 34 }}
            color="#20294C"
            lineHeight={1.5}
            fontWeight={600}
          >
            New & Improved Payment Process
          </Text>
        </Box>
        <Text
          fontWeight={700}
          fontSize={{ _: 16, sm: 16, md: 16, lg: 18, xl: 20 }}
          my={4}
          color="#0060FF"
        >
          No more Login`s or Sign-in`s
        </Text>

        <Text
          color="#979797"
          fontSize={{ _: 16, sm: 16, md: 16, lg: 18, xl: 20 }}
          fontWeight={700}
        >
          We have removed the login requirement to view and pay. Please click on
          the payment link you received over email or directly from your
          supplier which will take you directly to the checkout page.
        </Text>
        <Text
          fontWeight={700}
          fontSize={{ _: 16, sm: 16, md: 16, lg: 18, xl: 20 }}
          my={4}
          color="#0060FF"
        >
          We are updating this website as well. Stay tuned for a new look.
        </Text>

        <Text
          fontWeight={700}
          fontSize={{ _: 20, sm: 20, md: 20, lg: 22, xl: 24 }}
          color="#979797"
        >
          Thank you
        </Text>
      </Box>
    </Box>
  );
}

export default LoginCard;
