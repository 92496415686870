// import Box from 'components/Box';
// import COLORS from 'styles/colors';
// // import { ReactComponent as SvgPeople } from 'assets/svg/people.svg';

// function LoginPage() {
//   return (
//     <Box
//       width="100%"
//       height="100vh"
//       minHeight={500}
//       backgroundColor="gray.0"
//       position="relative"
//       overflow="hidden"
//     >
//       <Box
//         position="absolute"
//         bottom={0}
//         left={0}
//         height={{ _: '20px', sm: '150px', md: '200px' }}
//         width="100%"
//         backgroundColor="primary"
//         css={`
//           &:after {
//             position: absolute;
//             height: 70px;
//             width: 100%;
//             background: ${COLORS.primary};
//             top: -70px;
//             left: 0;
//             border-top-left-radius: 50%;
//             border-top-right-radius: 50%;
//             content: '';
//           }
//         `}
//       >
//         <Box
//           position="absolute"
//           left="50%"
//           top="-200px"
//           transform="translateX(-50%)"
//           zIndex={1}
//           width="100%"
//           maxWidth="1000px"
//         >
//           {/* <SvgPeople width="100%" /> */}
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// export default LoginPage;

import { Box, Paper } from '@mui/material';
import PayForm from 'features/PayFormReturning';
import { useMediaQuery } from 'hooks/useMediaQuery';
import backgoundImg from '../../assets/backgound.png';
import backgoundWhite from '../../assets/backgoundWhite.png';

import BottomNav from 'components/BottomNav';
import Logo from 'components/Logo';

const stylesDesk = {
  paperContainer: {
    width: '100%',
    // maxWidth:"1500px",
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: `url(${backgoundImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    paddingLeft: '100px',
    height: '100vh',
    paddingRight: '100px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#0048BE',
  },
};
const stylesMob = {
  paperContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'scroll',
    // height: '100vh',
    backgroundImage: `url(${backgoundWhite})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',

    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
  },
};

function LoginPage() {
  const { isMobile } = useMediaQuery();

  return (
    <Paper
      sx={!isMobile ? stylesDesk.paperContainer : stylesMob.paperContainer}
    >
      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            left: 20,
          }}
        >
          <Logo light />
        </Box>
      )}
      <PayForm />
      <BottomNav />
    </Paper>
  );
}

export default LoginPage;
