import Box from 'components/Box';
import PageLayout from 'components/PageLayout';
import Text from 'components/Text';

function FAQ() {
  return (
    <PageLayout>
      <Box width="100%" p="20px" display="flex">
        <Box textAlign="center" maxWidth="762px" width="100%" m="0 auto">
          <Text fontSize={31} mt={6} mb={4} fontWeight={500} color="#3D4251">
            Frequently Asked Questions
          </Text>

          <Box
            backgroundColor="#F2F5F9"
            marginTop={4}
            borderRadius="16px"
            padding={30}
            minHeight="151px"
            display="block"
            justifyContent="space-between"
            width="100%"
          >
            <Text
              mb={1}
              fontSize={18}
              color="#3D4251"
              fontWeight={600}
              textAlign="left"
            >
              What does Payyd do?
            </Text>
            <Text
              mt={20}
              fontSize={16}
              color="#3D4251"
              fontWeight={400}
              textAlign="left"
            >
              Payyd is a global invoicing app that advance pays its service
              provider`s while collecting invoices from payors globally
            </Text>
          </Box>
          <Box
            backgroundColor="#F2F5F9"
            marginTop={4}
            borderRadius="16px"
            padding={30}
            minHeight="151px"
            display="block"
            justifyContent="space-between"
            width="100%"
          >
            <Text
              mb={1}
              fontSize={18}
              color="#3D4251"
              fontWeight={600}
              textAlign="left"
            >
              Can I pay anyone through your service?
            </Text>
            <Text
              mt={20}
              fontSize={16}
              color="#3D4251"
              fontWeight={400}
              textAlign="left"
            >
              No not yet, you need to have pending invoices from a Payyd service
              provider to be able to use the service
            </Text>
          </Box>
          <Box
            backgroundColor="#F2F5F9"
            marginTop={4}
            borderRadius="16px"
            padding={30}
            minHeight="151px"
            display="block"
            justifyContent="space-between"
            width="100%"
          >
            <Text
              mb={1}
              fontSize={18}
              color="#3D4251"
              fontWeight={600}
              textAlign="left"
            >
              What payment methods do you offer for payment?
            </Text>
            <Text
              mt={20}
              fontSize={16}
              color="#3D4251"
              fontWeight={400}
              textAlign="left"
            >
              Payyd only offers local payment methods associated with your
              country.
            </Text>
          </Box>
          <Box
            backgroundColor="#F2F5F9"
            marginTop={4}
            borderRadius="16px"
            padding={30}
            minHeight="151px"
            display="block"
            justifyContent="space-between"
            width="100%"
          >
            <Text
              mb={1}
              fontSize={18}
              color="#3D4251"
              fontWeight={600}
              textAlign="left"
            >
              Is Payyd similar to Wires/ACH or Paypal?
            </Text>
            <Text
              mt={20}
              fontSize={16}
              color="#3D4251"
              fontWeight={400}
              textAlign="left"
            >
              No, Payyd does not transfer money overseas on your behalf. When
              you pay an invoice you are using paying Payyd for service rendered
              by its service providers.
            </Text>
          </Box>
          <Box
            backgroundColor="#F2F5F9"
            marginTop={4}
            borderRadius="16px"
            padding={30}
            minHeight="151px"
            display="block"
            justifyContent="space-between"
            width="100%"
          >
            <Text
              mb={1}
              fontSize={18}
              color="#3D4251"
              fontWeight={600}
              textAlign="left"
            >
              Does Payyd charge a fee?
            </Text>
            <Text
              mt={20}
              fontSize={16}
              color="#3D4251"
              fontWeight={400}
              textAlign="left"
            >
              Payyd only charges a nominal processing fee for providing a
              service and collecting funds locally.
            </Text>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
}

export default FAQ;
