import { useMedia } from 'react-use';

export const useMediaQuery = () => {
  const isExtraLarge = useMedia('(min-width: 1800px)');
  const isLarge = useMedia('(min-width: 1400px)');
  const isMedium = useMedia('(min-width: 1000px)');
  const isSmall = useMedia('(min-width: 600px)');
  const isMobile = useMedia('(max-width: 500px)');
  const isMobileOrTab = useMedia('(max-width: 900px)');

  return { isLarge, isMedium, isSmall, isExtraLarge, isMobile, isMobileOrTab };
};
