import { Button, Divider, Stack } from '@mui/material';
// import animationData from 'assets/lottie/success.json';
import Box from 'components/Box';
import Text from 'components/Text';
import { useGetInvoiceDetailsByRefQuery } from 'generated/graphql';
import { createRef } from 'react';
// import Lottie from 'react-lottie';
import { useHistory, useLocation } from 'react-router-dom';
import animationData from 'assets/lottie/processing.json';
import Lottie from 'react-lottie';

const ref = createRef();
/* eslint-disable new-cap */
// const doc = new jsPDF();
const PaymentReceipt = () => {
  // const takeScreenShot = async (node) => {
  //   const dataURI = await htmlToImage.toJpeg(node);
  //   return dataURI;
  // };

  // const download = (image) => {
  //   doc.setFillColor('245');
  //   doc.addImage(image, 'JPEG', 4, 0, 200, 200);
  //   doc.save('Transaction.pdf');
  // };

  // const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  const history = useHistory();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const merchantId = params.get('merchantReferenceId') || '';

  const data = useGetInvoiceDetailsByRefQuery({
    variables: { internalRef: merchantId },
  });

  const invoice = data?.data?.reports_transferTransactionByRef;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box width="100%" p="20px" display="flex">
      <Box textAlign="center" maxWidth={380} width="100%" m="0 auto">
        <div
          // @ts-ignore
          ref={ref}
          style={{ backgroundColor: '#fff' }}
        >
          <Box width={150} height={150} m="0 auto">
            <Lottie options={defaultOptions} height={150} width={150} />
          </Box>
          <Text fontSize={27} mb={4} fontWeight={700}>
            Processing Transaction
          </Text>
          <Stack direction="column" mb="40px" width="100%" spacing="10px">
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={15} fontWeight={600}>
                Status
              </Text>
              <Text fontSize={15} color="#6BBE2C" textAlign="right">
                In Process
              </Text>
            </Box>
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={15} fontWeight={600}>
                Payment date
              </Text>
              <Text fontSize={15} textAlign="right">
                {invoice?.modifiedOn.split('T')[0]}
              </Text>
            </Box>
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={15} fontWeight={600}>
                Reference number
              </Text>
              <Text fontSize={15} textAlign="right">
                {
                  // @ts-ignore
                  invoice?.internalRef
                }
              </Text>
            </Box>
            <Divider />
            <Text fontSize={15} fontWeight={600} textAlign="left">
              Service Provider
            </Text>
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={12} color="#6C7694">
                Name
              </Text>
              <Text fontSize={12} textAlign="right" fontWeight={600}>
                {`${
                  // @ts-ignore
                  invoice?.receiver?.firstName
                } ${
                  // @ts-ignore
                  invoice?.receiver?.lastName
                }`}
              </Text>
            </Box>
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={12} color="#6C7694">
                Email
              </Text>
              <Text fontSize={12} textAlign="right" fontWeight={600}>
                {
                  // @ts-ignore
                  invoice?.receiver?.emailAddress
                }
              </Text>
            </Box>
            <Divider />
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={12} color="#6C7694">
                Total
              </Text>
              <Text fontSize={12} textAlign="right" fontWeight={600}>
                {`${parseFloat(
                  // @ts-ignore
                  invoice?.amount ? invoice?.amount.toString() : '0.00',
                ).toFixed(2)} `}
                {
                  // @ts-ignore
                  invoice?.currencyCode
                }
              </Text>
            </Box>
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={12} color="#6C7694">
                Processing fee
              </Text>
              <Text fontSize={12} textAlign="right" fontWeight={600}>
                {`${parseFloat(
                  // @ts-ignore
                  invoice?.totalAmount
                    ? // @ts-ignore
                      invoice?.processingFee
                    : '0.00',
                ).toFixed(2)} `}
                {
                  // @ts-ignore
                  invoice?.currencyCode
                }
              </Text>
            </Box>
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={12} color="#6C7694">
                Taxes
              </Text>
              <Text fontSize={12} textAlign="right" fontWeight={600}>
                {`${parseFloat(
                  // @ts-ignore
                  invoice?.totalAmount ? invoice?.tax?.toString() : '0.00',
                ).toFixed(2)} `}
                {
                  // @ts-ignore
                  invoice?.currencyCode
                }
              </Text>
            </Box>
            <Box justifyContent="space-between" display="flex">
              <Text fontSize={18} fontWeight={600}>
                Amount Paid
              </Text>
              <Text fontSize={18} textAlign="right" fontWeight={600}>
                {`${parseFloat(
                  // @ts-ignore
                  invoice?.totalAmount
                    ? // @ts-ignore
                      invoice?.totalAmount.toString()
                    : '0.00',
                ).toFixed(2)} `}
                {
                  // @ts-ignore
                  invoice?.currencyCode
                }
              </Text>
            </Box>
          </Stack>
        </div>
        <Stack spacing={2} direction="column" width={240} margin="20px auto">
          {/* <Button
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={() => {
              downloadScreenshot();
            }}
            size="large"
          >
            Download PDF
          </Button> */}
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            Back to dashboard
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default PaymentReceipt;
