import { useMutation } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
} from '@mui/material';
import { message } from 'antd';
import { toTitleCase } from 'common/utils';
import Box from 'components/Box';
import Text from 'components/Text';
import {
  DECLINE_TRANSFER_TRANSACTION,
  TransferTransaction,
} from 'generated/graphql';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BankLogo } from '../../assets/svg/bank-checkout.svg';
import { ReactComponent as CardLogo } from '../../assets/svg/card-checkout.svg';
import './checkout.css';
import BackButton from 'common/BackButton';
type Transfer = {
  invoice: TransferTransaction;
  setStep: (step: number) => void;
};

function DeclinePayment({ invoice, setStep }: Transfer) {
  const history = useHistory();
  const [isDeclined, setIsDeclined] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [otherReason, setOtherReason] = useState<string | null>(null);
  const [declinePayment, { loading }] = useMutation(
    DECLINE_TRANSFER_TRANSACTION,
  );
  const reasons = [
    'Incorrect payment amount',
    'Fraudulent request suspected',
    'Disagreement with the seller',
    'Issue with mode of payment',
    'Duplicate payment request',
    'Insufficient funds',
    'Other (select to specify the reason)',
  ];

  const handleReasonChange = (reason: string) => {
    setSelectedReason(reason);
  };
  const disableDecline =
    !selectedReason ||
    (selectedReason === 'Other (select to specify the reason)' && !otherReason);
  const handleDecline = async () => {
    try {
      const { data } = await declinePayment({
        variables: {
          input: {
            reason: selectedReason?.includes('Other')
              ? otherReason
              : selectedReason,
            senderEmailAddress: invoice?.sender?.emailAddress,
            transferTransactionId: invoice?.id,
          },
        },
      });

      if (data?.reports_declineTransferTransaction?.data) {
        history.push(
          `/checkout/declined?merchantReferenceId=${invoice.internalRef}`,
        );
      } else {
        message.error(
          data?.reports_declineTransferTransaction?.errors[0]?.message,
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const renderDeclinedState = () => (
    <>
      <BackButton onClick={() => setIsDeclined(false)} />
      <Text
        fontWeight={600}
        color="#1A1F36"
        fontSize={{ _: '18px', xl: '22px', lg: '22px' }}
      >
        Please specify a reason for declining the payment request:
      </Text>
      <FormControl>
        <RadioGroup
          sx={{ mt: 3 }}
          aria-labelledby="decline-reasons-label"
          name="decline-reasons-group"
          value={selectedReason}
          onChange={(e) => handleReasonChange(e.target.value)}
        >
          {reasons.map((reason, index) => (
            <FormControlLabel
              key={index}
              value={reason}
              sx={{
                fontSize: { _: '16px', xl: '20px', lg: '20px' },
                color: '#697386',
              }}
              control={<Radio />}
              label={reason}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {selectedReason?.includes('Other') && (
        <TextField
          type="text"
          size="small"
          label="Other reason"
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
          sx={{ marginTop: 2, width: '100%' }}
          variant="outlined"
        />
      )}
      <LoadingButton
        sx={{
          width: '100%',
          fontWeight: '600',
          padding: '12px 20px',
          borderRadius: '10px',
          marginTop: '6vh',
          color: '#FFFFFF',
          background: '#0060FF',
          boxShadow: 'none',
          '&:hover': {
            opacity: '0.9 !important',
            background: '#0060FF !important',
            boxShadow: 'none',
          },
        }}
        loading={loading}
        disabled={disableDecline}
        onClick={() => handleDecline()}
        variant="contained"
        color="primary"
        size="large"
      >
        Decline Payment
      </LoadingButton>
    </>
  );

  const renderPendingState = () => (
    <>
      {invoice?.id ? (
        <>
          <Text
            fontWeight={700}
            color="#0060FF"
            fontSize={{ _: '24px', xl: '28px', lg: '28px' }}
          >
            Hey {toTitleCase(invoice?.sender?.firstName || '')}{' '}
            {toTitleCase(invoice?.sender?.lastName || '')},
          </Text>
          <Text mt={30} fontWeight={500} color="#697386" fontSize={'16px'}>
            {toTitleCase(invoice?.receiver?.firstName || '')}{' '}
            {toTitleCase(invoice?.receiver?.lastName || '')} has requested a
            payment of {invoice?.currencyCode} {invoice?.amount} through Payyd.
            Before proceeding, please verify that the information provided by
            the seller is correct. If you do not wish to proceed with the
            payment, you can decline the payment.
          </Text>
          <Box
            width="100%"
            borderRadius="6px"
            border="1px solid #DDE3E9"
            mt={30}
            p={30}
          >
            <Box display="flex">
              <CardLogo height={40} width={40} />
              <BankLogo height={40} width={40} style={{ marginLeft: '10px' }} />
            </Box>
            <Text
              mt={14}
              fontWeight={400}
              lineHeight={1.4}
              color="#697386"
              fontSize={'16px'}
            >
              <span style={{ fontWeight: 600 }}>Please note:</span> We only
              support{' '}
              <span style={{ color: '#0060FF' }}>
                direct bank-to-bank transfers.
              </span>{' '}
              Credit and debit cards are not accepted at the moment.
            </Text>
          </Box>
          <LoadingButton
            sx={{
              width: '100%',
              fontWeight: '600',
              padding: '12px 20px',
              borderRadius: '10px',
              marginTop: '6vh',
              color: '#FFFFFF',
              background: '#0060FF',
              boxShadow: 'none',
              '&:hover': {
                opacity: '0.9 !important',
                background: '#0060FF !important',
                boxShadow: 'none',
              },
            }}
            onClick={() => setStep(1)}
            variant="contained"
            color="primary"
            size="large"
          >
            Proceed to Pay
          </LoadingButton>
          <Button
            sx={{
              width: '100%',
              fontWeight: '600',
              padding: '12px 20px',
              borderRadius: '10px',
              background: '#F3F8FD',
              color: '#0060FF',
              marginTop: '3vh',
              boxShadow: 'none',
              border: '1px solid #DDE3E9',
              '&:hover': {
                background: '#FFFFFF !important',
                boxShadow: 'none',
              },
            }}
            onClick={() => setIsDeclined(true)}
            variant="contained"
            color="primary"
            size="large"
          >
            Decline to Pay
          </Button>
        </>
      ) : (
        <Box width="100%">
          <Skeleton animation="wave" />
          <Skeleton animation="wave" width={200} />
          <Skeleton
            variant="rectangular"
            height={118}
            style={{ margin: '10px 0' }}
          />
          <Skeleton animation="wave" width={150} />
          <Skeleton
            variant="rectangular"
            height={118}
            style={{ margin: '10px 0' }}
          />
        </Box>
      )}
    </>
  );

  return (
    <Box p={4}>{isDeclined ? renderDeclinedState() : renderPendingState()}</Box>
  );
}

export default DeclinePayment;
