import { Divider, Stack } from '@mui/material';
import animationData from 'assets/lottie/processing.json';
import Box from 'components/Box';
import Logo from 'components/Logo';
import Text from 'components/Text';
import { useGetInvoiceDetailsByRefQuery } from 'generated/graphql';
import Lottie from 'react-lottie';
import { useLocation } from 'react-router-dom';

const PaymentReceipt = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const merchantId = params.get('merchantReferenceId') || '';

  const data = useGetInvoiceDetailsByRefQuery({
    variables: { internalRef: merchantId },
  });

  const invoice = data?.data?.reports_transferTransactionByRef;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box width="100%" display="flex">
      <Box
        style={{
          position: 'absolute',
          top: 20,
          left: 20,
        }}
      >
        <Logo invoice />
      </Box>
      <Box textAlign="center" maxWidth={400} width="100%" m="0 auto">
        <Box width={120} height={120} m="0 auto">
          <Lottie options={defaultOptions} height={120} width={120} />
        </Box>
        <Text fontSize={27} mt={2} mb={4} fontWeight={500}>
          Processing Transaction
        </Text>
        <Text mb={4}>
          Thank you for using Payyd. Once your funds clear we will notify you
          and your supplier via email with a payment receipt. No further action
          is needed at the moment
        </Text>

        <Stack
          boxShadow={'-1px -1px 5px 2px rgba(0,0,0,0.15)'}
          p={3}
          direction="column"
          width="100%"
          spacing="10px"
        >
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={15} fontWeight={600}>
              Status
            </Text>
            <Text fontSize={15} color="#6BBE2C" textAlign="right">
              Completed
            </Text>
          </Box>
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={15} fontWeight={600}>
              Payment date
            </Text>
            <Text fontSize={15} textAlign="right">
              {invoice?.modifiedOn.split('T')[0]}
            </Text>
          </Box>
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={15} fontWeight={600}>
              Reference number
            </Text>
            <Text fontSize={15} textAlign="right">
              {
                // @ts-ignore
                invoice?.internalRef
              }
            </Text>
          </Box>
          <Divider />
          <Text fontSize={15} fontWeight={600} textAlign="left">
            Service Provider
          </Text>
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={12} color="#6C7694">
              Name
            </Text>
            <Text fontSize={12} textAlign="right" fontWeight={600}>
              {`${
                // @ts-ignore
                invoice?.receiver?.firstName
              } ${
                // @ts-ignore
                invoice?.receiver?.lastName
              }`}
            </Text>
          </Box>
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={12} color="#6C7694">
              Email
            </Text>
            <Text fontSize={12} textAlign="right" fontWeight={600}>
              {
                // @ts-ignore
                invoice?.receiver?.emailAddress
              }
            </Text>
          </Box>
          <Divider />
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={12} color="#6C7694">
              Total
            </Text>
            <Text fontSize={12} textAlign="right" fontWeight={600}>
              {`${parseFloat(
                // @ts-ignore
                invoice?.amount ? invoice?.amount.toString() : '0.00',
              ).toFixed(2)} `}
              {
                // @ts-ignore
                invoice?.currencyCode
              }
            </Text>
          </Box>
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={12} color="#6C7694">
              Processing fee
            </Text>
            <Text fontSize={12} textAlign="right" fontWeight={600}>
              {`${parseFloat(
                // @ts-ignore
                invoice?.totalAmount
                  ? // @ts-ignore
                    invoice?.processingFee
                  : '0.00',
              ).toFixed(2)} `}
              {
                // @ts-ignore
                invoice?.currencyCode
              }
            </Text>
          </Box>
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={12} color="#6C7694">
              Taxes
            </Text>
            <Text fontSize={12} textAlign="right" fontWeight={600}>
              {`${parseFloat(
                // @ts-ignore
                invoice?.totalAmount ? invoice?.tax?.toString() : '0.00',
              ).toFixed(2)} `}
              {
                // @ts-ignore
                invoice?.currencyCode
              }
            </Text>
          </Box>
          <Box justifyContent="space-between" display="flex">
            <Text fontSize={18} fontWeight={600}>
              Amount Paid
            </Text>
            <Text fontSize={18} textAlign="right" fontWeight={600}>
              {`${parseFloat(
                // @ts-ignore
                invoice?.totalAmount
                  ? // @ts-ignore
                    invoice?.totalAmount.toString()
                  : '0.00',
              ).toFixed(2)} `}
              {
                // @ts-ignore
                invoice?.currencyCode
              }
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default PaymentReceipt;
