import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { TransferTransaction, User } from 'generated/graphql';
import { TWalletUser } from 'types/globalTypes';

interface StateType {
  invoice: TransferTransaction;
  walletUser: User | TWalletUser;
  rapyydInput: object;
  currentStep: string;
}

const initialState = {
  invoice: {} as TransferTransaction,
  walletUser: {},
  rapyydInput: {},
  currentStep: 'information', // information | payment | receipt
} as StateType;

export const slice = createSlice({
  name: 'invoicePay',
  initialState,
  reducers: {
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    setWalletUser: (state, action) => {
      state.walletUser = action.payload;
    },
    setRapyydInput: (state, action) => {
      state.rapyydInput = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
  },
  extraReducers: () => {},
});

export const {
  setInvoice,
  setWalletUser,
  setCurrentStep,
  setRapyydInput,
} = slice.actions;

export const selectCurrentStep = (state: RootState): string =>
  state.invoicePay.currentStep;
export const selectInvoice = (state: RootState): TransferTransaction =>
  state.invoicePay.invoice;
export const selectWalletUser = (state: RootState): TWalletUser | User =>
  state.invoicePay.walletUser;
export const selectRapyydInput = (state: RootState): object =>
  state.invoicePay.rapyydInput;

export default slice.reducer;
