import React, { useEffect } from 'react';
import './ReceiverAppLoader.css';
import { STORAGEURL } from './utils';

export default function ReceiverAppLoader({
  disableScroll,
}: {
  disableScroll: boolean;
}) {
  useEffect(() => {
    if (disableScroll) {
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    }
  }, [disableScroll]);

  return (
    <>
      <div className="receiver-app-loader">
        <div className="container">
          <img
            src={`${STORAGEURL}payyd-logo-loader.svg`}
            height={30}
            width={30}
            alt="Payyd Logo"
          />
          <div className="loader-container">
            <div className="loader-bar"></div>
          </div>
        </div>
      </div>
    </>
  );
}
