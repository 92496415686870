import { ReactNode } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  compose,
  system,
  space,
  color,
  typography,
  flexbox,
  layout,
  position,
  SpaceProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  LayoutProps,
  PositionProps,
  fontFamily,
} from 'styled-system';

interface Props
  extends SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps,
    PositionProps,
    FlexboxProps {
  children: ReactNode;
  textTransform?: string;
  textDecoration?: string;
  css?: string;
  whiteSpace?: any;
  overflow?: any;
  textOverflow?: any;
}

export const FontStyle = createGlobalStyle`

@font-face {
  font-family: 'sans-serif';
}
`;

const Text = styled.p<Props>(
  compose(space, color, fontFamily, typography, layout, position, flexbox),
  system({
    textTransform: true,
    textDecoration: true,
    css: true,
    whiteSpace: true,
    overflow: true,
    textOverflow: true,
  }),
);

export default Text;
