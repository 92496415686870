import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Box as MBox,
  TextField as MTextField,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import { DatePicker, Typography, message } from 'antd';
import { handleGetSession } from 'common/auth0';
import Box from 'components/Box';
import Text from 'components/Text';
import {
  selectInvoice,
  selectWalletUser,
  setInvoice,
} from 'features/InvoicePay/slice';
import { ReactComponent as IdfyVarification } from '../../assets/svg/idfy-varification.svg';
import { ReactComponent as InfoLogo } from '../../assets/svg/info.svg';
// import USAStates from '../../common/USAStates.json';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
// import Loader from 'common/loader';
import { formatMoney, phoneCodeByISO3 } from 'common/utils';
import { rawHTML } from 'components/BottomNav';
import Logo from 'components/Logo';
import PrivacyPolicy from 'features/PrivacyPolicy';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import {
  AddLegalEntityInput,
  AddSenderUserInput,
  GET_COUNTRY_STATES,
  GetTransferTransactionByIdDocument,
  GetwalletCountriesIso,
  PayTransferTransactionInput,
  PaymentMethodInput,
  PaymentMethodSource,
  TransferTransaction,
  useAddWalletUserLegalEntityMutation,
  useAddWalletUserMutation,
  useGetInvoiceByIdQuery,
  useGetInvoiceDetailsByRefQuery,
  useGetIsMobileNumberExistsQuery,
  usePayTransferTransactionMutation,
  usePaymentMethodsByCountryIsoCode3Lazy,
  useVerifyTransferTransactionMutation,
  walletAddUserAddress,
} from 'generated/graphql';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { debounce } from 'lodash';
import MuiPhoneNumber from 'material-ui-phone-number';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { Fragment, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';
import { TPayerInformation } from 'types/globalTypes';
import avatars from '../../assets/avatars.png';
import { ReactComponent as BackIcon } from '../../assets/svg/back.svg';
import { ReactComponent as CheckedIcon } from '../../assets/svg/checked.svg';
import { ReactComponent as CheckoutBg } from '../../assets/svg/checkoutBg.svg';
import { ReactComponent as SecureLogo } from '../../assets/svg/secureLogo.svg';
import { EscrowModal } from './EscrowModal';

import DeclinePayment from './DeclinePaymnet';
import './checkout.css';
import BackButton from 'common/BackButton';
import ReceiverAppLoader from 'common/ReceiverAppLoader';
enum TabValues {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

function Checkout() {
  const dispatch = useDispatch();
  const currentSession = handleGetSession();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState<TabValues>(TabValues.INDIVIDUAL);
  const [selectedMethod, setSelectedMethod] = useState({
    paymentMethodSource: '',
    type: '',
    walletId: '',
  });

  // Plaid States
  const [plaidLinkToken, setPlaidLinkToken] = useState(null);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [auth, setAuth] = React.useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
  });

  const [captureLink, setCaptureLink] = useState(null);
  const [countryCodeForPayment, setCountryCodeForPayment] = useState('');
  const invoice: TransferTransaction = useSelector(selectInvoice);
  const walletUser = useSelector(selectWalletUser);
  const [phoneNumbers, setPhoneNumbers] = useState<string>('');
  const [idfyVerfied, setIdfyVarified] = useState(false);
  const [plaidAuth, setPlaidAuth] = useState(false);
  const [allowPlaid, setAllowPlaid] = useState(false);
  const { isMobile } = useMediaQuery();
  const emailFieldRef = React.useRef(null);

  const [phoneCode, setPhoneCode] = useState<string>('1');
  const [mobielExist, setMobielExist] = useState<boolean>(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showGdprModal, setShowGdprModal] = useState(false);
  const [mobielVarified, setMobielVarified] = useState<boolean>(false);
  const [scheduleDate, setScheduleDate] = useState<Moment>();
  const [addWalletUser] = useAddWalletUserMutation();
  const [addUserLegalEntity] = useAddWalletUserLegalEntityMutation();
  const [transferTransaction] = useVerifyTransferTransactionMutation();
  const userEmailAddress = localStorage.getItem('nerUserAddress');
  const [getStates, { data: states }] = useLazyQuery(GET_COUNTRY_STATES);
  const [openEscrow, setOpenEscrow] = useState<boolean>(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const internalRef = params.get('ref') || '';
  const { data: getInvoiceData } = useGetInvoiceDetailsByRefQuery({
    variables: {
      internalRef: internalRef,
    },
  });
  React.useEffect(() => {
    if (
      window.location.href.includes('ref') &&
      getInvoiceData?.reports_transferTransactionByRef
    ) {
      const invoiceData = getInvoiceData?.reports_transferTransactionByRef;
      localStorage.setItem('invoice', invoiceData?.id || '');

      window.location.href =
        window.location.origin + `/checkout?invoice=${invoiceData?.id}`;
    }
  }, [getInvoiceData?.reports_transferTransactionByRef]);

  const [addUserAddress] = useMutation(walletAddUserAddress);

  const invoiceId =
    params.get('invoice') || localStorage.getItem('invoice') || '';
  const { data: invoiceDat } = useGetInvoiceByIdQuery({
    variables: { id: invoiceId },
  });
  const isEsrowToday =
    invoiceDat?.reports_transferTransactionById?.escrowDate?.split('T')[0] ===
    invoiceDat?.reports_transferTransactionById?.createdOn?.split('T')[0];
  useUpdateEffect(() => {
    if (invoiceDat?.reports_transferTransactionById) {
      setScheduleDate(
        // @ts-ignore
        !invoiceDat?.reports_transferTransactionById.escrowDate || isEsrowToday
          ? null
          : moment(invoiceDat?.reports_transferTransactionById.escrowDate),
      );
      dispatch(setInvoice(invoiceDat?.reports_transferTransactionById));
    }
  }, [invoiceDat]);
  // @ts-ignore
  const invoiceDataLocal: TransferTransaction = invoiceDat;
  const PaymentPurpose = {
    NONE: 'None',
    'Digital Services Exports': 'Digital Services Exports',
    'Travel Agent Commission': 'Travel Agent Commission',
    'Goods Exports': 'Goods Exports',
    'Perishable Export': 'Perishable Export',
    'Pharmaceutical Export': 'Pharmaceutical Export',
    'Software Development': 'Software Development',
    'Airbnb Rental': 'Airbnb Rental',
    'Marketing Services': 'Marketing Services',
    'Professional Consultations': 'Professional Consultations',
    'Freelancer Services': 'Freelancer Services',
    OTHER: 'Other',
  };
  function getPaymentPurpose(key) {
    // @ts-ignore
    return PaymentPurpose[key];
  }
  const [payTransferTransaction] = usePayTransferTransactionMutation({
    refetchQueries: currentSession?.sub
      ? [
          {
            query: GetTransferTransactionByIdDocument,
            variables: {
              userId: invoice.sender?.userId,
              first: 40,
            },
          },
        ]
      : [],
  });

  const [
    getPaymentMethodByCountry,
    { data: paymentMethod },
  ] = usePaymentMethodsByCountryIsoCode3Lazy({
    // @ts-ignore
    variables: {
      // @ts-ignore
      countryIsoCode3: invoice.countryIsoCode3,
    },
  });

  const [
    varifyNumber,
    { data: varifyNumbers },
  ] = useGetIsMobileNumberExistsQuery();

  let timerId: any = null;
  React.useEffect(() => {
    if (emailFieldRef.current) {
      // @ts-ignore
      emailFieldRef.current.focus();
    }
  }, []);
  const handleVerify = debounce(() => {
    varifyNumber({
      variables: {
        phoneNumber:
          phoneNumbers?.replace(/\D/g, '') || invoice?.sender?.phoneNumber,
        phoneCountryCode: phoneCode || invoice?.sender?.phoneCountryCode,
      },
    });
  }, 1000);

  React.useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      handleVerify();
    }, 1000);
  }, [phoneNumbers]);
  const { open, ready } = usePlaidLink({
    token: plaidLinkToken,
    env: 'production',
    onSuccess: (publicToken, metadata) => {
      // send public_token to server
      if (metadata?.transfer_status === 'COMPLETE') {
        history.push(
          `/checkout/processing?merchantReferenceId=${invoice.internalRef}`,
        );
        // handleStatusUpdate(publicToken, metadata);
      }
    },
    onEvent: (eventName, metadata) => {
      if (eventName === 'EXIT') return message.error('Session end by user');
      if (metadata?.error_message || eventName)
        return message.error(metadata?.error_message);
    },

    onExit: (e) => {
      return console.log(e, 'error');
    },
  });
  React.useEffect(() => {
    if (ready && plaidLinkToken) {
      open();
    } else {
      console.error('plaidLinkToken is not available');
    }
  }, [ready, open, plaidLinkToken]);

  React.useEffect(() => {
    if (invoice.id) {
      if (invoice.transferTransactionStatus === 'LINK_DECLINED') {
        return history.push(
          `/checkout/declined?merchantReferenceId=${invoice.internalRef}`,
        );
      }
      // @ts-ignore
      setPhoneNumbers(invoice?.sender?.phoneNumber || '');
      if (invoice?.transferTransactionStatus !== 'LINK_SENT') {
        setStep(1);
      }
      const phoneCodeIso3 = phoneCodeByISO3(invoice.countryIsoCode3);
      setPhoneCode(phoneCodeIso3);
      setCountryCodeForPayment(invoice.countryIsoCode3);
      handleVerify();
      if (invoice?.sender?.legalEntity?.id) {
        setActiveTab(TabValues.COMPANY);
      } else {
        setActiveTab(TabValues.INDIVIDUAL);
      }
      if (invoice.currencyCode === 'USD') {
        getStates({
          variables: {
            countryIsoCode2: 'US',
          },
        });
      }
    }
    if (invoice?.rapyd) {
      // @ts-ignore
      setSelectedMethod(invoice.rapyd.paymentMethod);
      // @ts-ignore
    }
    if (invoice?.stripe) {
      setSelectedMethod({
        // @ts-ignore
        walletId: '',
        type: 'card',
        paymentMethodSource: 'STRIPE',
      });
    }
    if (invoice?.unlimint) {
      setSelectedMethod({
        // @ts-ignore
        walletId: invoice?.unlimint?.walletId,
        type: invoice?.paymentMethodSourceDescription || '',
        paymentMethodSource: 'UNLIMINT',
      });
    }
    if (invoice?.goCardless) {
      setSelectedMethod({
        // @ts-ignore
        walletId: '',
        type: invoice?.paymentMethodSourceDescription || '',
        paymentMethodSource: 'GO_CARDLESS',
      });
    }
  }, [invoice?.id]);

  React.useEffect(() => {
    // @ts-ignore
    if (varifyNumbers) {
      // @ts-ignore
      setMobielExist(varifyNumbers?.user_isMobileNumberExists);
      // @ts-ignore
      setMobielVarified(!varifyNumbers?.user_isMobileNumberExists);
    }
  }, [varifyNumbers]);

  React.useEffect(() => {
    if (walletUser.id && invoice) {
      // @ts-ignore
      setCountryCodeForPayment(invoice.countryIsoCode3);
      getPaymentMethodByCountry();
    }
  }, [walletUser, invoice]);

  const initialValues = {
    emailAddress:
      walletUser?.emailAddress || invoice?.sender?.emailAddress || '',
    firstName: '',
    middleName: invoice?.sender?.middleName || '',
    lastName: '',
    phoneNumber: '',
    phoneCountryCode: '',
    address: invoice?.sender?.primaryAddress?.streetAddress || '',
    taxNumber: '',
    city: invoice?.sender?.primaryAddress?.city || '',
    state:
      invoice.currencyCode === 'USD'
        ? invoice?.sender?.primaryAddress?.stateIsoCode2
        : invoice?.sender?.primaryAddress?.state,
    stateIsoCode2: invoice?.sender?.primaryAddress?.stateIsoCode2 || '',
    countryIsoCode3: invoice.countryIsoCode3 || '',
    zipCode: invoice?.sender?.primaryAddress?.zipCode || '',
    dateOfBirth: '',
    country: invoice.country?.name || '',
    businessName: invoice?.sender?.businessName || '',
  };

  const handleCreateWalletUserBusinessInfo = async (form, id) => {
    // create new Wallet
    setLoading(true);

    const createPayload: AddLegalEntityInput = {
      userId: id,
      legalEntity: {
        legalName: form.businessName,
        websiteUrl: form.websiteUrl,
      },
    };
    // imvoi "VHJhbnNmZXJUcmFuc2FjdGlvbgpsMzAw"
    // @ts-ignore

    return addUserLegalEntity({
      variables: { data: createPayload },
    });
  };
  const handleAddUserAddress = async (userAddress) => {
    return await addUserAddress({
      variables: {
        data: {
          address: {
            city: userAddress?.primaryAddress?.city,
            countryIsoCode3: userAddress?.primaryAddress?.countryIsoCode3,
            state: userAddress?.primaryAddress?.state,
            stateIsoCode2: userAddress?.primaryAddress?.stateIsoCode2,
            streetAddress: userAddress?.primaryAddress?.streetAddress,
            zipCode: userAddress?.primaryAddress?.zipCode,
          },
          userId: userAddress?.id,
        },
      },
    });
  };
  const handleUserSubmit = async (formValues) => {
    try {
      if (!invoice.sender?.userId) {
        setLoading(true);
        const {
          data: newWalletUserResponse,
          errors = [],
        } = await handleCreateWalletUser(formValues);
        message.destroy();
        setLoading(false);
        if (
          errors.length ||
          newWalletUserResponse?.wallet_addSenderUser?.errors?.length
        ) {
          message.error(
            errors[0]?.message ||
              // @ts-ignore
              newWalletUserResponse?.wallet_addSenderUser?.errors[0].code ===
                'DUP_ENTRY'
              ? 'Email or Phone number is already associated with other account'
              : errors[0]?.message,
          );
          return setLoading(false);
        } else {
          const newWalletUser =
            newWalletUserResponse?.wallet_addSenderUser?.data;
          const { data: addressData } = await handleAddUserAddress(
            newWalletUser,
          );
          setCaptureLink(
            addressData?.wallet_addUserAddress?.data?.user?.primaryKYC
              ?.captureLink,
          );
          if (activeTab === TabValues.COMPANY) {
            const walletUserBusinessInfo = await handleCreateWalletUserBusinessInfo(
              formValues,
              newWalletUser?.id,
            );
            message.destroy();
            if (!walletUserBusinessInfo.data?.wallet_addLegalEntity?.errors) {
              const newWalletUser =
                walletUserBusinessInfo.data?.wallet_addLegalEntity?.data;
              if (newWalletUser?.id) {
                return handleSubmitForm(
                  newWalletUserResponse?.wallet_addSenderUser?.data?.id,
                  true,
                );
                // await refetch();
                // await refetchWalletUser();
              }
            } else {
              message.error(
                walletUserBusinessInfo.data?.wallet_addLegalEntity?.errors[0]
                  .message,
              );
              setLoading(false);
            }
          }
          // await refetch();
          // await refetchWalletUser();
          handleSubmitForm(
            newWalletUserResponse?.wallet_addSenderUser?.data?.id,
            true,
          );
        }
      } else {
        return handleSubmitForm('', true);
      }
    } catch (error: any) {
      message.destroy();
      console.log(error);
      message.error(error.message);
      return setLoading(false);
    }
  };

  const handleSubmitForm = async (senderId = '', localPlaid) => {
    // @ts-ignore
    const invoiceData = invoiceDataLocal.reports_transferTransactionById;
    setPlaidAuth(false);
    try {
      if (
        (!invoiceData?.sender?.primaryKYC?.profileStatus ||
          invoiceData?.sender?.primaryKYC?.profileStatus ===
            'CAPTURE_PENDING') &&
        // @ts-ignore
        invoiceData.receivableAmount >= 2500 &&
        !invoiceData.sender?.legalEntity?.id &&
        invoiceData?.receiver?.countryIsoCode3 !== 'PAK' &&
        !idfyVerfied
      ) {
        return setIdfyVarified(true);
      }
      if (invoiceData?.stripe?.checkoutUrl) {
        window.location.href = invoiceData?.stripe?.checkoutUrl;
        return;
      }
      if (invoiceData?.checkOutUrl) {
        window.location.href = invoiceData?.checkOutUrl;
        return;
      }
      if (!invoiceData.internalRef) {
        message.info('Invalid Invoice!');
        return;
      }
      if (
        (!allowPlaid || localPlaid) &&
        // @ts-ignore
        paymentMethod?.reports_paymentMethodsByCountryIsoCode3[0]
          .paymentMethodSource === 'PLAID'
      ) {
        setPlaidAuth(false);
      }
      setLoading(true);

      if (invoiceData.transferTransactionStatus !== 'Link_Viewed') {
        const linkingInvoice = await handleLinkWalletUserToInvoice(
          invoiceData.internalRef,
          invoiceData.sender?.userId || senderId,
        );
        if (linkingInvoice?.errors?.length) {
          setLoading(false);
          return message.error(linkingInvoice?.errors[0].message);
        }
      }
      setLoading(false);
      const selectedPM =
        // @ts-ignore
        paymentMethod?.reports_paymentMethodsByCountryIsoCode3[0];
      const inputData: PayTransferTransactionInput = {
        senderUserId: invoiceData.sender?.userId || senderId,
        internalRef: invoiceData.internalRef,
        escrowDate: scheduleDate || new Date(),
        paymentMethod: {
          paymentMethodSource: selectedPM.paymentMethodSource,
          type: selectedPM.type,
          walletId: selectedPM.walletId,
        },
      };
      if (
        (invoice.countryIsoCode3 === 'NLD' ||
          invoice.countryIsoCode3 === 'DEU') &&
        invoiceData.totalAmount > 2000
      ) {
        const otherPM =
          // @ts-ignore
          paymentMethod?.reports_paymentMethodsByCountryIsoCode3[1];
        inputData.paymentMethod = {
          paymentMethodSource: otherPM.paymentMethodSource,
          type: otherPM.type,
          walletId: otherPM.walletId,
        };
      }
      if (selectedMethod.type === 'card') {
        const paymentMethodInput: PaymentMethodInput = {
          paymentMethodSource: PaymentMethodSource.STRIPE,
          type: 'card',
          walletId: '',
        };
        inputData.paymentMethod = paymentMethodInput;
      }
      setLoading(true);
      const { data: transferData }: any = await payTransferTransaction({
        variables: {
          data: inputData,
        },
      });
      if (!transferData.reports_payTransferTransaction?.errors) {
        if (
          transferData.reports_payTransferTransaction?.data?.plaid?.linkToken
        ) {
          setLoading(false);
          return setPlaidLinkToken(
            transferData.reports_payTransferTransaction?.data?.plaid?.linkToken,
          );
        }
        if (transferData.reports_payTransferTransaction?.data?.checkOutUrl) {
          window.location.href =
            transferData.reports_payTransferTransaction.data.checkOutUrl;
        }

        if (
          // @ts-ignore
          paymentMethod?.reports_paymentMethodsByCountryIsoCode3[0]
            ?.paymentMethodSource === 'PAYYD_BC'
        ) {
          history.push('/checkout/bankDetails', {
            data: {
              internalRef: invoiceData.internalRef,
              amount: invoiceData.totalAmount,
              currencyCode: invoiceData.currencyCode,
            },
          });
        }
      } else {
        message.error(
          transferData.reports_payTransferTransaction?.errors[0]?.message ||
            'Something went wrong',
        );
      }
      setLoading(false);
    } catch (error: any) {
      message.destroy();
      setLoading(false);
      message.error(error.message);
    }
  };

  const handleCreateWalletUser = async (formValues) => {
    // create new Wallet
    message.loading('Registering', 0);
    setLoading(true);

    const {
      firstName,
      lastName,
      taxNumber,
      address,
      streetAddress,
      city,
      state,
      emailAddress,
      stateIsoCode2,
      zipCode,
      // countryIsoCode3,
    } = formValues;

    const createPayload: AddSenderUserInput = {
      address: {
        streetAddress:
          invoice?.receiver?.countryIsoCode3 === 'PAK'
            ? '9th Street'
            : address || streetAddress,
        city,
        state: state || '',
        stateIsoCode2,
        countryIsoCode3: countryCodeForPayment,
        zipCode,
      },
      // @ts-ignore
      sub: currentSession?.sub,
      user: {
        firstName,
        lastName,
        emailAddress: userEmailAddress || emailAddress,
        countryIsoCode3: countryCodeForPayment,
        taxID: taxNumber,
        phoneNumber: phoneNumbers?.replace(/\D/g, ''),
        phoneCountryCode: phoneCode,
        userEntityType:
          activeTab === TabValues.COMPANY ? 'BUSINESS' : 'PERSONAL',
      },
      transferTransaction: {
        // @ts-ignore
        internalRef: invoice.internalRef,
        // @ts-ignore
        userType: 'SENDER',
      },
    };

    // @ts-ignore
    if (
      activeTab === TabValues.INDIVIDUAL &&
      invoice.receivableAmount <= 2500
    ) {
      delete createPayload.user.taxID;
    }
    const { data, errors } = await addWalletUser({
      variables: { data: createPayload },
    });
    return { data, errors };
  };

  const handleLinkWalletUserToInvoice = async (
    internalRef: string,
    senderUserId: string,
  ) => {
    message.loading('Linking Invoice...', 0);

    const { data } = await transferTransaction({
      variables: {
        data: {
          internalRef,
          senderUserId,
        },
      },
    });
    message.destroy();
    // @ts-ignore
    if (data.reports_verifyTransferTransaction?.errors?.length > 0) {
      // @ts-ignore
      return data.reports_verifyTransferTransaction;
    }
    message.info('Account and Invoice Linked!');
  };

  let IsoCode3 = {
    nodes: [
      {
        name: '',
        countryIsoCode3: '',
        phoneCountryCode: '',
      },
    ],
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const CountryIsoCode3 = GetwalletCountriesIso({
    // @ts-ignore
    variables: { first: 300 },
  });
  const { data } = CountryIsoCode3;
  if (data) {
    // @ts-ignore
    IsoCode3 = data?.wallet_sendCountries;
  }

  const handleChangePhoneNumber = (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
    country,
  ) => {
    const temp = value
      ?.toString()
      ?.slice(country.dialCode.toString()?.length + 1);
    setMobielExist(false);
    setPhoneCode(country.dialCode);
    setPhoneNumbers(temp);
  };
  function disabledDate(current) {
    return (
      current &&
      current.valueOf() <
        moment(
          isEsrowToday
            ? invoiceDat?.reports_transferTransactionById?.createdOn
            : invoiceDat?.reports_transferTransactionById?.escrowDate,
        )
          // @ts-ignore
          ?.get()
          .valueOf()
    );
  }

  const BlurryDialog = styled(Dialog)<DialogProps>(() => ({
    backdropFilter: 'blur(1px)',
    overflow: 'hidden',
    // other styles here...
  }));

  React.useEffect(() => {
    if (invoice.countryIsoCode3) {
      getPaymentMethodByCountry();
    }
  }, [invoice]);

  return (
    <Box style={{}}>
      {!invoice.id && <ReceiverAppLoader disableScroll={!invoice.id} />}
      <EscrowModal openEscrow={openEscrow} setOpenEscrow={setOpenEscrow} />
      <Box
        style={{
          minHeight: '92vh',
          maxWidth: '1800px',
          width: '100%',
          // display:"flex",
          display: 'flex',
          // mx:"auto",
          margin: 'auto',
          justifyContent: 'space-between',
        }}
        flexDirection={{ _: 'column', lg: 'row', md: 'row' }}
      >
        <Box
          style={{
            background:
              'linear-gradient(180deg, #20294C -36.62%, #0A1B16 100%)',
            position: 'absolute',
            top: 0,

            left: 0,
            zIndex: -1,
            // maxHeight: '900px',
            display: 'flex',
            justifyContent: 'flex-start',
            objectFit: 'cover',
          }}
          width={{ _: '100%', lg: '50%', md: '50%' }}
          height={{ _: '100%', lg: 'max-content', md: 'max-content' }}
        >
          <CheckoutBg height="120vh" width="200%" />
        </Box>
        <Box
          width={{ _: '100%', lg: '50%', md: '50%' }}
          minHeight={{ _: '680px', lg: 'max-content', md: 'max-content' }}
          mt={20}
          style={{ marginInline: 'auto', padding: isMobile ? 2 : 20 }}
        >
          <Logo invoice width={150} height={30} />
          <Box style={{ paddingInline: 30 }}>
            <Box mt={'5vh'} display="flex" alignItems="center">
              <SecureLogo />
              <Text fontSize={'20px'} color="#fff" ml={'1vh'} fontWeight={700}>
                100% secure pay
              </Text>
            </Box>
            <Box mt={'2.5vh'} display="flex" alignItems="center">
              <img src={avatars} />
              <Text
                fontSize={{ _: 12, md: 16, lg: 16, xl: 16 }}
                color="#C7C7C7"
                ml={'1vh'}
                fontWeight={400}
                maxWidth={{ lg: '400px' }}
              >
                Trusted by <span style={{ fontWeight: 700 }}>4000+</span> users:
                Exporters, Individual freelancers, creators, influencers and
                small businesses
              </Text>
            </Box>
            <Text
              color="#fff"
              // my="5px"
              fontSize={isMobile ? 24 : '48px'}
              fontWeight={500}
              my={'4vh'}
            >
              {invoice?.currencyCode || 'USD' + ' '}
              &nbsp;
              {invoice.totalAmount ? formatMoney(invoice.totalAmount) : '0.00'}
            </Text>
            <Box borderRadius="11px" p={4} background="rgba(255,255,255,0.1)">
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                mx="auto"
              >
                <Text color="#C7C7C7" fontSize={14}>
                  Service Provider:
                </Text>
                <Text fontSize={14} color="#fff">
                  {invoice.receiver?.businessName
                    ? invoice.receiver?.businessName
                    : `${invoice.receiver?.firstName || ''} ${
                        invoice.receiver?.lastName || ''
                      }`}
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                mt={2}
                width="100%"
                mx="auto"
              >
                <Text color="#C7C7C7" fontSize={14}>
                  Reference ID:
                </Text>
                <Text fontSize={14} color="#fff">
                  {invoice?.internalRef || ''}
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                mt={2}
                width="100%"
                mx="auto"
              >
                <Text color="#C7C7C7" fontSize={14}>
                  Reason:
                </Text>
                <Text fontSize={14} color="#fff">
                  {getPaymentPurpose(invoice?.paymentPurpose) === 'Other'
                    ? invoice?.paymentPurposeOther
                    : getPaymentPurpose(invoice?.paymentPurpose) || 'Other'}
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                mt={2}
                width="100%"
                mx="auto"
              >
                <Text color="#C7C7C7" fontSize={14}>
                  {scheduleDate ? 'Escrow' : 'Invoice'} Date:
                </Text>
                <Text fontSize={14} color="#fff">
                  {invoice?.escrowDate
                    ? invoice?.escrowDate?.split('T')[0]
                    : invoice?.createdOn?.split('T')[0]}
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                mt={2}
                width="100%"
                mx="auto"
              >
                <Text color="#C7C7C7" fontSize={14}>
                  Settlement Amount:
                </Text>
                <Text fontSize={14} color="#fff">
                  {' '}
                  {`${parseFloat(
                    invoice.receivableAmount
                      ? invoice.receivableAmount.toString()
                      : '0.00',
                  ).toFixed(2)} `}
                  USD
                </Text>
              </Box>
            </Box>
            <Box px={4} mt={30} width="100%" justifyContent="flex-end">
              <Box display="flex" justifyContent="space-between">
                <Text color="#C7C7C7" fontSize={14}>
                  Processing Fee:
                </Text>
                <Text color="#fff" fontSize={14} fontWeight={600}>
                  {`${
                    invoice.processingFee
                      ? formatMoney(invoice.processingFee)
                      : '0.00'
                  }`}{' '}
                  {invoice?.currencyCode}
                </Text>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Text color="#C7C7C7" fontSize={14}>
                  Tax:
                </Text>
                <Text color="#fff" fontSize={14} fontWeight={600}>
                  {' '}
                  {`${invoice.amount ? formatMoney(invoice.tax) : '0.00'} `}
                  {invoice?.currencyCode}
                </Text>
              </Box>
              <Box
                style={{
                  width: '100%',
                  background: 'rgba(255,255,255,0.2)',
                  marginTop: '10px',
                  height: '1px',
                }}
              />
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Text color="#C7C7C7" fontSize={14} fontWeight={600}>
                  Total:
                </Text>
                <Text color="#fff" fontSize={14} fontWeight={600}>
                  {`${
                    invoice.totalAmount
                      ? formatMoney(invoice.totalAmount)
                      : '0.00'
                  } `}
                  {invoice?.currencyCode}
                </Text>
              </Box>
            </Box>
            <BlurryDialog
              onClose={() => setShowTermsModal(false)}
              open={showTermsModal}
              style={{ overflow: 'hidden' }}
            >
              <PrivacyPolicy setShowTermsModal={setShowTermsModal} />
            </BlurryDialog>
            <BlurryDialog
              onClose={() => setShowGdprModal(false)}
              open={showGdprModal}
              style={{ overflow: 'hidden' }}
            >
              <Box maxWidth={1000} borderRadius="26px">
                <Stack spacing={2} direction="column" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Text ml={2} fontSize={18} fontWeight={600}>
                      GDPR Policy
                    </Text>
                  </Box>
                  <div dangerouslySetInnerHTML={{ __html: rawHTML }} />

                  <Text
                    lineHeight="20px"
                    fontSize={18}
                    fontWeight={600}
                    textAlign="left"
                    pb={2}
                  >
                    {new Date().getFullYear()} © Payyd. All Rights Reserved.
                  </Text>
                  <Button
                    color="info"
                    variant="contained"
                    size="large"
                    onClick={() => setShowGdprModal(false)}
                  >
                    Got it
                  </Button>
                </Stack>
              </Box>
            </BlurryDialog>
            <Box
              mt={{ _: 40, lg: '46px' }}
              display={{
                _: 'none',
                sm: 'flex',
                md: 'flex',
                lg: 'flex',
                xl: 'flex',
              }}
              justifyContent="space-between"
            >
              <Text
                onClick={() => setShowTermsModal(true)}
                style={{ cursor: 'pointer', textAlign: 'center' }}
                fontSize={14}
                fontWeight={500}
                color="#fff"
                textDecoration="underline"
              >
                Policy and Terms
              </Text>
              <Text
                onClick={() => setShowGdprModal(true)}
                style={{ cursor: 'pointer', textAlign: 'center' }}
                fontSize={14}
                fontWeight={500}
                color="#fff"
                textDecoration="underline"
              >
                GDPR
              </Text>
              <Text
                onClick={() => {}}
                style={{ cursor: 'pointer', textAlign: 'center' }}
                fontSize={14}
                fontWeight={500}
                color="#fff"
                textDecoration="underline"
              >
                Chat with us
              </Text>
              <Text
                style={{ textAlign: 'center' }}
                fontSize={14}
                fontWeight={500}
                color="#C7C7C7"
              >
                ©{new Date().getFullYear()} All rights reserved
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          width={{ _: '100%', lg: '50%', md: '50%' }}
          height={{
            _: '100%',
            sm: '120vh',
            xl: '120vh',
            lg: '120vh',
            md: '120vh',
          }}
          className="custom-scrollbar"
          style={{
            paddingInline: isMobile ? 16 : 50,
            marginInline: 'auto',

            overflowY: 'scroll',
            paddingTop: 30,
          }}
        >
          {step === 0 ? (
            <DeclinePayment invoice={invoice} setStep={setStep} />
          ) : (
            <>
              <Formik
                enableReinitialize
                validateOnChange={true} // Trigger validation on field change
                validateOnBlur={true}
                // @ts-ignore
                initialValues={initialValues}
                validate={(values) => {
                  const errors: Partial<TPayerInformation> = {};
                  // @ts-ignore
                  if (activeTab === TabValues.COMPANY) {
                    if (!values.businessName) {
                      errors.businessName = 'Required';
                    }
                  }
                  // @ts-ignore
                  if (
                    invoice?.receiver?.countryIsoCode3 !== 'PAK' &&
                    !values.address
                  ) {
                    errors.address = 'Required';
                  }
                  if (!values.firstName && activeTab === TabValues.INDIVIDUAL) {
                    errors.firstName = 'Required';
                  }
                  if (!values.lastName && activeTab === TabValues.INDIVIDUAL) {
                    errors.lastName = 'Required';
                  }
                  if (
                    invoice?.receiver?.countryIsoCode3 !== 'PAK' &&
                    !values.city
                  ) {
                    errors.city = 'Required';
                  }
                  if (
                    invoice?.receiver?.countryIsoCode3 !== 'PAK' &&
                    !values.state &&
                    invoice?.currencyCode === 'USD'
                  ) {
                    errors.state = 'Required';
                  }
                  if (
                    invoice?.currencyCode === 'USD' &&
                    !values.stateIsoCode2
                  ) {
                    errors.stateIsoCode2 = 'Required';
                  }
                  return errors;
                }}
                onSubmit={(values: TPayerInformation) => {
                  handleUserSubmit(values);
                }}
              >
                {({ values, setFieldValue, setValues, errors, submitForm }) => (
                  <>
                    {step === 1 ? (
                      <>
                        {invoice?.transferTransactionStatus === 'LINK_SENT' && (
                          <BackButton onClick={() => setStep(0)} />
                        )}
                        <Form>
                          <Fragment>
                            <Box
                              display="flex"
                              alignItems="center"
                              mt={'1vh'}
                              style={{ float: 'left' }}
                            >
                              <Text fontWeight={600} fontSize="16px">
                                Paying as a Business?
                              </Text>
                              <Switch
                                {...label}
                                checked={activeTab === TabValues.COMPANY}
                                onChange={(e) =>
                                  setActiveTab(
                                    e.target.checked
                                      ? TabValues.COMPANY
                                      : TabValues.INDIVIDUAL,
                                  )
                                }
                              />
                            </Box>
                            <Box
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box>
                                <Field
                                  component={TextField}
                                  type="text"
                                  size="small"
                                  inputRef={emailFieldRef}
                                  label="Email"
                                  name="emailAddress"
                                  sx={{ marginTop: 2, width: '100%' }}
                                  variant="outlined"
                                />
                              </Box>
                              {activeTab === TabValues.INDIVIDUAL ? (
                                <>
                                  <Field
                                    component={TextField}
                                    type="text"
                                    size="small"
                                    label="First Name"
                                    name="firstName"
                                    sx={{ marginTop: 2, width: '100%' }}
                                    variant="outlined"
                                  />
                                  <Field
                                    component={TextField}
                                    type="text"
                                    size="small"
                                    label="Last Name"
                                    onBlur={() =>
                                      !openEscrow && setOpenEscrow(true)
                                    }
                                    name="lastName"
                                    sx={{ marginTop: 2, width: '100%' }}
                                    variant="outlined"
                                  />
                                </>
                              ) : (
                                <>
                                  <Field
                                    component={TextField}
                                    type="text"
                                    label="Business Name"
                                    onClick={() =>
                                      !openEscrow && setOpenEscrow(true)
                                    }
                                    size="small"
                                    name="businessName"
                                    sx={{ marginTop: 2, width: '100%' }}
                                    variant="outlined"
                                  />
                                  {invoice?.receiver?.countryIsoCode3 !==
                                    'PAK' && (
                                    <>
                                      <Field
                                        component={TextField}
                                        size="small"
                                        type="text"
                                        label="Business Website (Optional)"
                                        sx={{ marginTop: 2, width: '100%' }}
                                        name="websiteUrl"
                                        variant="outlined"
                                      />
                                    </>
                                  )}
                                </>
                              )}
                              <Box
                                display="flex"
                                mt={4}
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <MuiPhoneNumber
                                  // component={MuiPhoneNumber}
                                  // defaultCountry={'us'}

                                  label="Mobile Number"
                                  value={'+' + phoneCode + phoneNumbers}
                                  sx={{ width: '48%' }}
                                  disableAreaCodes
                                  // @ts-ignore
                                  onChange={(value, country) => {
                                    if (country.dialCode === phoneCode) {
                                      handleChangePhoneNumber(value, country);
                                    } else {
                                      handleChangePhoneNumber('', country);
                                    }
                                  }}
                                  variant="outlined"
                                  size="small"
                                  InputProps={{
                                    endAdornment: mobielVarified &&
                                      phoneNumbers && (
                                        <InputAdornment position="end">
                                          <CheckedIcon />
                                        </InputAdornment>
                                      ),
                                  }}
                                />

                                <Autocomplete
                                  id="country-select-demo"
                                  // @ts-ignore
                                  options={IsoCode3?.nodes}
                                  sx={{ width: '48%' }}
                                  disabled
                                  value={{
                                    countryIsoCode3: '',
                                    name: values.country,
                                    phoneCountryCode: '+34',
                                  }}
                                  size="small"
                                  autoHighlight
                                  // @ts-ignore
                                  getOptionLabel={(option) => option.name || ''}
                                  renderOption={(props, option) => (
                                    <MBox
                                      component="li"
                                      sx={{
                                        '& > img': { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      <img
                                        loading="lazy"
                                        width="20"
                                        // @ts-ignore
                                        src={`https://flagcdn.com/w20/${option.countryIsoCode2?.toLowerCase()}.png`}
                                        // @ts-ignore
                                        srcSet={`https://flagcdn.com/w40/${option.countryIsoCode2?.toLowerCase()}.png 2x`}
                                        alt=""
                                      />
                                      <button
                                        style={{
                                          border: '0px',
                                          backgroundColor: 'transparent',
                                          width: '100%',
                                          textAlign: 'left',
                                        }}
                                        onClick={(e) => {
                                          const selectedCountry = e.currentTarget.innerText.slice(
                                            -3,
                                          );

                                          // @ts-ignore
                                          setCountryCodeForPayment(
                                            selectedCountry,
                                          );
                                          getPaymentMethodByCountry();
                                          setFieldValue(
                                            'country',
                                            e.currentTarget.innerText,
                                          );
                                        }}
                                        // @ts-ignore
                                      >
                                        {option?.name || ''}{' '}
                                        {option?.countryIsoCode3 || ''}
                                      </button>
                                    </MBox>
                                  )}
                                  renderInput={(params) => {
                                    return (
                                      <MTextField
                                        variant="outlined"
                                        {...params}
                                        disabled
                                        name="phoneNumber"
                                        label="Select Country"
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Box>
                              {mobielExist && !invoice.sender?.userId && (
                                <Typography style={{ color: '#FA527A' }}>
                                  This Mobile Number is already associated with
                                  other email.{' '}
                                </Typography>
                              )}
                              {invoice?.currencyCode === 'USD' ? (
                                <FormControl
                                  sx={{ width: '100%', marginTop: 2 }}
                                >
                                  <InputLabel htmlFor="stateIsoCode2">
                                    {activeTab === TabValues.INDIVIDUAL
                                      ? 'State'
                                      : 'Business State'}
                                  </InputLabel>
                                  <Select
                                    size="small"
                                    label="State"
                                    value={values.stateIsoCode2}
                                    onChange={(e): any => {
                                      setValues({
                                        ...values,
                                        stateIsoCode2: e.target.value,
                                        state: e.target.value,
                                      });
                                    }}
                                    inputProps={{
                                      name: 'stateIsoCode2',
                                      id: 'stateIsoCode2',
                                    }}
                                    style={{ width: '100%', marginTop: 2 }}
                                    variant="outlined"
                                  >
                                    {states?.wallet_states?.nodes?.map(
                                      (state) => {
                                        return (
                                          <MenuItem
                                            key={state.isoCode2}
                                            value={state.isoCode2}
                                          >
                                            {state.name}
                                          </MenuItem>
                                        );
                                      },
                                    )}
                                  </Select>
                                  {errors?.stateIsoCode2 && (
                                    <Text
                                      color="#d32f2f"
                                      mt={2}
                                      ml={3}
                                      fontSize={12}
                                    >
                                      Required
                                    </Text>
                                  )}
                                </FormControl>
                              ) : (
                                <>
                                  {invoice?.receiver?.countryIsoCode3 !==
                                    'PAK' && (
                                    <Field
                                      component={TextField}
                                      type="text"
                                      size="small"
                                      InputLabelProps={{ shrink: true }}
                                      label={
                                        activeTab === TabValues.INDIVIDUAL
                                          ? 'State'
                                          : 'Business State'
                                      }
                                      name="state"
                                      sx={{ width: '100%', marginTop: 2 }}
                                      variant="outlined"
                                    />
                                  )}
                                </>
                              )}
                              {invoice?.receiver?.countryIsoCode3 !== 'PAK' && (
                                <>
                                  <Field
                                    component={TextField}
                                    type="text"
                                    size="small"
                                    label={
                                      activeTab === TabValues.INDIVIDUAL
                                        ? 'City/Town'
                                        : 'Business City/Town'
                                    }
                                    name="city"
                                    sx={{ width: '100%', marginTop: 2 }}
                                    variant="outlined"
                                  />
                                  <Field
                                    component={TextField}
                                    size="small"
                                    type="text"
                                    label="Address"
                                    name="address"
                                    sx={{ marginTop: 2, width: '100%' }}
                                    variant="outlined"
                                  />
                                </>
                              )}
                            </Box>
                          </Fragment>
                        </Form>
                        <Box
                          background="#F5F5F5"
                          p={4}
                          mt={3}
                          borderRadius="8px"
                        >
                          <Box
                            background="#fff"
                            p={1}
                            width="100px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="40px"
                            border="1px solid #D5DBE1"
                          >
                            <Text p={0} m={0} color="#0060FF" fontWeight={700}>
                              Escrow Pay
                            </Text>
                          </Box>
                          <Box
                            mt={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text
                              fontWeight={500}
                              color="#697386"
                              fontSize="14px"
                            >
                              Want to release this payment to your supplier at a
                              future date?
                            </Text>
                            <Tooltip
                              placement="right"
                              title={
                                <React.Fragment>
                                  <Text
                                    color="#fff"
                                    fontSize={15}
                                    fontWeight={600}
                                  >
                                    Escrow Paymnents
                                  </Text>
                                  <Text color="#fff">
                                    Escrow is a feature where Payyd will hold
                                    your payment until the date you want us to
                                    while we confirm to the supplier that you
                                    have paid for the goods or services. This
                                    really helps in funds security.
                                  </Text>
                                </React.Fragment>
                              }
                            >
                              <IconButton>
                                <InfoLogo />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box
                            // mt={1}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Text fontWeight={600} mb={1} fontSize={16}>
                              Hold Payment Until
                            </Text>
                            <DatePicker
                              clearIcon={false}
                              placeholder="Select Date"
                              value={scheduleDate}
                              format="YYYY/MM/DD"
                              defaultValue={scheduleDate}
                              style={{ width: '190px', borderRadius: '10px' }}
                              onChange={(e) => {
                                setScheduleDate(moment(e));
                              }}
                              disabledDate={disabledDate}
                            />
                          </Box>
                        </Box>

                        <Box
                          width={{
                            _: '100%',
                            lg: '100%',
                            md: '100%',
                            xl: '100%',
                          }}
                        >
                          {/* <Text color="#737373" fontSize={20} fontWeight={600} mt={'20px'}>
                    {invoice.rapyd?.nextAction === 'not_applicable'
                      ? 'Selected Payment Method'
                      : 'Select Payment Method'}
                  </Text> */}

                          <Box>
                            <Box pt={1} mt={'3vh'} pb={2}>
                              <LoadingButton
                                sx={{
                                  width: '100%',
                                  marginBottom: '3vh',
                                  fontWeight: '600',
                                  padding: '12px 20px',
                                  borderRadius: '10px',
                                  color: '#FFFFFF',
                                  background: '#0060FF',
                                  boxShadow: 'none',
                                  '&:hover': {
                                    opacity: '0.9 !important',
                                    background: '#0060FF !important',
                                    boxShadow: 'none',
                                  },
                                }}
                                variant="contained"
                                color="primary"
                                size="large"
                                // @ts-ignore
                                onClick={() =>
                                  !phoneNumbers ||
                                  Object.keys(errors).length > 0
                                    ? message.error('All fields are required')
                                    : setStep(2)
                                }
                              >
                                Proceed
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <Box style={{}}>
                        <Box
                          onClick={() => setStep(1)}
                          display="flex"
                          mb={4}
                          cursor="pointer"
                          alignItems="center"
                        >
                          <BackIcon />
                          <Text fontWeight={700} fontSize={18} ml={15}>
                            Back
                          </Text>
                        </Box>
                        <Text color="#0060FF" fontSize="24px" fontWeight={700}>
                          Hey, hold on!
                        </Text>
                        <Text
                          color="#1A1F36"
                          fontSize="18px"
                          mt={3}
                          mb="30px"
                          fontWeight={600}
                        >
                          To protect you from scams let’s confirm a few things
                          before you pay!
                        </Text>
                        <FormGroup style={{ marginTop: '10px' }}>
                          <Box
                            style={{
                              display: 'flex',
                              marginBottom: '10px',
                              alignItems: 'flex-start',
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={auth.first}
                                  onChange={(e) =>
                                    setAuth({
                                      ...auth,
                                      first: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label=""
                            />
                            <Box pt={1} display="flex">
                              <Text
                                color="#697386"
                                fontSize="16px"
                                fontWeight={500}
                              >
                                1.
                              </Text>
                              <Text
                                ml={2}
                                color="#697386"
                                fontSize="16px"
                                fontWeight={500}
                              >
                                I know the service provider listed in the
                                request and I have independently verified them
                                to be legitimate
                              </Text>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              marginBottom: '10px',
                              alignItems: 'flex-start',
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={auth.second}
                                  onChange={(e) =>
                                    setAuth({
                                      ...auth,
                                      second: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label=""
                            />
                            <Box pt={1} display="flex">
                              <Text
                                color="#697386"
                                fontSize="16px"
                                fontWeight={500}
                              >
                                2.
                              </Text>
                              <Text
                                ml={2}
                                color="#697386"
                                fontSize="16px"
                                fontWeight={500}
                              >
                                I have a written agreement with the service
                                provider for the goods or services to be
                                rendered
                              </Text>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: 'flex',
                              marginBottom: '10px',
                              alignItems: 'flex-start',
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={auth.third}
                                  onChange={(e) =>
                                    setAuth({
                                      ...auth,
                                      third: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label=""
                            />
                            <Box pt={1} display="flex">
                              <Text
                                color="#697386"
                                fontSize="16px"
                                fontWeight={500}
                              >
                                3.
                              </Text>
                              <Text
                                ml={2}
                                color="#697386"
                                fontSize="16px"
                                fontWeight={500}
                              >
                                I am aware that any payment made in advance
                                carries an inherent risk of loss if goods or
                                services are not delivered and I am willing to
                                take the risk at my own discretion.
                              </Text>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: 'flex',
                              marginBottom: '10px',
                              alignItems: 'flex-start',
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={auth.fourth}
                                  onChange={(e) =>
                                    setAuth({
                                      ...auth,
                                      fourth: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label=""
                            />
                            <Box pt={1} display="flex">
                              <Text
                                color="#697386"
                                fontSize="16px"
                                fontWeight={500}
                              >
                                4.
                              </Text>
                              <Text
                                ml={2}
                                color="#697386"
                                fontSize="16px"
                                fontWeight={500}
                              >
                                For any disputes of non-delivery of goods or
                                services, discrepancy in payment or possible
                                refunds I will contact Payyd`s support for all
                                resolution.
                              </Text>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              marginBlock: '20px',
                              alignItems: 'flex-start',
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={auth.fifth}
                                  onChange={(e) =>
                                    setAuth({
                                      ...auth,
                                      fifth: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label=""
                            />
                            <Box>
                              <Text
                                color="#000"
                                fontSize="14px"
                                fontWeight={500}
                              >
                                I agree to the above terms and conditions are
                                legally binding and will be saved for future
                                records.
                              </Text>
                              <Text
                                mt={2}
                                color="#697386"
                                fontSize="14px"
                                fontWeight={500}
                              >
                                A copy of this statement & payment confirmation
                                will be sent to your provided email.
                              </Text>
                            </Box>
                          </Box>
                        </FormGroup>

                        <LoadingButton
                          loading={loading}
                          sx={{
                            width: '100%',
                            marginBottom: '3vh',
                            fontWeight: '600',
                            padding: '12px 20px',
                            borderRadius: '10px',
                            color: '#FFFFFF',
                            background: '#0060FF',
                            boxShadow: 'none',
                            '&:hover': {
                              opacity: '0.9 !important',
                              background: '#0060FF !important',
                              boxShadow: 'none',
                            },
                          }}
                          variant="contained"
                          color="primary"
                          disabled={
                            !Object.values(auth).every((value) => value)
                          }
                          size="large"
                          onClick={submitForm}
                        >
                          Authorize and Pay Now
                        </LoadingButton>
                      </Box>
                    )}
                  </>
                )}
              </Formik>

              {invoice?.currencyCode === 'USD' ? (
                <Typography
                  style={{
                    textAlign: 'center',
                    fontSize: 14,

                    color: '#A3ACBA',
                  }}
                >
                  We use{' '}
                  <a
                    target="blank"
                    href="https://plaid.com/legal/#end-user-services-agreement-us"
                  >
                    {' '}
                    3rd party partners{' '}
                  </a>{' '}
                  to complete your payment. You will be redirected to an
                  external link if applicable.
                </Typography>
              ) : (
                <Typography style={{ fontSize: 10, marginTop: '10px' }}>
                  We use 3rd party partners to complete your payment. You will
                  be redirected to an external link if applicable.
                </Typography>
              )}
              <Box
                mx="auto"
                width={200}
                my={10}
                alignItems="center"
                justifyContent="space-between"
                display="flex"
              >
                <img height="100%" width="22%" src="/securelogo2.jpg" />
                <img height="100%" width="22%" src="/securelogo4.png" />
                <img height="100%" width="22%" src="/securelogo3.jpg" />
                <img height="100%" width="22%" src="/securelogo1.png" />
              </Box>
            </>
          )}
        </Box>
      </Box>

      <BlurryDialog
        // onClose={() => setIdfyVarified(false)}
        open={idfyVerfied}
        style={{ overflowX: 'hidden' }}
      >
        <Box maxWidth={1200} padding={20} borderRadius="26px">
          <Stack spacing={2} direction="column" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box display="flex" alignItems="center" width="100%">
                <IdfyVarification />
                <Text fontSize={32} ml={2} color="#20294C" fontWeight={600}>
                  Identity Verfication
                </Text>
              </Box>
              <CloseIcon />
            </Box>
            <Text
              fontSize={16}
              color="#646464"
              fontWeight={700}
              style={{ marginBottom: '-16px' }}
            >
              We ask for identity documents when invoice value is over $2500.
              This quick step will help protect you and us against any
              fraudulent behaviour.
            </Text>
            <Box>
              <Text fontSize={16} mt={2} color="#646464">
                I certify and understand that the ID provided for this
                verification process belongs to me and is issued by my local or
                national government. Providing false and fake documents will
                result in blocking and reporting.
              </Text>
              <Box style={{ float: 'right' }} pt={2} pb={4}>
                <LoadingButton
                  loading={loading}
                  style={{ marginTop: 10 }}
                  variant="contained"
                  color="primary"
                  size="large"
                  // @ts-ignore
                  onClick={() => {
                    const invoiceData =
                      // @ts-ignore
                      invoiceDataLocal.reports_transferTransactionById;
                    window.location.href =
                      (captureLink ||
                        // @ts-ignore
                        invoiceData?.sender?.primaryKYC?.captureLink) +
                        '&redirect_uri=' +
                        window.location.href.replace(
                          'checkout',
                          'checkout/verification',
                        ) || '';
                  }}
                >
                  Verify Identity
                </LoadingButton>
              </Box>
            </Box>
          </Stack>
        </Box>
      </BlurryDialog>

      <BlurryDialog
        // onClose={() => setIdfyVarified(false)}
        open={plaidAuth}
        PaperProps={{
          style: {
            overflowX: 'hidden',
          },
        }}
      >
        <Box maxWidth={1000} padding={20} borderRadius="26px">
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setPlaidAuth(false)}
            aria-label="close"
            sx={{
              position: 'absolute',
              top: '0px',
              right: '8px',
            }}
          >
            <CloseCircleOutlined />
          </IconButton>
          <Stack
            marginTop={1}
            spacing={2}
            direction="column"
            alignItems="center"
          >
            <Text fontSize={20} fontWeight={600}>
              Payyd Tech Works LLC uses ACH to collect this payment. We will
              debit the funds from your bank account directly using US Bank ACH
              service.
            </Text>
            <Text fontSize={14} style={{}}>
              I{' '}
              <span style={{ fontWeight: 'bold' }}>
                {`${
                  invoice.sender?.firstName
                    ? invoice.sender?.firstName + ' ' + invoice.sender?.lastName
                    : invoice.sender?.businessName
                }`}{' '}
              </span>
              account holder or authorized representative hereby provide my
              electronic authorization today the
              <span
                style={{ fontWeight: 'bold', marginLeft: '4px' }}
              >{`${new Date()}`}</span>
              , authorizing Payyd Tech Works LLC to create a Single ACH debit
              from my bank account in the amount of
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                ${`${invoice.amount}`}
              </span>
              . I confirm that any goods or services being rendered to me have
              been received and I am fully satisfied with the goods and
              services. I do not and will not hold Payyd Tech Works LLC liable
              for any non - deliverance of goods or services.{' '}
            </Text>
            <Text fontSize={14} style={{ marginBottom: '-16px' }}>
              This debit will post in your account within the next 1 business
              day. Please write to us at support@payyd.money for any further
              questions OR within 2 business days of the above mentioned date
              for a refund. No refunds can be processed after the aforementioned
              2 business days.
            </Text>
            <Box>
              <Box pt={2}>
                <LoadingButton
                  style={{ marginTop: 10 }}
                  variant="contained"
                  color="primary"
                  size="large"
                  // @ts-ignore
                  onClick={() => {
                    setAllowPlaid(true);
                    handleSubmitForm('', false);
                  }}
                >
                  I Authorize
                </LoadingButton>
              </Box>
            </Box>
            <Text
              fontSize={10}
              style={{
                // marginBottom: '-16px',
                marginTop: '3px',
              }}
            >
              (We will email you a copy of this authorization)
            </Text>
          </Stack>
        </Box>
      </BlurryDialog>
    </Box>
  );
}

export default Checkout;
