import animationData from 'assets/lottie/IdenityVerificationProcessing.json';
import Box from 'components/Box';
import Text from 'components/Text';
import { useEffect } from 'react';
import Lottie from 'react-lottie';

const PaymentReceipt = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = window.location.href.replace(
        'checkout/verification',
        'checkout',
      );
    }, 45000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box width="100%" mt={100} display="flex">
      <Box maxWidth={700} width="100%" m="0 auto">
        <Box
          style={{ alignItems: 'flex-start' }}
          ml={-50}
          width={200}
          height={100}
        >
          <Lottie options={defaultOptions} height={100} width={200} />
        </Box>
        <Text fontSize={35} mt={6} mb={4} color="#20294C" fontWeight={600}>
          Processing Identity Verification
        </Text>
        <Text fontSize={16} fontWeight={600} color="#646464">
          Our partner is verifying the information you provided.This page will
          redirect in between 20 to 40 seconds. Please wait and don`t click the
          back button.
        </Text>
      </Box>
    </Box>
  );
};

export default PaymentReceipt;
