import { Button, Stack } from '@mui/material';
import Switch from '@mui/material/Switch';
import { message } from 'antd';
import { handleGetSession } from 'common/auth0';
import Box from 'components/Box';
import PageLayout from 'components/PageLayout';
import Text from 'components/Text';
import { selectWalletUser, setWalletUser } from 'features/InvoicePay/slice';
import {
  UpdateUserNotificationInput,
  useGetWalletUserBySubQuery,
  User,
  useUpdateUserNotifications,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

function Preferences() {
  const [emailNotification, setEmailNotification] = useState(false);
  const [mobileNotification, setMobileNotification] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSession = handleGetSession();

  const [UpdateUserNotification] = useUpdateUserNotifications();
  const walletUser = useSelector(selectWalletUser);
  const { data: walletUserData } = useGetWalletUserBySubQuery({
    // @ts-ignore
    variables: { sub: currentSession!.sub },
  });

  useEffect(() => {
    if (walletUserData?.wallet_userBySub) {
      const data = walletUserData?.wallet_userBySub as User;
      setEmailNotification(data.emailNotification);
      setMobileNotification(data.smsNotification);
      dispatch(setWalletUser(data));
    }
  }, [walletUserData]);
  const handleSubmit = async () => {
    const createPayload: UpdateUserNotificationInput = {
      userId: walletUser.id,
      smsNotification: mobileNotification,
      emailNotification: emailNotification,
    };
    const { data } = await UpdateUserNotification({
      variables: { data: createPayload },
    });
    // @ts-ignore
    if (data.wallet_updateUserNotification.data.id) {
      message.info('Your Preferences has been Saved.');
      history.go(0);
    } else {
      message.error('Something went Wrong, Try again.');
    }
  };
  return (
    <PageLayout>
      <Box width="100%" p="20px" display="flex">
        <Box textAlign="center" maxWidth={550} width="100%" m="0 auto">
          <Text fontSize={27} mt={6} mb={4} fontWeight={500} color="#000">
            Preferences
          </Text>
          <Text
            mb={8}
            fontSize={18}
            fontWeight={500}
            textAlign="left"
            color="#000"
          >
            Where You Recieve Notifications
          </Text>

          <Box mt={4}></Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box>
              <Text
                color="#000"
                mb={1}
                fontSize={16}
                fontWeight={500}
                textAlign="left"
              >
                Email notifications
              </Text>
              <Text
                mb={8}
                fontSize={14}
                color="#6C7694"
                fontWeight={400}
                textAlign="left"
              >
                Send notification to your email.
              </Text>
            </Box>
            <Switch
              checked={emailNotification}
              onChange={(e) => setEmailNotification(e.target.checked)}
            />
          </Box>
          <hr
            style={{
              color: '#B6C9DB',
              backgroundColor: '#B6C9DB',
              height: 0,
            }}
          />
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box>
              <Text
                mb={1}
                color="#000"
                fontSize={16}
                fontWeight={500}
                textAlign="left"
              >
                Mobile notifications
              </Text>
              <Text
                mb={8}
                fontSize={14}
                color="#6C7694"
                fontWeight={400}
                textAlign="left"
              >
                Send notification to SMS.
              </Text>
            </Box>
            <Switch
              checked={mobileNotification}
              onChange={(e) => setMobileNotification(e.target.checked)}
            />
          </Box>
          <hr
            style={{
              color: '#B6C9DB',
              backgroundColor: '#B6C9DB',
              height: 0,
            }}
          />
          <Stack spacing={2} direction="column" width={240} margin="20px auto">
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                handleSubmit();
              }}
            >
              Save Changes
            </Button>
          </Stack>
        </Box>
      </Box>
    </PageLayout>
  );
}

export default Preferences;
