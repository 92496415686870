import Box from 'components/Box';
import Logo from 'components/Logo';
import PageLayout from 'components/PageLayout';
import SuccessBg from '../../assets/successBg.png';

import PaymentReceipt from 'features/InvoicePaySuccess/PaymentReceipt';

function InvoicePaySuccess() {
  return (
    <PageLayout>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Logo invoice width={150} height={30} />
        <Box
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <img src={SuccessBg} alt="Invoice" />
        </Box>
      </Box>
      <Box
        maxWidth={{ _: 1000, lg: 2280 }}
        width="100%"
        display="flex"
        justifyContent="space-between"
        mx="auto"
        flexDirection={{ _: 'column-reverse', lg: 'row' }}
      >
        <PaymentReceipt />
      </Box>
    </PageLayout>
  );
}

export default InvoicePaySuccess;
