import { Grid, Skeleton } from '@mui/material';
import { handleGetSession, handleLogout } from 'common/auth0';
import Box from 'components/Box';
import PageLayout from 'components/PageLayout';
import CurrentBalance from 'features/CurrentBalance';
import { setWalletUser } from 'features/InvoicePay/slice';
import PaymentRequests from 'features/PaymentRequests';
import Transactions from 'features/Transactions';
import { useGetWalletUserBySubQuery, User } from 'generated/graphql';
import { useMediaQuery } from 'hooks/useMediaQuery';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

function DashboardPage(props) {
  const { isMedium } = useMediaQuery();
  const currentSession = handleGetSession();
  const [userData, setUserData] = useState<User>();
  const dispatch = useDispatch();
  const { data: walletUserData } = useGetWalletUserBySubQuery({
    // @ts-ignore
    variables: { sub: currentSession?.sub },
  });

  React.useEffect(() => {
    if (walletUserData?.wallet_userBySub === null) {
      handleLogout();
    } else {
      const data = walletUserData?.wallet_userBySub as User;
      setUserData(data);
      dispatch(setWalletUser(data));
    }
  }, [walletUserData]);

  const LoadingState = () => {
    return (
      <Box width="80%">
        <Skeleton animation="wave" />
        <Skeleton animation="wave" width={200} />
        <Skeleton
          variant="rectangular"
          height={118}
          style={{ margin: '10px 0' }}
        />
        <Skeleton animation="wave" width={150} />
      </Box>
    );
  };
  return (
    <PageLayout>
      {!isMedium && (
        <Box display="flex" justifyContent="center">
          {walletUserData?.wallet_userBySub && (
            <CurrentBalance
              // @ts-ignore
              userData={userData}
            />
          )}
        </Box>
      )}
      <Box mt={{ _: 4, md: 0 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            {walletUserData?.wallet_userBySub ? (
              <PaymentRequests walletUserData={walletUserData} props={props} />
            ) : (
              <LoadingState />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {walletUserData?.wallet_userBySub ? (
              <Transactions walletUserData={walletUserData} />
            ) : (
              <LoadingState />
            )}
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
}

export default DashboardPage;
