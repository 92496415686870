import { Button, Stack } from '@mui/material';
import { message } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import {
  usePayTransferTransactionMutation,
  useVerifySmsMutation,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/svg/back.svg';

function Varification() {
  const [otp, setOtp] = useState('');
  const [rapydID, setRapydID] = useState('');

  // const walletUser = useSelector(selectRapyydInput);
  const [varifySmsMutation, varifySmsMutationRespose] = useVerifySmsMutation();
  const [
    payTransferTransaction,
    payTransferTransactionResult,
  ] = usePayTransferTransactionMutation();
  const [counter, setCounter] = useState(60);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const history = useHistory();

  // @ts-ignore
  const data = localStorage.getItem('rapyydProp') || '';
  const rapydData = JSON.parse(data);

  const handleOtp = async () => {
    const varifySms = await varifySmsMutation({
      variables: {
        phoneNumber: parseInt(
          rapydData.phoneNumber.slice(1, rapydData.phoneNumber?.length),
        ),
        phoneCountryCode: parseInt(rapydData.phoneCountryCode),
        // @ts-ignore
        payydEnvironment: process.env.REACT_APP_MODE,
      },
    });
    // @ts-ignore
    setRapydID(varifySms.data.messagebird_createSmsVerify.id);
  };

  // otpid "c6d21294049b41a2876c79ff5084f104"
  useEffect(() => {
    if (rapydData.phoneNumber) {
      handleOtp();
    }
  }, [rapydData.phoneNumber]);

  const handlePayTranfer = async () => {
    const { data } = await payTransferTransaction({
      variables: {
        data: {
          senderUserId: rapydData.senderUserId,
          internalRef: rapydData.internalRef,
          paymentMethod: {
            type: rapydData.rapydPaymentMethod,
            paymentMethodSource: rapydData.serviceProvider,
            walletId: rapydData.walletId,
          },
          otp: {
            id:
              process.env.REACT_APP_MODE === 'MOCK'
                ? 'c6d21294049b41a2876c79ff5084f104'
                : rapydID,
            // @ts-ignore
            payydEnvironment: process.env.REACT_APP_MODE,
            token: otp,
          },
        },
      },
    });
    // @ts-ignore
    if (data?.reports_payTransferTransaction?.errors?.length > 0) {
      // @ts-ignore
      if (data.reports_payTransferTransaction.errors[0].code === '10') {
        return message.error(
          'You have entered an invalid OTP. Please try again.',
        );
      } else {
        return message.error(
          // @ts-ignore
          data.reports_payTransferTransaction.errors[0].message,
        );
      }
    }
    // @ts-ignore
    if (
      data?.reports_payTransferTransaction?.data?.checkOutUrl ||
      data?.reports_payTransferTransaction?.data?.stripe ||
      data?.reports_payTransferTransaction?.data?.rapyd
    ) {
      localStorage.removeItem('rapyydProp');
      // @ts-ignore
      window.location.href =
        data?.reports_payTransferTransaction?.data?.checkOutUrl ||
        data?.reports_payTransferTransaction?.data?.stripe?.checkoutUrl ||
        data?.reports_payTransferTransaction?.data?.rapyd?.checkoutRedirectUrl;
    }
  };
  return (
    <Box display="block" maxWidth={1500} width="90%" mx="auto">
      <Box
        onClick={() =>
          history.push(`/checkout?invoice=${rapydData?.invoiceId}&fetch=true`)
        }
        display="flex"
        cursor="pointer"
        alignItems="center"
      >
        <BackIcon />
        <Text fontWeight={500} ml={15}>
          Back
        </Text>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        mx="auto"
        flexDirection={{ _: 'column-reverse', lg: 'row' }}
      >
        <Box width="100%" p="20px" display="flex">
          <Box textAlign="center" maxWidth={380} width="100%" m="0 auto">
            {/* <Box width={120} height={120} m="0 auto">
          <Lottie options={defaultOptions} height={120} width={120} />
        </Box> */}
            <Text fontSize={27} mt={6} mb={4} fontWeight={500}>
              Verification code
            </Text>
            <Stack direction="column" mb="40px" width="100%" spacing="10px">
              <Box justifyContent="space-between" display="block">
                <Text fontSize={15} fontWeight={500}>
                  We’ve sent you an SMS with a code to
                </Text>
                <Text fontSize={15} fontWeight={500}>
                  your number ending with *{rapydData.phoneNumber.slice(-4)}
                </Text>
                <OtpInput
                  inputStyle={{
                    width: '100%',
                    height: '50px',
                    borderRadius: '5px',
                    border: '1px solid #e6e6e6',
                    padding: '0 10px',
                  }}
                  value={otp}
                  containerStyle={{
                    width: '100%',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                  onChange={(e) => setOtp(e)}
                  numInputs={6}
                  separator={<span>&nbsp; &nbsp;</span>}
                />
                <Text fontSize={15} fontWeight={500} color="#6C7694">
                  Enter 6-digit code
                </Text>
              </Box>
            </Stack>
            <Stack
              spacing={2}
              direction="column"
              width={170}
              margin="-20px auto 20px auto"
            >
              <Button
                disabled={
                  otp?.length !== 6 ||
                  varifySmsMutationRespose.loading ||
                  payTransferTransactionResult.loading
                }
                variant="contained"
                size="large"
                onClick={handlePayTranfer}
              >
                {varifySmsMutationRespose.loading ? 'Please wait..' : 'Verify'}
              </Button>
            </Stack>
            <Stack direction="column" mb="40px" width="100%" spacing="10px">
              <Box justifyContent="space-between" display="block">
                <Box justifyContent="center" display="flex">
                  <Text fontSize={15} fontWeight={500} color="#6C7694">
                    Didn’t received the code? Resets in &nbsp;
                  </Text>
                  <Text fontSize={15} fontWeight={500} color="primary">
                    {' '}
                    {counter}
                  </Text>
                </Box>
                {counter === 0 && (
                  <Text
                    onClick={() => {
                      setOtp('');
                      setCounter(60);
                      handleOtp();
                    }}
                    fontSize={15}
                    style={{ cursor: 'pointer' }}
                    fontWeight={700}
                    color="primary"
                  >
                    Resend code
                  </Text>
                )}
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Varification;
