import Box from 'components/Box';
import Logo from 'components/Logo';
import CurrentBalance from 'features/CurrentBalance';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useHistory } from 'react-router-dom';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

function TopNav() {
  const { isLarge, isMedium } = useMediaQuery();
  const history = useHistory();

  return (
    <Box
      height={{ _: 64, md: 100 }}
      maxWidth={3500}
      margin="auto"
      borderBottom="1px solid"
      borderColor="gray.1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={{ _: 20, md: 40, lg: 100 }}
    >
      <Box
        maxWidth={1500}
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => history.push('/dashboard')}
          >
            <Logo invoice width={140} />
          </Box>
          {isMedium && <CurrentBalance />}
        </Box>
        {isLarge ? <DesktopNav /> : <MobileNav />}
      </Box>
    </Box>
  );
}

export default TopNav;
