import { Button, Divider, Stack } from '@mui/material';
import { formatMoney } from 'common/utils';
import Box from 'components/Box';
import PageLayout from 'components/PageLayout';
import Text from 'components/Text';
import { TransferTransaction } from 'generated/graphql';
import { createRef } from 'react';
import { useHistory } from 'react-router-dom';

const ref = createRef();

/* eslint-disable new-cap */
// const doc = new jsPDF();

// const takeScreenShot = async (node) => {
//   const dataURI = await htmlToImage.toJpeg(node);
//   return dataURI;
// };

// const download = (image) => {
//   doc.setFillColor('245');
//   doc.addImage(image, 'JPEG', 4, 0, 200, 200);
//   doc.save('Transaction.pdf');
// };

// const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

const TransactionHisotry = () => {
  const history = useHistory();
  // @ts-ignore
  const data = history?.location?.state?.data as TransferTransaction;

  return (
    <PageLayout>
      <Box>
        <div
          // @ts-ignore
          ref={ref}
          style={{ backgroundColor: '#fff', maxWidth: '680' }}
        >
          <Box
            maxWidth={{ _: 800 }}
            width="100%"
            display="flex"
            justifyContent="space-between"
            mx="auto"
            flexDirection={{ _: 'column-reverse', lg: 'row' }}
          >
            <Box width="100%" p="20px" display="flex">
              <Box textAlign="center" maxWidth={650} width="100%" m="0 auto">
                <Text fontSize={27} mt={6} mb={4} fontWeight={500}>
                  Transaction Details
                </Text>
                <Stack direction="column" mb="40px" width="100%" spacing="10px">
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={15} fontWeight={500}>
                      Status
                    </Text>
                    <Text
                      fontSize={15}
                      color={
                        data?.transferTransactionStatusDescription ===
                        'Funds_Collected'
                          ? '#6BBE2C'
                          : data?.rapyd
                          ? '#3D4251'
                          : data?.transferTransactionStatusDescription ===
                            'Processing'
                          ? '#3D4251'
                          : data?.transferTransactionStatusDescription ===
                            'Error'
                          ? '#FA527A'
                          : '#FA527A'
                      }
                      textAlign="right"
                    >
                      {data?.transferTransactionStatusDescription ===
                      'Funds_Collected'
                        ? 'Completed'
                        : data?.rapyd
                        ? 'Processing'
                        : data?.transferTransactionStatusDescription ===
                          'Payment Initiated'
                        ? 'Processing'
                        : data?.transferTransactionStatusDescription}
                    </Text>
                  </Box>
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={15} fontWeight={500}>
                      Payment date
                    </Text>
                    <Text fontSize={15} textAlign="right">
                      {data?.modifiedOn
                        ? data?.modifiedOn?.split('T')[0]
                        : data?.createdOn?.split('T')[0]}
                    </Text>
                  </Box>
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={15} fontWeight={500}>
                      Reference number
                    </Text>
                    <Text fontSize={15} textAlign="right">
                      {data?.internalRef}
                    </Text>
                  </Box>
                  <Divider />
                  <Text fontSize={15} fontWeight={500} textAlign="left">
                    Service Provider
                  </Text>
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={12} color="#6C7694">
                      Name
                    </Text>
                    <Text fontSize={12} textAlign="right" fontWeight={500}>
                      {`${data?.receiver?.firstName} ${data?.receiver?.lastName}`}
                    </Text>
                  </Box>
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={12} color="#6C7694">
                      Email
                    </Text>
                    <Text fontSize={12} textAlign="right" fontWeight={500}>
                      {data?.receiver?.emailAddress}
                    </Text>
                  </Box>
                  <Divider />
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={12} color="#6C7694">
                      Total
                    </Text>
                    <Text fontSize={12} textAlign="right" fontWeight={500}>
                      {`${
                        // @ts-ignore
                        data?.amount ? formatMoney(data?.amount) : '0.00'
                      } `}
                      {
                        // @ts-ignore
                        data?.currencyCode
                      }
                    </Text>
                  </Box>
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={12} color="#6C7694">
                      Processing fee
                    </Text>
                    <Text fontSize={12} textAlign="right" fontWeight={500}>
                      {`${
                        // @ts-ignore
                        data?.totalAmount
                          ? // @ts-ignore
                            formatMoney(data?.processingFee)
                          : '0.00'
                      } `}
                      {
                        // @ts-ignore
                        data?.currencyCode
                      }
                    </Text>
                  </Box>
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={12} color="#6C7694">
                      Taxes
                    </Text>
                    <Text fontSize={12} textAlign="right" fontWeight={500}>
                      {`${
                        data?.totalAmount
                          ? // @ts-ignore
                            formatMoney(data?.tax)
                          : '0.00'
                      } `}
                      {data?.currencyCode}
                    </Text>
                  </Box>
                  <Box justifyContent="space-between" display="flex">
                    <Text fontSize={18} fontWeight={500} color="#3D4251">
                      Amount Paid
                    </Text>
                    <Text fontSize={18} textAlign="right" fontWeight={500}>
                      {`${
                        // @ts-ignore
                        data?.totalAmount
                          ? // @ts-ignore
                            formatMoney(data?.totalAmount)
                          : '0.00'
                      } `}
                      {
                        // @ts-ignore
                        data?.currencyCode
                      }
                    </Text>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </div>
        <Box marginTop={-30}>
          <Stack spacing={2} direction="column" width={240} margin="20px auto">
            {/* <Button
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={() => {
                downloadScreenshot();
              }}
              size="large"
            >
              Download PDF
            </Button> */}
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              Back to dashboard
            </Button>
          </Stack>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default TransactionHisotry;
