import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import LoginPage from 'pages/LoginPage';
import PayPage from 'pages/PayPage';
import AuthPage from 'pages/AuthPage';
import MainPage from 'pages/MainPage';
import { getToken } from 'common/utils';
import React from 'react';
import Checkout from 'features/InvoicePay/Checkout';
import BankDetails from 'features/BankDetails';
import InvoicePaySuccess from 'features/InvoicePaySuccess';
import CheckoutProcessing from 'features/CheckoutProcessing';
import CheckoutFailed from 'features/CheckoutFailed';
import IDFYProcessing from 'features/IDFYProcessing';
import CheckoutDeclined from 'features/CheckoutDeclined';

interface IPrivateRoute {
  component: React.FC<any>;
  [x: string]: any;
}

const PrivateRoute = ({ component: Component, ...rest }: IPrivateRoute) => {
  const token = getToken();
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (token) return <Component {...props} />;
        return <Redirect to="/login" />;
      }}
    />
  );
};

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/pay">
          <PayPage />
        </Route>
        <Route exact path="/checkout">
          <Checkout />
        </Route>
        <Route exact path="/checkout/bankDetails">
          <BankDetails />
        </Route>
        <Route path="/checkout/success">
          <InvoicePaySuccess />
        </Route>
        <Route exact path="/checkout/verification">
          <IDFYProcessing />
        </Route>
        <Route path="/checkout/processing">
          <CheckoutProcessing />
        </Route>
        <Route path="/checkout/failed">
          <CheckoutFailed />
        </Route>
        <Route path="/checkout/declined">
          <CheckoutDeclined />
        </Route>
        <Route exact path="/auth">
          <AuthPage />
        </Route>
        <PrivateRoute path="/" component={MainPage} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
