import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  me: {},
  role: ''
};

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
  extraReducers: () => {
  },
});

export const { setRole } = slice.actions;

export const selectUserMe = (state) => state.users.me;

export default slice.reducer;
