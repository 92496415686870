import React from 'react';
import Box from 'components/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface BackButtonOptions {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
export default function BackButton(options: BackButtonOptions) {
  return (
    <Box
      onClick={options.onClick}
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        display: 'flex',
        marginBottom: '10px',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #EBEBEB',
      }}
    >
      <ChevronLeftIcon color="action" fontSize="medium" />
    </Box>
  );
}
