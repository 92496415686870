import BottomNavInner from 'components/BottomNavInner';
import ScrollToTop from 'components/ScrollToTop';
import TopNav from 'components/TopNav';
import InvoicePayError from 'features/InvoicePayError';
import InvoicePayFail from 'features/InvoicePayFail';
import InvoicePayProcessing from 'features/InvoicePayProcessing';
import InvoicePayProcessingPayment from 'features/InvoicePayProcessingPayment';
import InvoicePaySuccess from 'features/InvoicePaySuccess';
import TransactionHisotry from 'features/Transactions/TransactionHistory';
import DashboardPage from 'pages/DashboardPage';
import EditProfile from 'pages/EditProfile';
import FAQ from 'pages/FAQ';
import InvoicePage from 'pages/InvoicePage';
import Preferences from 'pages/Preferences';
import Support from 'pages/Support';
import { Redirect, Route, Switch } from 'react-router-dom';

function MainPage() {
  return (
    <main>
      <ScrollToTop />
      <Route
        exact
        path={[
          '/dashboard',
          '/rapyd',
          '/editProfile',
          '/support',
          '/preferences',
          '/transaction/:id',
          '/rapyd/complete_checkout',
          '/rapyd/complete_payment',
          '/rapyd/cancel_payment',
          '/payment/cancel_payment',
          '/payment/error_payment',
          '/payment/inprogress_payment',
          '/payment/success_payment',
          '/payment/inProcessing',
          '/rapyd/error_payment',
          '/stripe/error',
          '/stripe/cancel',
          '/stripe/success',
          '/FAQ',
        ]}
      >
        <TopNav />
      </Route>
      <Switch>
        {/* <div style={{ minHeight: '100vh' }}> */}
        <Route path="/dashboard" component={DashboardPage} />
        <Route exact path="/support">
          <Support />
        </Route>
        <Route exact path="/preferences">
          <Preferences />
        </Route>
        <Route exact path="/FAQ">
          <FAQ />
        </Route>
        <Route exact path="/transaction/:id">
          <TransactionHisotry />
        </Route>
        <Route exact path="/editProfile">
          <EditProfile />
        </Route>
        <Route path="/invoice">
          <InvoicePage />
        </Route>

        <Route path="/rapyd/complete_checkout">
          <InvoicePaySuccess />
        </Route>
        <Route path="/stripe/success">
          <InvoicePaySuccess />
        </Route>
        <Route path="/rapyd/complete_payment">
          <InvoicePaySuccess />
        </Route>
        <Route path="/rapyd/cancel_payment">
          <InvoicePayError />
        </Route>
        <Route path="/payment/cancel_payment">
          <InvoicePayFail />
        </Route>
        <Route path="/payment/error_payment">
          <InvoicePayError />
        </Route>
        <Route path="/payment/inprogress_payment">
          <InvoicePayProcessing />
        </Route>
        <Route path="/payment/success_payment">
          <InvoicePaySuccess />
        </Route>
        <Route path="/payment/inProcessing">
          <InvoicePayProcessingPayment />
        </Route>

        <Route path="/srtipe/cancel">
          <InvoicePayFail />
        </Route>
        <Route path="/rapyd/error_payment">
          <InvoicePayError />
        </Route>
        <Route path="/srtipe/error">
          <InvoicePayError />
        </Route>
        <Redirect from="/" to="/dashboard" />
        {/* </div> */}
      </Switch>
      <Route
        exact
        path={[
          '/dashboard',
          '/rapyd',
          '/payment',
          '/editProfile',
          '/support',
          '/preferences',
          '/transaction/:id',
          '/rapyd/complete_checkout',
          '/rapyd/complete_payment',
          '/rapyd/cancel_payment',
          '/payment/cancel_payment',
          '/payment/error_payment',
          '/payment/inprogress_payment',
          '/payment/success_payment',
          '/payment/inProcessing',
          '/stripe/cancel',
          '/stripe/success',
          '/FAQ',
        ]}
      >
        <BottomNavInner />
      </Route>
    </main>
  );
}

export default MainPage;
