const COLORS = {
  primary: '#0060FF',
  red: '#FA527A',
  green: '#008c23',
  sand: '#f5f6fa',
  gray: ['#F9F9F9', '#B6C9DB', '#F6F6F6'],
  white: '#ffffff',
  black: ['#3D4251', '#6C7694'],
  shadow: 'rgba(218, 176, 103, 0.2)',
  // shadow: 'rgba(0, 0, 0, 0.15)',
};

export default COLORS;
