import Box from 'components/Box';
import PageLayout from 'components/PageLayout';

import PaymentReceipt from 'features/InvoicePayFail/PaymentReceipt';

function InvoicePayFail() {
  return (
    <PageLayout>
      <Box
        maxWidth={{ _: 600, lg: 2280 }}
        width="100%"
        display="flex"
        justifyContent="space-between"
        mx="auto"
        flexDirection={{ _: 'column-reverse', lg: 'row' }}
      >
        <PaymentReceipt />
      </Box>
    </PageLayout>
  );
}

export default InvoicePayFail;
