import { ReactComponent as SvgLogo } from 'assets/svg/logo.svg';
import { ReactComponent as SvgLogoLight } from 'assets/svg/logo-light.svg';
import { ReactComponent as SvgLogoInvoice } from 'assets/svg/logoInvoice.svg';
import Box from 'components/Box';

interface IProps {
  width?: number;
  light?: boolean;
  height?: number;
  invoice?: boolean;
}

function Logo({
  width = 170,
  light = false,
  invoice = false,
  height = 40,
}: IProps) {
  return (
    <Box width={width}>
      {light ? (
        <SvgLogoLight width={width} />
      ) : invoice ? (
        <SvgLogoInvoice width={width} height={height} />
      ) : (
        <SvgLogo width={width} />
      )}
    </Box>
  );
}

export default Logo;
