import { Button, Dialog, DialogProps, Stack } from '@mui/material';
import Box from 'components/Box';
import Text from 'components/Text';
import React, { SetStateAction, Dispatch } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

interface EscrowModalProps {
  openEscrow: boolean;
  setOpenEscrow: Dispatch<SetStateAction<boolean>>;
}

export const EscrowModal: React.FC<EscrowModalProps> = ({
  openEscrow,
  setOpenEscrow,
}) => {
  const BlurryDialog = styled(Dialog)<DialogProps>(() => ({
    backdropFilter: 'blur(1px)',
    overflow: 'hidden',
    // other styles here...
  }));
  return (
    <BlurryDialog
      // onClose={() => setIdfyVarified(false)}
      open={openEscrow}
      style={{ overflowX: 'hidden' }}
    >
      <Box maxWidth={1000} padding={20} borderRadius="26px">
        <Stack spacing={2} direction="column" alignItems="center">
          <Text fontSize={20} fontWeight={600}>
            Safeguard Funds via Escrow Pay
          </Text>
          <Text fontSize={14} style={{ marginBottom: '-16px' }}>
            When you pay via Payyd you can now escrow this payment until your
            goods or services are delivered to you by your supplier i.e they
            won’t get paid until you are fulfilled by the seller.This is a great
            way to protect yourself against fraudulent sellers.
          </Text>
          <Text fontSize={14} style={{ marginBottom: '-16px' }}>
            It’s simple- Select an “Escrow Until” date and be assured your funds
            are safe and released only after the selected date.
          </Text>
          <Box pt={2} pb={4}>
            <Button
              style={{ marginTop: 10 }}
              variant="contained"
              color="primary"
              size="large"
              // @ts-ignore
              onClick={() => {
                setOpenEscrow(!openEscrow);
              }}
            >
              Continue
            </Button>
          </Box>
        </Stack>
      </Box>
    </BlurryDialog>
  );
};
EscrowModal.propTypes = {
  openEscrow: PropTypes.bool.isRequired,
  setOpenEscrow: PropTypes.func.isRequired,
};
