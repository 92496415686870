import { Stack } from '@mui/material';
import Box from 'components/Box';
import Text from 'components/Text';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/svg/back.svg';
import { ReactComponent as BankLogo } from '../../assets/svg/bankLogo.svg';

function BankDetails() {
  const history: any = useHistory();
  // const status: string = 'PENDING';
  // const handelSubmit = async () => {
  //   try {
  //     const res = await restService(
  //       `payydbc/payment-callback?internalRef=${history?.location?.state?.data?.internalRef}&transferTransactionStatus=${status}&code=${process.env.REACT_APP_PAYYD_BC_CODE}`,
  //       'POST',
  //     );
  //     if (res) {
  //       console.log(res);
  //       message.destroy();
  //       setShowModal(true);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  return (
    <Box display="block" maxWidth={1500} width="90%" mx="auto">
      <Box
        onClick={() => history.goBack()}
        display="flex"
        pt={4}
        cursor="pointer"
        alignItems="center"
      >
        <BackIcon />
        <Text fontWeight={700} ml={15}>
          Back
        </Text>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        mx="auto"
        flexDirection={{ _: 'column-reverse', lg: 'row' }}
      >
        <Box width="100%" p="20px" display="flex">
          <Box textAlign="center" maxWidth={550} width="100%" m="0 auto">
            <Box
              justifyContent="center"
              display="flex"
              mb={4}
              mt={6}
              alignItems="center"
            >
              <BankLogo />
              <Text
                ml={2}
                fontSize={{ _: 20, md: 40, lg: 40, xl: 40 }}
                fontWeight={600}
              >
                Payment Instructions
              </Text>
            </Box>
            <Stack direction="column" mt={6} width="100%" spacing="10px">
              <Box
                style={{
                  background: '#F3F8FD',
                  border: '1px solid #D5DBE1',
                  borderRadius: '10px',
                }}
                p={3}
                justifyContent="space-between"
                display="block"
              >
                <Text
                  fontSize={{ _: 12, md: 16, lg: 16, xl: 16 }}
                  color="#646464"
                  fontWeight={600}
                >
                  Follow the instructions on the page to complete the payment.
                  You can close this page, once you have read the instructions
                </Text>
              </Box>
              <Box ml={10}>
                <Text
                  fontSize={{ _: 14, md: 18, lg: 18, xl: 18 }}
                  color="#697386"
                  fontWeight={500}
                >
                  Please send the funds to the following account listed below.
                </Text>
              </Box>
              {history?.location?.state?.data?.currencyCode === 'USD' ? (
                <Box background="#F3F8FD" padding={10} borderRadius={20}>
                  <Box display="flex" pt={20} justifyContent="space-between">
                    <Text fontSize={12}>Account Name:</Text>
                    <Text fontSize={12} fontWeight={700}>
                      Payyd Tech Works LLC
                    </Text>
                  </Box>
                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text fontSize={12}>Type of Account:</Text>
                    <Text fontSize={12} fontWeight={700}>
                      Checking
                    </Text>
                  </Box>
                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text fontSize={12}>Account number:</Text>
                    <Text fontSize={12} fontWeight={700}>
                      202335772101
                    </Text>
                  </Box>
                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text fontSize={12}>Routing number :</Text>
                    <Text fontSize={12} fontWeight={700}>
                      091311229
                    </Text>
                  </Box>
                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text fontSize={12}>Bank Name:</Text>
                    <Text fontSize={12} fontWeight={700}>
                      Choice Financial Group
                    </Text>
                  </Box>
                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text fontSize={12}>Bank Address</Text>
                    <Text
                      fontSize={12}
                      width="50%"
                      textAlign="right"
                      fontWeight={700}
                    >
                      4501 23rd Avenue S, Fargo ND 58104
                    </Text>
                  </Box>
                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text fontSize={12}>Payyd Address</Text>
                    <Text
                      fontSize={12}
                      width="50%"
                      textAlign="right"
                      fontWeight={700}
                    >
                      30 N Gould Street, St RE, Sheridan, Wyoming 82801
                    </Text>
                  </Box>
                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      fontSize={12}
                    >
                      Amount
                    </Text>
                    <Text fontSize={12} fontWeight={700}>
                      {' '}
                      {history?.location?.state?.data?.amount} USD
                    </Text>
                  </Box>
                </Box>
              ) : history?.location?.state?.data?.currencyCode === 'EUR' ? (
                <Box background="#F3F8FD" padding={10} borderRadius={20}>
                  <Box display="flex" pt={20} justifyContent="space-between">
                    <Text fontSize={12}>Account Name:</Text>
                    <Text fontSize={12} fontWeight={700}>
                      Payyd Tech Works Limited
                    </Text>
                  </Box>

                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text fontSize={12}>IBAN:</Text>
                    <Text fontSize={12} fontWeight={700}>
                      BE54 9676 3819 5597
                    </Text>
                  </Box>
                  <Box display="flex" pt={10} justifyContent="space-between">
                    <Text
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      fontSize={12}
                    >
                      Amount
                    </Text>
                    <Text fontSize={12} fontWeight={700}>
                      {' '}
                      {history?.location?.state?.data?.amount} EUR
                    </Text>
                  </Box>
                </Box>
              ) : history?.location?.state?.data?.currencyCode === 'GBP' ? (
                <Box background="#F3F8FD" padding={20} borderRadius={20}>
                  <Box
                    borderBottom="1px solid #DADADA"
                    display="flex"
                    py={2}
                    justifyContent="space-between"
                  >
                    <Text fontSize={14} whiteSpace="nowrap" fontWeight={500}>
                      Account Name:
                    </Text>
                    <Text fontSize={14} fontWeight={700}>
                      Payyd Tech Works Limited
                    </Text>
                  </Box>
                  <Box
                    borderBottom="1px solid #DADADA"
                    display="flex"
                    py={2}
                    justifyContent="space-between"
                  >
                    <Text fontSize={14} fontWeight={500}>
                      Account number:
                    </Text>
                    <Text fontSize={14} fontWeight={700}>
                      18109369
                    </Text>
                  </Box>
                  <Box
                    borderBottom="1px solid #DADADA"
                    display="flex"
                    py={2}
                    justifyContent="space-between"
                  >
                    <Text fontSize={14} fontWeight={500}>
                      Sort Code:
                    </Text>
                    <Text fontSize={14} fontWeight={700}>
                      23-14-70
                    </Text>
                  </Box>
                  <Box
                    borderBottom="1px solid #DADADA"
                    display="flex"
                    py={2}
                    justifyContent="space-between"
                  >
                    <Text fontSize={14} fontWeight={500}>
                      IBAN
                    </Text>
                    <Text
                      fontSize={14}
                      width="50%"
                      textAlign="right"
                      fontWeight={700}
                    >
                      GB73 TRWI 2314 7018 1093 69
                    </Text>
                  </Box>

                  <Box display="flex" pt={2} justifyContent="space-between">
                    <Text fontSize={14} fontWeight={500}>
                      Amount
                    </Text>
                    <Text fontSize={14} fontWeight={700}>
                      {' '}
                      {history?.location?.state?.data?.amount} GBP
                    </Text>
                  </Box>
                </Box>
              ) : null}
              <Box display="flex">
                <Text
                  fontSize={{ _: 12, md: 16, lg: 16, xl: 16 }}
                  color={'#697386'}
                  fontWeight={700}
                >
                  Please add your &nbsp;
                  <span style={{ color: '#0060FF' }}>
                    Invoice ID - {history?.location?.state?.data?.internalRef}
                  </span>
                  &nbsp; to the bank`s payment reference.
                </Text>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BankDetails;
