import { Stack } from '@mui/material';
import { Button } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';

interface PrivacyProps {
  setShowTermsModal?: any;
}

function PrivacyPolicy({ setShowTermsModal }: PrivacyProps) {
  return (
    <Box maxWidth={1000} padding={20} borderRadius="26px">
      <Stack spacing={2} direction="column" alignItems="center">
        <Box display="flex" alignItems="center">
          <Text ml={2} fontSize={18} fontWeight={600}>
            Privacy Data Policy and Terms
          </Text>
        </Box>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            1. Introduction{' '}
          </Text>
          <br />
          The Payyd service by Payyd Tech Works Limited. (“Company”, “our”,
          “us”, “we”, “Payyd”) UUK Company house number14724111 located at 7
          Bell Yard, 1st Floor, London WC2A 2JR United KIngdom, understands the
          importance of privacy, especially when it comes to your Personal
          Information. Data privacy and your trust are our top priorities, and
          in this Privacy Policy (“Privacy Policy”) we explain what information
          we collect from you when you use Payyd Services (collectively, the
          “Payyd Services”) through the secure, invite-only website
          www.payyd.money (“Secure Website & Applications”) how we will use this
          information and the very limited ways that we may share your
          information.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          This Privacy Policy does not apply to any Third Party Website &
          applications, marketplaces, or applications, even if they are
          accessible through our Services. Please read this policy carefully to
          understand our policies and practices regarding your information, as
          you consent to the collection, use, and disclosure of Personal
          Information (as defined below). IF YOU DO NOT AGREE WITH OUR POLICIES
          AND PRACTICES, DO NOT USE Payyd SERVICES.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          Please read this policy carefully to understand what information we
          collect, why we collect it, and how you can update, manage, export,
          and delete Personal Information (as defined below). In the cases where
          we require your consent to process your Personal Information, we will
          ask for your consent to the collection, use, and disclosure of your
          Personal Information. We may provide additional “just-in-time”
          disclosures or information about the data processing practices of
          specific Payyd Services. If you do not agree with our policies and
          practices, do not use Payyd Services.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          This Privacy Policy is incorporated by reference into our Terms of Use
          and subject to the provisions of the Terms of Use. All other terms not
          defined herein will have the meanings set forth in the Terms of Use.
        </Text>

        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            2. Who May Use our Services
          </Text>
          <br />
          Payyd Services are only intended for adults eighteen (18) years of age
          and older. We do not knowingly collect Personal Information from
          persons under the age of 18. If we learn that we have collected any
          Personal Information from persons under the age of 18, we will
          promptly take steps to delete such information and terminate the
          access to Payyd Services.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            3. Scope
          </Text>
          <br />
          This Privacy Policy applies to Personal Information that is Processed
          (as defined below) by Payyd in the course of our business.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            4. Personal Information We Collect and How We Use It
          </Text>
          <br /> We collect several types of information from and about
          customers, users, and visitors (collectively “Individuals”) depending
          on how they are using the Payyd Services, including:
        </Text>
        <Text
          // lineHeight="20px"
          fontSize={16}
          textAlign="left"
          width="100%"
          fontWeight={600}
          // pb={2}
        >
          (a) Types of Personal Information We Collect
        </Text>

        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (i) Information You Provide Directly to Us.
          </Text>
          <br /> When you use the Payyd Services or engage in certain
          activities, such as registering for a Payyd User Profile with us,
          creating a decentralized wallet, transferring assets, requesting
          information, or contacting us directly, we may ask you to provide the
          following types of information:
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          (A) for individuals, certain personal information, including your
          first name and last name, address, telephone number, e-mail address,
          gender, date of birth, username, and encrypted password which enables
          you to utilize certain features of the Payyd Services, financial
          information regarding your bank account (such as the name of the bank,
          the account type, routing number, and account number), payment reason,
          geographical location, social security number and photographs; and
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          (B) for entities, information about its business, financial condition,
          corporate structure, shareholders, directors, officers, ultimate
          beneficial owners, agents, and other representatives.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          In some cases, such as when you send or receive high-value or
          high-volume transactions, or where we need to comply with anti-money
          laundering regulations, we may also need more commercial or
          identification information from you.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          In providing the personal data of any individual (other than yourself)
          that receive payments from you during your use of our Services, you
          promise that you have obtained consent from such individual to
          disclose his/her personal data to us, as well his/her consent to our
          collection, use, and disclosure of such personal data, for the
          purposes set out in this Privacy Policy.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (ii) Communications with Us.
          </Text>
          <br /> We may collect Personal Information from you such as name,
          phone number, email address, or mailing address when you choose to
          request information about Payyd Services, request to receive customer
          or technical support, or otherwise communicate with us.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (iii) Automatic Data Collection.
          </Text>
          <br /> We may collect certain information automatically through Payyd
          Services or other methods of web analysis, such as your Internet
          protocol (IP) address, cookie identifiers, and other device
          identifiers that are automatically assigned to your computer or device
          when you access the Internet, browser type and language, geolocation
          information, hardware type, operating system, user settings, location
          information, mobile carrier, Internet service provider, pages that you
          visit before and after using the Payyd Services, the date and time of
          your visit, the amount of time you spend on each page, information
          about the links you click and pages you view within the Payyd website,
          and other actions taken through the use of the Payyd website such as
          preferences. Although we do not correlate tracking information to
          individuals, some information collected, such as IP addresses, will be
          unique.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (iv) Information from Other Sources.
          </Text>
          <br /> We may receive information about you from other sources,
          including through our service providers who perform the KYC and AML
          checks. These service providers may collect information from other
          sources in order to supplement the information provided by you.
          Information we collect through our service providers may include your
          name, government-issued identification number, location, gender, birth
          date, and email address. This supplemental information allows us to
          verify information that you have provided to us and to enhance our
          ability to provide you with information about our business, products,
          and Payyd Services.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (v) Do Not Track Settings.
          </Text>
          <br /> Do Not Track (“DNT”) is a privacy preference that Individuals
          can set in certain web browsers. DNT is a privacy preference that
          Individuals can set in certain browsers to inform Websites &
          Applications that they do not want certain information about their
          webpage visits collected over time and across Websites & Applications.
          We do not track, plant cookies, or use advertising when a DNT browser
          mechanism is in place.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            (b) How We Use Your Information
          </Text>
          <br /> We collect Personal Information in an effort to provide you
          with secure and efficient customer experience, to protect you from
          risks of improper use and fraud and to improve, develop and improve
          Payyd Services. We use information that we collect about you or that
          you provide to us, including Personal Information:
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (i) To provide you with information, products, or services that you
            request from us.
          </Text>
          <br /> We may use information about you to:
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2} pl={4}>
          ● Generally manage customer information and accounts;
          <br />
          ● To provide you with information, products, or services that you
          request from us;
          <br />
          ● Provide access to certain areas, functionalities, and features of
          our Payyd Site and Payyd App; and
          <br />● Contact you to answer requests for customer support or
          technical support.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (ii) Administrative Purposes.
          </Text>
          <br /> We may use Personal Information about you for administrative
          purposes, including to:
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2} pl={4}>
          ● Measure interest in our Services;
          <br />
          ● Ensure internal quality control;
          <br />
          ● Verify Individual identity;
          <br />
          ● Communicate about Individual accounts and activities on our Payyd
          Site and Payyd App and systems, and, in our discretion, changes to
          Payyd Services or any of our policies;
          <br />
          ● Send email to the email address you provide to us to verify your
          account and for informational and operational purposes;
          <br />
          ● Verify the information that you provide to use and validate you
          through the KYC/AML process required by our fiduciary partners;
          <br />
          ● Meet the requirements to make disclosures under the requirements of
          any law binding on the Payyd Site and Payyd App under and for the
          purposes of any guidelines issued by regulatory or other authorities
          with which Payyd Site and Payyd App is expected to comply;
          <br />
          ● Process payment for products of Payyd Services purchased;
          <br />
          ● Provide you with notices about your account and payments;
          <br />
          ● Process applications and transactions;
          <br />
          ● Prevent potentially prohibited or illegal activities;
          <br />● Carry out our obligations and enforce our rights arising from
          any contracts entered into between you and us, including for billing
          and collection and ● Enforce our Terms.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (iii) Limiting Use, Disclosure, and Retention.
          </Text>
          <br /> We will not use your Personal Information Data for any purpose
          that you have not consented to. Payyd will NOT sell or trade Personal
          Information Data for commercial purposes. We will only use Personal
          Information to provide you with important messages about our Services
          and transactional communications regarding your activity on our
          Website & Applications, such as deposit and withdrawal notifications.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (iv) Anonymous and Aggregated Information Use.
          </Text>
          <br /> We may use Personal Information and other information about you
          to create anonymized and aggregated information, such as de-identified
          demographic information, de-identified location information,
          information about the computer or device from which you access Payyd
          Services or other analyses we create. Anonymized and aggregated
          information are used for a variety of functions, including the
          measurement of visitors’ interest in and use of various portions or
          features of the Payyd Services. Anonymized or aggregated information
          is not Personal Information, and we may use such information in a
          number of ways, including research, internal analysis, analytics, and
          any other legally permissible purposes. We may share this information
          within us and with Third Parties for our or their purposes in an
          anonymized or aggregated form designed to prevent anyone from
          identifying you.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (v) Other Uses.
          </Text>
          <br /> We may use Personal Information for which we have a legitimate
          interest, such as direct marketing, individual or market research,
          anti-fraud protection, or any other purpose disclosed to you at the
          time you provide Personal Information or with your consent.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            (c) Cookies, Pixel Tags/Web Beacons, Analytics Information, and
            Interest-Based Advertising
          </Text>
          <br /> We, as well as Third Parties that provide content or other
          functionality on our Site and App, may use cookies, pixel tags, local
          storage, and other technologies (“Technologies”) to automatically
          collect information through the Payyd Services. We use Technologies
          that are essentially small data files placed on your computer, tablet,
          mobile phone, or other devices (referred to collectively as a
          “device”) that allow us to record certain pieces of information
          whenever you visit or interact with Payyd Services, applications,
          messaging, and tools, and to recognize you across all relevant
          devices.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (i) Cookies.
          </Text>
          <br /> Cookies are small text files placed in visitors’ devices to
          store their preferences. Most browsers allow you to block and delete
          cookies. However, if you do that, some features of the Payyd Services
          may not work properly.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={14} fontWeight={600} textDecoration="underline">
            {' '}
            (ii) Pixel Tags/Web Beacons.
          </Text>
          <br /> A pixel tag (also known as a web beacon) is a piece of code
          embedded on the Site or the App that collects information about
          Individuals’ engagement on the Site or the App. The use of a pixel
          allows us to record, for example, that a user has visited a particular
          web page or clicked on a particular advertisement.
        </Text>

        <Text width="100%" lineHeight="20px" textAlign="left" pb={2}>
          Our uses of such Technologies fall into the following general
          categories:
        </Text>
        <Box pl={4}>
          <Text lineHeight="20px" textAlign="left" pb={2}>
            <Text fontSize={14} fontWeight={600} textDecoration="underline">
              {' '}
              (a) Operationally Necessary
            </Text>
            <br /> We may use cookies, web beacons, or other similar
            technologies that are necessary to the operation of Payyd Services,
            applications, and tools. This includes technologies that allow you
            access to Payyd Services, applications, and tools; that are required
            to identify irregular Payyd Service behavior, prevent fraudulent
            activity and improve security; or that allow you to make use of our
            functions such as Payyd invoices, pending invoices, or similar
            functions;
          </Text>
          <Text lineHeight="20px" textAlign="left" pb={2}>
            <Text fontSize={14} fontWeight={600} textDecoration="underline">
              {' '}
              (b) Performance Related
            </Text>
            <br /> We may use cookies, web beacons, or other similar
            technologies to assess the performance of our applications, Payyd
            Services, and tools, including as part of our analytic practices to
            help us understand how our visitors use Payyd Services, determine if
            you have interacted with our messaging, determine whether you have
            viewed an item or link, or to improve our Website & Applications
            content, applications, Payyd Services, or tools;
          </Text>
          <Text lineHeight="20px" textAlign="left" pb={2}>
            <Text fontSize={14} fontWeight={600} textDecoration="underline">
              {' '}
              (c) Functionality Related
            </Text>
            <br />
            We may use cookies, web beacons, or other similar technologies that
            allow us to offer you enhanced functionality when accessing or using
            Payyd Services. This may include identifying you when you sign into
            your account or keeping track of your specific preferences,
            interests, or past items viewed so that we may enhance the
            presentation of content;
          </Text>
          <Text lineHeight="20px" textAlign="left" pb={2}>
            <Text fontSize={14} fontWeight={600} textDecoration="underline">
              {' '}
              (d) Advertising or Targeting Related
            </Text>
            <br />
            We may use first-party or Third Party cookies and web beacons to
            deliver content, including ads relevant to your interests, on our
            Site, or on Third Party websites & applications. This includes using
            Technologies to understand the usefulness to you of the
            advertisements and content that has been delivered to you, such as
            whether you have clicked on an advertisement.
          </Text>
        </Box>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          If you would like to opt out of the Technologies we employ through
          Payyd Services, applications, or tools, you may do so by blocking,
          deleting, or disabling them as your browser or device permits. Please
          note however that certain functionality of the Payyd Services may not
          be available or function properly if you opt-out of certain Technology
          uses.
        </Text>

        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            5. Information for European Economic Area Data Subjects
          </Text>
          <br />
          If you are a resident of the European Economic Area, United Kingdom,
          or Switzerland (collectively, “EEA”), you may have additional rights
          under the EU General Data Protection Regulation (the “GDPR”) with
          respect to your Personal Information, as outlined below. To the extent
          applicable to us, we adhere to relevant EEA data protection laws. For
          purposes of this section, the terms “Personal Information” and
          “processing/processed” have the meanings provided in the GDPR. <br />
          <br />
          (i) Processing Based on Lawful Basis Only. We only process your
          Personal Information if we have a lawful basis for doing so. Below are
          the legal bases we process the Personal Information subject to GDPR:{' '}
          <br />
          <br />
          (a) Consent: To enhance your user experience, to engage in third-party
          marketing activities, direct marketing activities and any other
          purposes to be indicated to you in order to receive your consent. We
          will clearly and expressly indicate to you if we process Personal
          Information based on your consent, at the time of collection. You may
          withdraw your consent at any time without affecting the lawfulness of
          processing based on consent before consent is withdrawn.
          <br />
          <br /> (b) Contractual necessity: To carry out our obligations and
          enforce our rights arising from any contracts entered into between you
          and us, including for billing and collection, to provide Payyd
          Services, customer support service, and service communications, and to
          improve Payyd Services and ensure internal quality control.
          <br />
          <br /> (c) Legitimate interest: To conduct research and development of
          new products, monitor the usage of Payyd, facilitate corporate
          acquisitions, mergers, or transactions, engage in direct marketing
          activities, and to protect our rights. When we process Personal
          Information based on our legitimate interests, we always consider and
          balance any potential impact on you and your rights under data
          protection laws. <br />
          <br />
          (d) Legal obligation, Public Interest or in your Vital Interest: To
          conduct anti-fraud, anti-money laundering and identity
          verification/authentication checks, to comply with legal and
          regulatory applications binding on Payyd, to satisfy retention
          obligations and to ensure network and information security. <br />
          <br />
          (ii) Collection and Use of Special Categories of Personal Information.
          Some of the Personal Information we collect is considered sensitive by
          applicable law and referred to as Special Categories of Personal
          Information. We may collect and process the special categories of
          Personal Information when you voluntarily provide them for us to
          comply with our legal obligations, or as applicable law otherwise
          permits. For example, we may collect your biometric data and racial or
          ethnic origin to conduct anti-fraud, anti-money laundering and
          identity verification/authentication checks, to comply with legal and
          regulatory applications binding on Payyd.
          <br />
          <br /> (iii) Marketing Direct Marketing: We may, from time to time,
          send direct marketing materials to you that are only based on
          advertising our products, services, facilities or activities. We will
          only communicate to you based on our legitimate interests, subject to
          the applicable laws and regulations, or with your consent. You may opt
          out of such communications by following the directions provided in any
          marketing communication. Third-Party Marketing: We will not provide
          your Personal Information to any third parties for third-party
          marketing purposes without obtaining your consent.
          <br />
          <br /> (iv) Automated Individual Decision making. Depending on Payyd
          products or services you use, we may make automated decisions about
          you. This means that we may use technology that can evaluate your
          personal circumstances and other factors to predict risks or outcomes.
          We do this for the efficient running of our services and to ensure
          decisions are fair, consistent and based on the right information.
          Where we make an automated decision about you, you have the right to
          ask that it is manually reviewed by a person. For example, we may make
          automated decisions about you that relate to: Opening accounts
          <br />
          <br /> ● anti-money laundering and sanctions checks; and
          <br />
          <br /> ● identity and address checks. Detecting fraud
          <br />
          <br /> ● monitoring your account to detect fraud and financial
          crime.Our legal basis is one or more of the following <br />
          <br />● keeping to contracts and agreements between you and us; or
          legal obligations.
          <br />
          <br />
          (v) Contact Us If you would like to contact us about our practices, or
          exercise any of your rights;
          <br />
          <br />
          General Support: support@payyd.money<br></br>
          Cardholder Support; legal@payyd.money<br></br>
          Write to;<br></br>
          Payyd Tech Works Limited<br></br>7 Bell Yard, 1st Floor<br></br>
          London WC2A 2JR
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            6. How We Share Your Personal Information
          </Text>
          <br /> We only collect Personal Information that we believe to be
          relevant and required to conduct our business. We may share your
          Personal Information with our service providers, but only to the
          extent that they each need to know specific information to continue to
          provide services to you. This includes: <br />
          <br />
          (i) Vendors and Service Providers We may share any information we
          receive with vendors and service providers. The types of service
          providers (processors) to whom we entrust Personal Information include
          service providers for (i) provision of IT and related services; (ii)
          provision of information you have requested; (iii) payment processing;
          (iv) customer service activities; (v) fraud prevention; and (vi) in
          connection with the provision of the services, including KYC/AML
          checks. We have executed appropriate contracts with the service
          providers that prohibit them from using or sharing Personal
          Information except as necessary to perform the contracted services on
          our behalf or to comply with applicable legal requirements.
          <br />
          <br /> (ii) Disclosures to Protect Us or Others (e.g., as required by
          law and similar disclosures). We may access, preserve, and disclose
          your Personal Information, other account information, and content, if
          we believe doing so, is required or appropriate to: (i) comply with
          law enforcement or national security requests and legal processes,
          such as a court order or subpoena; (ii) respond to your requests;
          (iii) protect your, our or others’ rights, property, or safety; (iv)
          enforce our policies or contracts; (v) collect amounts owed to us;
          (vi) prevent physical harm or financial loss or in connection with an
          investigation or prosecution of suspected or actual illegal activity;
          or (vii) disclose your Personal Information in good faith where it is
          otherwise necessary or advisable. <br />
          <br />
          (iii) Security Reasons In addition, from time to time, server logs may
          be reviewed for security purposes – e.g., to detect unauthorized
          activity on the Payyd Site. In such cases, server log data containing
          IP addresses may be shared with law enforcement bodies in order that
          they may identify Individuals in connection with their investigation
          of unauthorized activities.
          <br />
          <br /> (iv) Merger, Sale, or Other Asset Transfers If we are involved
          in a merger, acquisition, financing due diligence, reorganization,
          bankruptcy, receivership, sale of company assets, or transition of
          service to another provider, then your information may be sold or
          transferred as part of such a transaction as permitted by law and/or
          contract. In such an event, we will endeavor to direct the transferee
          to use Personal Information in a manner that is consistent with the
          Privacy Policy in effect at the time such Personal Information was
          collected.
          <br />
          <br /> (v) For any other purpose disclosed by us and consented by you
          when you provide the information. We may disclose aggregated
          information about our users, which means information that does not
          identify any individual, without restriction. As an example, we may
          include in our marketing materials that a certain percentage of our
          users are female, but we will not share a list of the female users in
          these materials.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            7. Data Transfers
          </Text>
          <br /> (i) International Data Transfers. You agree that all Personal
          Information collected via or by us may be transferred, Processed, and
          stored anywhere in the world, including but not limited to the United
          States, in the cloud, on our servers, on the servers of our
          affiliates, or the servers of our service providers. Your Personal
          Information may be accessible to law enforcement or other authorities
          pursuant to a lawful request. By providing information to us, you
          explicitly consent to the storage of your Personal Information in
          these locations. <br />
          <br />
          (ii) Collection and Transfer of Data outside the EEA. Payyd operates
          globally with many of its systems based in the United States.
          Therefore, we may need to transfer your Personal Information outside
          of the country from which it was originally provided, and which has no
          data protection laws or laws that are less strict in comparison to
          those in EEA. You agree that all Personal Information collected via or
          by us may be transferred, Processed, and stored anywhere in the world,
          including but not limited to the United States, in the cloud, on our
          servers, on the servers of our affiliates, or the servers of our
          service providers. Your Personal Information may be accessible to law
          enforcement or other authorities pursuant to a lawful request. When we
          transfer Personal Information outside of the EEA, we take steps to
          ensure appropriate safeguards are in place to protect your Personal
          Information. We contractually obligate recipients of your Personal
          Information to agree to at least the same level of privacy safeguards
          as required under applicable data protection laws. By communicating
          electronically with Payyd, you explicitly consent to the storage of
          your Personal Information in these locations. When we transfer your
          Personal Information based on your consent, you may withdraw your
          consent at any time. In the event of withdrawal of consent and thus we
          are unable to transfer Personal Information to third countries, Payyd
          Services may become unavailable to you.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            8.Your Privacy Rights
          </Text>
          <br /> Subject to the applicable law of where you reside, you may be
          able to exercise certain rights regarding your Personal Information,
          as explained below. These rights may not be available to all users,
          depending on the local law applicable to you based on the jurisdiction
          in which you reside. If you are a resident of the EEA, you have such
          rights pursuant to GDPR. We may not accommodate a request to change
          information if we believe the change would violate any law or legal
          requirement or cause the information to be incorrect. Although we make
          good faith efforts to provide individuals with access to their
          Personal Information, there may be circumstances in which we are
          unable to provide access, including but not limited to: where the
          information contains legal privilege, would compromise others’ privacy
          or other legitimate rights, where the burden or expense of providing
          access would be disproportionate to the risks to the Individual’s
          privacy in the case in question or where it is commercially
          proprietary. If we determine that access should be restricted in any
          particular instance, we will provide you with an explanation of why
          that determination has been made and a contact point for any further
          inquiries. To protect your privacy, we will take commercially
          reasonable steps to verify your identity before granting access to or
          making any changes to your Personal Information. How to exercise your
          rights. To exercise any of your rights set out below you can submit
          your request by emailing us at support@payyd.money. In the request
          please include your full name, the email address associated with your
          account, and a detailed description of your data request. Such
          requests will be processed in line with local and applicable laws.
          Payyd has absolute discretion in providing you with those rights if
          any of the rights mentioned below are not provided under the
          applicable local laws of the jurisdiction in which you reside. For
          security reasons, we can`t deal with your request if we are not sure
          of your identity, so we may ask you for proof of your ID. If you are
          an EEA resident Payyd will respond to your request without delay and
          within one month of receipt of the request. We may extend the time
          limit by a further two months if the request is complex or if we
          receive a number of requests from you. Payyd will usually not charge
          you a fee when you exercise your rights. However, we are allowed by
          law to charge a reasonable fee or refuse to act on your request if it
          is manifestly unfounded or excessive. <br />
          <br />
          You have the following Privacy Rights:
          <br />
          <br /> (i) Right to be informed. You have the right to be informed
          about how we collect and use your Personal Information. We provide
          this privacy policy to explain how we use your personal data.
          <br />
          <br /> (ii) Right of access. You can review and change your Personal
          Information by logging into your Account with Payyd. You may also
          inquire as to whether we are Processing Personal Information about you
          or requesting access to your Personal Information. <br />
          <br />
          (iii) Right of rectification. You have the right to request the
          rectification of inaccurate Personal Information and to have
          incomplete data completed.
          <br />
          <br /> (iv) Right to object. You have the right to object to the
          processing of your Personal Information in the following
          circumstances:
          <br />
          <br /> -when processing is based on our legitimate interest;
          <br /> -direct marketing;
          <br /> -processing for the purpose of scientific/historical research
          and statistics;
          <br />
          -performance of a task in the public interest.
          <br />
          <br /> Where you object to the processing of your Personal Information
          based on any of the above grounds (except for the direct marketing),
          <br />
          <br /> Payyd must stop unless: <br />
          -we can demonstrate compelling legitimate grounds for the processing,
          which override your interests, rights and freedoms; or <br />
          -Processing is for the establishment, exercise or defence of legal
          claims. You have an absolute right to stop your Personal Information
          being used for direct marketing. This includes any profiling of data
          that is related to direct marketing. There are no exemptions or
          grounds for Payyd to refuse your such request.
          <br />
          <br /> (v) Right to refuse or withdraw consent <br />
          <br />
          (a) General. To the extent the processing of your Personal Information
          or sensitive Personal Information is based on your consent, you may
          withdraw your consent and opt out of further Processing by contacting
          us as described below, at any time. Your withdrawal will not affect
          the lawfulness of Payyd’s Processing based on consent before your
          withdrawal.
          <br />
          <br /> (b) Email and Telephone Communications. If you receive an
          unwanted email from us, you can use the unsubscribe link found at the
          bottom of the email to opt out of receiving future emails. We will
          process your request within a reasonable time after receipt. Note that
          you will continue to receive transaction-related emails regarding
          products or Payyd Services you have requested. We may also send you
          certain non-promotional communications regarding us and our Services
          and you will not be able to opt out of those communications (e.g.,
          communications regarding updates to our Terms or this Privacy Policy).
          We maintain telephone “do-not-call” lists as mandated by law. We
          process requests to be placed on do-not-phone and do-not-contact lists
          within 60 days after receipt, or such shorter time as may be required
          by law.
          <br />
          <br /> (vi) Right to erasure (the right to be forgotten). You may
          request to erase your Personal Information if
          <br />
          <br /> (i) it is no longer necessary for the purposes for which we
          have collected it,
          <br /> (ii) you have withdrawn your consent and no other legal ground
          for the processing exists,
          <br /> (iii) you objected and no overriding legitimate grounds for the
          processing exist,
          <br /> (iv) the processing is unlawful, or erasure is required to
          comply with a legal obligation. Upon complying with your erasure
          request, we may still keep certain account information in our database
          for a certain amount of time in order to satisfy our legal
          obligations. <br />
          <br />
          The right to erasure does not apply if Processing is necessary for one
          of the following reasons: to exercise the right of freedom of
          expression and information; to comply with a legal obligation; for the
          performance of a task carried out in the public interest or in the
          exercise of official authority; for archiving purposes in the public
          interest, scientific research historical research or statistical
          purposes where erasure is likely to render impossible or seriously
          impair the achievement of that processing; or for the establishment,
          exercise or defence of legal claims.
          <br />
          <br /> If Payyd has disclosed your Personal Information to others,
          Payyd will contact each recipient and inform them of the erasure,
          unless this proves impossible or involves disproportionate effort. If
          asked to, Payyd will also inform you about these recipients.
          <br />
          <br /> (vii) Right to restrict processing. Subject applicable law, you
          may have the right to restrict or object us to Process your Personal
          Information under certain conditions.
          <br />
          <br /> You have the right to request us to restrict the processing of
          your Personal Information in the following circumstances:
          <br />
          <br /> You contest the accuracy of your Personal Information and Payyd
          is verifying the accuracy of the information;
          <br /> the information has been unlawfully processed (ie in breach of
          the lawfulness requirement of the first principle of the GDPR) and you
          opposes erasure and request restriction instead; <br />
          Payyd no longer need the Personal Information but you need us to keep
          it in order to establish, exercise or defend a legal claim; <br />
          or you have objected to Payyd processing your data under Article 8
          (iv), and Payyd is considering whether its legitimate grounds override
          those of yours’.
          <br />
          <br />
          Although this is distinct from the right to rectification and the
          right to object, there are close links between those rights and the
          right to restrict processing:
          <br />
          <br /> if you have challenged the accuracy of your data and asked for
          us to rectify it (Article 8 (iii)), you also have a right to request
          Payyd restrict processing while Payyd considers your rectification
          request; or
          <br /> if you exercise your right to object under Article 8 (iv), you
          also have a right to request Payyd to restrict processing while Payyd
          considers your objection request.
          <br />
          <br />
          Therefore, as a matter of good practice Payyd will automatically
          restrict the processing whilst it is considering its accuracy or the
          legitimate grounds for processing the Personal Information in
          question.
          <br />
          <br /> (viii) Portability. You may receive your Personal Information
          that you have provided to us, in a structured, commonly used and
          machine-readable format and have the right to transmit it to other
          data controllers without hindrance. This right only exists if the
          processing is based on your consent or a contract and the processing
          is carried out by automated means.
          <br />
          <br /> (ix) Rights related to automated decision-making. If we make an
          automated decision about you that significantly affects you, you can
          ask us to carry out a manual review of this decision.
          <br />
          <br /> (x) Right to lodge a complaint.If you are EEA resident and are
          unhappy with how we have handled your personal data, you have the
          right to lodge a complaint with a supervisory authority, in particular
          in the EEA Member State of your residence or the location where the
          issue that is the subject of the complaint occurred (a list is
          available on this Website & Applications). Further to such a complaint
          right, you have the right to an effective judicial remedy.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            9. Data Retention
          </Text>
          <br />
          We retain the Personal Information we receive as described in this
          Privacy Policy for as long as you use our Site and App or as necessary
          to fulfill the purpose(s) for which it was collected, provide Payyd
          Services, resolve disputes, establish legal defenses, conduct audits,
          pursue legitimate business purposes, enforce our agreements, and
          comply with applicable laws.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            10. Security of Your Information
          </Text>
          <br />
          We take steps to ensure that your information is treated securely and
          in accordance with this Privacy Policy. We have implemented measures
          designed to secure your Personal Information from accidental loss and
          from unauthorized access. All user information is encrypted through
          the Site and App with Secure Socket Layer technology (SSL) and is also
          encrypted when it is stored by us to prevent unauthorized parties from
          viewing such information. Also, we perform regular malware scanning of
          the Site and App and all servers and computers used by us to support
          the Site and App. All Company employees are required to adhere to our
          security and confidentiality procedures and undergo training related
          to maintaining the security of user Personal Information. The safety
          and security of your information also depends on you. Where you have
          chosen a password for access to certain parts of the Site and App, you
          are responsible for keeping this password confidential. We ask you not
          to share your password with anyone and be careful about giving out
          information to other Individuals on the Payyd platform if requested.
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your Personal
          Information, we cannot guarantee the security of your Personal
          Information transmitted to the Payyd platform. Any transmission of
          Personal Information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the platform, and we do not accept liability for unintentional
          disclosure. By using Payyd Services or providing Personal Information
          to us, you agree that we may communicate with you electronically
          regarding security, privacy, and administrative issues relating to
          your use of the Payyd Services. If we learn of a security system’s
          breach, we may attempt to notify you electronically by making a notice
          available to you through the platform or sending an email to you. You
          may have a legal right to receive this notice in writing.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            11. International Users
          </Text>
          <br />
          By using the Site and App, you explicitly agree that we will transfer
          data to the United States and other global jurisdictions. By choosing
          to use the Site and App or otherwise provide information to us, you
          agree that any dispute over privacy or the terms contained in this
          Privacy Policy and the adjudication of any disputes arising in
          connection with the platform will be in accordance with the Terms of
          Use.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            12. Third Party Links
          </Text>
          <br />
          Our Website & Applications may contain links that lead to other
          Website & Applicationss, and Payyd is not responsible for the privacy
          practices, content, and/or activities of these linked Website &
          Applicationss. Nonetheless, we seek to protect the integrity of our
          Services and welcome any feedback about these external Website &
          Applicationss.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            13. Redress/Compliance and Accountability
          </Text>
          <br />
          If you have any questions about our privacy practices, this Privacy
          Policy, or how to lodge a complaint with the appropriate authority,
          please contact us as described below. We will address your concerns
          and attempt to resolve any privacy issues in a timely manner.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            14. Other Rights and Important Information
          </Text>
          <br />
          (i) Changes to our Privacy Policy and Practices. This Privacy Policy
          may change from time to time. You understand and agree that you will
          be deemed to have accepted the updated Privacy Policy if you use the
          Site and App after we make the updated Privacy Policy available to
          you. If at any point you do not agree to any portion of the Privacy
          Policy then in effect, you must immediately stop using the Site and
          App. <br />
          <br />
          (ii) Revision to the Privacy Policy. We may revise this Privacy Policy
          in our sole discretion, so review it periodically. If you continue to
          access or use the Site and App made available to you after such
          changes have been made, you are deemed to have provided your consent
          to the changes.
          <br />
          <br /> (iii) Posting of Revised Privacy Policy. If there are any
          material changes to this Privacy Policy, we will notify you by email
          or as otherwise required by applicable law. We will post any
          adjustments to the Privacy Policy on the Site, and the revised version
          will be effective immediately when it is posted (or upon notice as
          applicable). <br />
          <br />
          (iv) New Uses of Personal Information. Additionally, before we use
          Personal Information for any new purpose not originally authorized by
          you, we will endeavor to provide information regarding the new purpose
          and give you the opportunity to opt out. Where consent of the
          Individual for the Processing of Personal Information is otherwise
          required by law or contract, we will endeavor to comply with the law
          or contract.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            15. Definitions
          </Text>
          <br />
          The following capitalized terms will have the meanings herein as set
          forth below.
          <br />
          <br /> ● “Agent” means any Third Party that Processes Personal
          Information pursuant to the instructions of, and solely for, us or to
          which we disclose Personal Information for use on its behalf.
          <br />
          <br /> ● “Personal Information” is any information relating to an
          identified or identifiable natural person (“Individual”); an
          identifiable natural person is one who can be identified, directly or
          indirectly, in particular by reference to an identifier such as a
          name, an identification number, location data, an online identifier or
          to one or more factors specific to the physical, physiological,
          genetic, mental, economic, cultural or social identity of that natural
          person.
          <br />
          <br />● “Process” or “Processing” means any operation which is
          performed upon Personal Information, whether or not by automatic
          means, such as collection, recording, organization, structuring,
          storage, adaptation or alteration, retrieval, consultation, use,
          disclosure by transmission, dissemination or otherwise making
          available, alignment or combination, restriction, erasure, or
          destruction.
          <br />
          <br /> ● “Sensitive Data” or “Sensitive Personal Information” is a
          subset of Personal Information that, due to its nature, has been
          classified by law or by policy as deserving additional privacy and
          security protections. Sensitive Personal Information includes Personal
          Information consisting of the following data elements: (1) race or
          ethnic origin; (2) political opinions; (3) religious or philosophical
          beliefs; (4) trade union membership; (5) genetic data; (6) biometric
          data where processed to uniquely identify a person; (6) health
          information; (7) sexual orientation or information about the
          Individual’s sex life; or (8) information relating to the commission
          of a criminal offense.
          <br />
          <br />● “Third Party” is any company, natural or legal person, public
          authority, agency, or body other than you, us or our Agents.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          <Text fontSize={16} fontWeight={600}>
            {' '}
            16. Contact Us
          </Text>
          <br />
          If you would like to contact us about our privacy practices or
          exercise any of your data subject rights, please contact us through
          our Support Portal or by emailing compliance@payyd.money.
        </Text>
        <Box display="flex" alignItems="center">
          <Text ml={2} fontSize={18} fontWeight={600}>
            Website & Applications Terms
          </Text>
        </Box>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          These terms of use (the “Agreement”) form an agreement between you
          (“You”) and The Payyd App, hereby Payyd (the “Payyd”, “We” or “Us”).
          The effective date of this Agreement is when You accept or are deemed
          to accept this Agreement as discussed below. You hereby consent to the
          exchange of information and documents between You and Us
          electronically over the Internet or by email and that this electronic
          Agreement shall be the equivalent of a written paper agreement between
          You and Us.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          1. Ownership and Copyright. You acknowledge that any and all
          information, content, reports, data, databases, graphics, interfaces,
          web pages, text, files, software, product names, company names,
          trade-marks, logos and trade-names contained on this Website &
          Applications (collectively the “Content”), including the manner in
          which the Content is presented or appears and all information relating
          thereto, are the property of their respective owners as indicated,
          Payyd or its licensors, as the case may be.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          2. Permitted Use. Payyd hereby grants to You a limited, revocable,
          personal, non-transferable, non-sublicensable and non-exclusive
          license to access, read and download one copy of the Content solely
          for the purpose of evaluating the services offered by us (“Services”).
          Further, You hereby acknowledge and agree that Your use of the
          Services is limited to accessing Payyd’s secure payment portal (the
          “Portal”) in order to review and pay for invoices sent by Payyd. You
          must pay any invoices in full no later than 5 Calendar Days after the
          invoice is uploaded into the Portal.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          3. Restrictions on Use. You agree that You will not: distribute the
          Content for any purpose, including, without limitation, compiling an
          internal database, redistributing or reproducing the Content by way of
          the press or media or through any commercial network, cable or
          satellite system; or create derivative works of, reverse engineer,
          decompile, disassemble, adapt, translate, transmit, arrange, modify,
          copy, bundle, sell, sublicense, export, merge, transfer, adapt, loan,
          rent, lease, assign, share, outsource, host, publish, make available
          to any person or otherwise use, either directly or indirectly, the
          Content in whole or in part, in any form or by any means whatsoever,
          be they physical, electronic or otherwise. You shall not permit, allow
          or do anything that would infringe or otherwise prejudice the
          proprietary rights of Payyd or its licensors or allow any third party
          to access the Content. The restrictions set out in this Agreement
          shall not apply to the limited extent the restrictions are prohibited
          by applicable law.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          4.License to Use Your Information. With the exception of your
          non-public personal information, You hereby grant to Payyd the
          perpetual, unlimited, royalty-free, worldwide, non-exclusive,
          irrevocable, transferable license to run, display, copy, reproduce,
          publish, bundle, distribute, market, create derivative works of,
          adapt, translate, transmit, arrange, modify, sublicense, export,
          merge, transfer, loan, rent, lease, assign, share, outsource, host,
          make available to any person or otherwise use any information or other
          content You provide on or through this Website & Applications or which
          is sent to Payyd by email or other correspondence, including, without
          limitation, any ideas, concepts, inventions, know-how, techniques or
          any intellectual property contained therein, for any purpose
          whatsoever. Payyd shall not be subject to any obligations of
          confidentiality regarding any such information unless specifically
          agreed to by Payyd in writing or required by law. You represent and
          warrant that you have the right to grant the license set out above.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          5. Personal Information. Payyd may from time to time, but is not
          obligated to, monitor your use of the Website & Applications and
          collect, store, use and disclose information concerning you to solicit
          you to offer you its Services, and You hereby consent to such
          collection, storage, use and disclosure and waive any right of privacy
          You may have in it.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          6. Service Agreement. If You procure Services from Payyd or one of its
          suppliers or sponsors, then such Services shall be delivered pursuant
          to a separate agreement and are not provided hereunder, and You shall
          have no rights or claims in respect of such Services hereunder.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          7.Limitations on Liability and Disclaimers. THERE IS NO GUARANTEE THAT
          PERSONAL INFORMATION AND TRANSACTIONS ON THIS Website & Applications
          OR ON THE INTERNET WILL BE MAINTAINED CONFIDENTIAL AND SECURE. THE USE
          OF THIS Website & Applications AND THE CONTENT IS AT YOUR OWN RISK,
          AND Payyd ASSUMES NO LIABILITY OR RESPONSIBILITY PERTAINING TO THE
          CONTENT, YOUR USE OF THE Website & Applications OR THE RECEIPT,
          STORAGE, TRANSMISSION OR OTHER USE OF YOUR PERSONAL INFORMATION. This
          Website & Applications may contain links to other sites. Payyd does
          not assume responsibility for the accuracy or appropriateness of the
          information, data, opinions, advice or statements contained at such
          sites, and when You access such sites, You are doing so at Your own
          risk. In providing links to the other sites, Payyd is in no way acting
          as a publisher or disseminator of the material contained on those
          other sites and does not seek to monitor or control such sites. A link
          to another site should not be construed to mean that Payyd is
          affiliated or associated with same. Payyd DOES NOT RECOMMEND OR
          ENDORSE ANY OF THE CONTENT, INCLUDING WITHOUT LIMITATION ANY
          HYPERLINKS TO, OR CONTENT FOUND, ON OTHER Website & ApplicationsS. The
          mention of another party or its product or service on this Website &
          Applications should not be construed as an endorsement of that party
          or its product or service. Payyd will not be responsible for any
          damages You or any third party may suffer as a result of the
          transmission, storage or receipt of confidential or proprietary
          information that You make or that You expressly or implicitly
          authorize Payyd to make, or for any errors or any changes made to any
          transmitted, stored or received information. You are solely
          responsible for the retrieval and use of the Content. You should apply
          Your own judgment in making any use of any Content, including, without
          limitation, the use of the information as the basis for any
          conclusions. THE CONTENT MAY NOT BE ACCURATE, UP TO DATE, COMPLETE OR
          UNTAMPERED, AND IS NOT TO BE RELIED UPON. THE CONTENT IS PROVIDED FOR
          INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE INTERPRETED AS A
          RECOMMENDATION FOR ANY SPECIFIC PRODUCT OR SERVICE, USE, OR COURSE OF
          ACTION. EXCEPT AS EXPRESSLY PROVIDED IN A SEPARATE AGREEMENT WITH YOU,
          THIS Website & Applications AND ALL CONTENT, PRODUCTS, SERVICES, AND
          SOFTWARE ON THIS Website & Applications OR MADE AVAILABLE THROUGH THIS
          Website & Applications ARE PROVIDED “AS IS” WITHOUT ANY
          REPRESENTATIONS, WARRANTIES, GUARANTEES OR CONDITIONS, OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT
          LIMITED TO, WARRANTIES AS TO UNINTERRUPTED OR ERROR-FREE OPERATION,
          AVAILABILITY, ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS,
          LEGALITY, SUITABILITY, PRIVACY, SECURITY, MERCHANTABILITY, QUALITY,
          TITLE, NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE, OR THOSE
          ARISING OUT OF A COURSE OF DEALING OR USAGE OF TRADE. IN NO EVENT WILL
          Payyd, ITS AFFILIATES, AGENTS, LICENSORS, SUPPLIERS OR THEIR
          RESPECTIVE DIRECTORS, OFFICERS OR EMPLOYEES BE LIABLE FOR ANY SPECIAL,
          INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, AGGRAVATED, ECONOMIC OR
          CONSEQUENTIAL DAMAGES, HOWSOEVER CAUSED, INCLUDING BUT NOT LIMITED TO:
          DAMAGES FOR LOSS OF USE, LOST PROFITS OR LOST SAVINGS, EVEN IF Payyd
          OR ANY OF ITS LAWFUL AGENTS OR EMPLOYEES HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES OR CLAIM. IN NO EVENT WILL Payyd, ITS
          AFFILIATES, AGENTS, LICENSORS, SUPPLIERS OR THEIR RESPECTIVE
          DIRECTORS, OFFICERS OR EMPLOYEES BE LIABLE FOR DAMAGES OR LOSSES
          RESULTING FROM VIRUSES, DATA CORRUPTION, FAILED MESSAGES, TRANSMISSION
          ERRORS OR PROBLEMS; TELECOMMUNICATIONS SERVICE PROVIDERS; LINKS TO
          THIRD PARTY Website & ApplicationsS; THE INTERNET BACKBONE; PERSONAL
          INJURY; THIRD PARTY CONTENT, PRODUCTS OR SERVICES; DAMAGES OR LOSSES
          CAUSED BY YOU OR YOUR RESPECTIVE EMPLOYEES, AGENTS OR SUBCONTRACTORS;
          LOSS OF USE OR LACK OF AVAILABILITY OF FACILITIES, INCLUDING COMPUTER
          RESOURCES, ROUTERS, AND STORED DATA; THE USE OR INABILITY TO USE THIS
          Website & Applications OR THE CONTENT; ANY OTHER Website &
          Applications ACCESSED TO OR FROM THIS Website & Applications; OR
          EVENTS BEYOND THE REASONABLE CONTROL OF Payyd, EVEN IF Payyd OR ANY OF
          ITS LAWFUL AGENTS OR EMPLOYEES HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES OR CLAIM. IN NO CASE WILL Payyd’S, ITS AFFILIATES,
          AGENTS, LICENSORS, SUPPLIERS AND THEIR RESPECTIVE DIRECTORS, OFFICERS
          AND EMPLOYEES CUMULATIVE TOTAL LIABILITY ARISING UNDER ANY CAUSE
          WHATSOEVER (INCLUDING WITHOUT LIMITATION BREACH OF CONTRACT, TORT,
          NEGLIGENCE, GROSS NEGLIGENCE OR OTHERWISE) BE FOR MORE THAN TWENTY
          DOLLARS $20.00. Payyd ASSUMES NO OBLIGATION TO UPDATE THE CONTENT ON
          THIS SITE. THE CONTENT ON THIS SITE MAY BE CHANGED WITHOUT NOTICE TO
          YOU. Payyd IS NOT RESPONSIBLE FOR ANY CONTENT OR INFORMATION THAT YOU
          MAY FIND UNDESIRABLE OR OBJECTIONABLE. Payyd DISCLAIMS ANY LIABILITY
          FOR UNAUTHORIZED USE OR REPRODUCTION OF ANY PORTION OF THE Website &
          Applications. ACCESSING THE CONTENT FROM TERRITORIES WHERE IT MAY BE
          ILLEGAL IS PROHIBITED.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          8. Termination. This Agreement is effective until terminated by Payyd,
          with or without cause, in Payyd`s sole and unfettered discretion.
          Payyd may terminate this Agreement without notice to You for any
          reason or for no reason. Any such termination by Payyd shall be in
          addition to and without prejudice to such rights and remedies as may
          be available to Payyd, including injunction and other equitable
          remedies.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          The disclaimers, limitations on liability, ownership, termination,
          interpretation, Your license to Payyd, Your warranty and the indemnity
          provisions of this Agreement shall survive the termination or expiry
          of this Agreement.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          9. Indemnity. You agree at all times to indemnify, defend and hold
          harmless Payyd, its agents, suppliers, licensors, affiliates and their
          respective directors and employees against all actions, proceedings,
          costs, claims, damages, demands, liabilities and expenses whatsoever
          (including legal and other fees and disbursements) sustained, incurred
          or paid by Payyd directly or indirectly in respect of: any information
          or other content You provide on or through this Website & Applications
          or which is sent to Payyd by email or other correspondence; or Your
          use or misuse of the Content or this Website & Applications, including
          without limitation infringement claims.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          10. Governing Law. Payyd, this Website & Applications, and the Content
          (excluding linked Website & Applicationss or content) are physically
          located within the State where Payyd has its registered address. This
          Agreement shall be governed pursuant to the laws of the State of
          Wyoming, USA. Any and all disputes arising under this Agreement shall
          be settled exclusively and directly with Payyd and all other legal
          recourse is waived due to the cost burden associated with such
          processes. You agree to waive any right You may have to including: (i)
          a trial by jury; and (ii) the commencement of or participation in any
          class action against Payyd related to this Website & Applications, You
          also agree to opt-out of any class proceedings against Payyd or its
          licensors.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          11. Interpretation. The division of this Agreement into sections and
          the insertion of headings are for convenience of reference only and
          shall not affect the construction or interpretation of this Agreement.
          In this Agreement, words importing the singular number include the
          plural and vice versa; words importing gender include all genders; and
          words importing persons include individuals, sole proprietors,
          partnerships, corporations, trusts, and unincorporated associations.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          12. Entire Agreement. This Agreement as it may be amended from time to
          time in accordance with the provisions of Section 12, and any and all
          other legal notices and policies on this Website & Applications,
          constitutes the entire agreement between You and Payyd with respect to
          the use of this Website & Applications and the Content.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          13. Amendment and Waiver. Payyd reserves the right, in its discretion,
          to amend this Agreement at any time by posting amendments on this
          Website & Applications. You are responsible for periodically reviewing
          the amendments on this Website & Applications, and You are deemed to
          be aware of such amendments. If You do not agree to the amended terms
          and conditions, You shall immediately stop using this Website &
          Applications. Access to this Website & Applications or use of this
          Website & Applications after any amendments have been posted shall
          constitute Your acknowledgment and acceptance of the amended terms and
          conditions. No supplement, modification or amendment to this Agreement
          and no waiver of any provision of this Agreement shall be binding on
          Payyd unless executed by Payyd in writing. No waiver of any of the
          provisions of this Agreement shall be deemed to be or shall constitute
          a waiver of any other provision (whether or not similar) nor shall
          such waiver constitute a continuing waiver unless otherwise expressly
          provided.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          14. Severability. Any provision of this Agreement which is held by a
          court of competent jurisdiction to be illegal, invalid or
          unenforceable in such jurisdiction shall, as to that jurisdiction, be
          ineffective to the extent of such illegality, invalidity or
          unenforceability and shall otherwise be enforced to the maximum extent
          permitted by law, all without affecting the remaining provisions of
          this Agreement or affecting the legality, validity or enforceability
          of such provision in any other jurisdiction.
        </Text>
        <Text lineHeight="20px" textAlign="left" pb={2}>
          15. Enurement. This Agreement shall inure to the benefit of and be
          binding upon each of us and our respective successors and permitted
          assigns. You acknowledge having read this Agreement before accepting
          it, having the authority to accept this Agreement and having received
          a copy of this Agreement.
        </Text>
        <Text
          lineHeight="20px"
          fontSize={18}
          fontWeight={600}
          textAlign="left"
          pb={2}
        >
          {new Date().getFullYear()} © Payyd. All Rights Reserved.
        </Text>
        <Button
          color="info"
          // variant="contained"
          size="large"
          onClick={() => setShowTermsModal(false)}
        >
          Got it
        </Button>
      </Stack>
    </Box>
  );
}

export default PrivacyPolicy;
