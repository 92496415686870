import React from 'react';
import Authenticate from 'features/Authenticate';
import * as S from 'pages/AuthPage/styles';

function AuthPage() {
  return (
    <S.AuthPage>
      <Authenticate />
    </S.AuthPage>
  );
}

export default AuthPage;
