import { Button } from '@mui/material';
import { handleLogout } from 'common/auth0';
import BottomNavInner from 'components/BottomNavInner';
import Box from 'components/Box';
import Logo from 'components/Logo';
import Text from 'components/Text';
import TopNav from 'components/TopNav';
import BankDetails from 'features/BankDetails';
import IDFYProcessing from 'features/IDFYProcessing';

import { selectWalletUser } from 'features/InvoicePay/slice';
import Varification from 'features/Varification';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

function InvoicePage() {
  const walletUser = useSelector(selectWalletUser);
  const sender = JSON.parse(localStorage.getItem('rapyydProp') || '{}');

  return (
    <Box>
      {!walletUser?.id && !sender.senderUserId ? (
        <Box
          height={100}
          width="100%"
          borderBottom="1px solid"
          borderColor="gray.1"
          display="flex"
          alignItems="center"
          style={{ height: '60px' }}
          justifyContent="center"
          px={{ _: 20, md: 40, lg: 100 }}
        >
          <Box
            maxWidth={1500}
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Logo invoice />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              // width="15%"
            >
              <Button variant="text" onClick={handleLogout}>
                <Text color="#3D4251">Log out</Text>
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <TopNav />
      )}
      <Box
        px={{ _: 20, md: 40, lg: 100 }}
        py={15}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Switch>
          <Route exact path="/invoice/pay/verification">
            <Varification />
          </Route>
          <Route exact path="/invoice/pay/bankDetails">
            <BankDetails />
          </Route>

          <Route exact path="/invoice/prcocessing">
            <IDFYProcessing />
          </Route>
          <Redirect from="/invoice" to="/dasboard" />
        </Switch>
      </Box>
      <BottomNavInner />
    </Box>
  );
}

export default InvoicePage;
