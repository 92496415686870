import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  background,
  layout,
  color,
  flexbox,
  border,
  position,
  typography,
  TypographyProps,
  SpaceProps,
  BackgroundProps,
  LayoutProps,
  ColorProps,
  FlexboxProps,
  BorderProps,
  PositionProps,
  system,
} from 'styled-system';

export interface BoxProps
  extends SpaceProps,
    BackgroundProps,
    LayoutProps,
    ColorProps,
    FlexboxProps,
    BorderProps,
    TypographyProps,
    PositionProps {
  children?: ReactNode;
  transition?: string;
  transform?: string;
  boxShadow?: string;
  cursor?: string;
  css?: string;
}

const Box = styled.div<BoxProps>(
  compose(
    space,
    typography,
    background,
    layout,
    color,
    flexbox,
    border,
    position,
  ),
  system({
    transition: true,
    transform: true,
    boxShadow: true,
    css: true,
    cursor: true,
  }),
);

export default Box;
