import { createTheme } from '@mui/material/styles';
import NetflixSansRegular from 'assets/fonts/NetflixSans-Regular.woff2';
import NetflixSansMedium from 'assets/fonts/NetflixSans-Medium.woff2';
import Urbanist from 'assets/fonts/Urbanist.woff2';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    round: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: '#0060FF',
    },
  },
  components: {
    // MuiTextField:{
    //   styleOverrides:{
    //     root: {
    //       fontSize:"5px",
    //       backgroundColor:"red",
    //       height:"30px",
    //     },
    //   }
    // },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: '30px',
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: '30px',
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'none',
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Netflix Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Netflix Sans'), url(${NetflixSansRegular}) format('woff2');
        }
        @font-face {
          font-family: 'Netflix Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Netflix Sans'), url(${NetflixSansMedium}) format('woff2');
        }
        @font-face {
          font-family: 'Urbanist';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Urbanist'), url(${Urbanist}) format('woff2');
        }
      `,
    },
  },
});

export default theme;
