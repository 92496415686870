import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Button, IconButton, Stack } from '@mui/material';
import Box from 'components/Box';
import Text from 'components/Text';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { ReactComponent as PreferencesIcon } from '../../assets/svg/preferences.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/editProfile.svg';
import { ReactComponent as LogoutIcon } from '../../assets/svg/signout.svg';
import { ReactComponent as EllipseIcon } from '../../assets/svg/Ellipse.svg';

import { handleGetSession, handleLogout } from 'common/auth0';
import { ReactComponent as QuestionIcon } from '../../assets/svg/question.svg';
import { useHistory } from 'react-router-dom';
import {
  useGetNotificationeByIdQuery,
  useGetWalletUserBySubQuery,
} from 'generated/graphql';
import { useSelector } from 'react-redux';
import { selectWalletUser } from 'features/InvoicePay/slice';

const DesktopNav = () => {
  const [showDropdown, setDropdown] = useState(false);
  const [showNotificationDropdown, setNotificationDropdown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const walletUser = useSelector(selectWalletUser);
  const wrapperRef = useRef(null);
  const notificationRef = useRef(null);

  useEffect(() => {
    newss();
  }, []);

  const [newss, { data: notification }] = useGetNotificationeByIdQuery({
    context: { clientName: 'subscribe' },
    // @ts-ignore
    variables: { userId: walletUser?.id },
  });
  useEffect(() => {
    // @ts-ignore
    if (notification?.subscription_activitiesByUserId?.nodes?.length > 0) {
      // @ts-ignore
      setNotifications(notification?.subscription_activitiesByUserId?.nodes);
    }
  }, [notification]);

  const currentSession = handleGetSession();
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        console.log('clicked');
        // setDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  useEffect(() => {
    function handleClickOutside(event) {
      // @ts-ignore
      if (
        notificationRef.current &&
        // @ts-ignore
        !notificationRef.current.contains(event.target)
      ) {
        setNotificationDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notificationRef]);
  const history = useHistory();
  const { data: walletUserData } = useGetWalletUserBySubQuery({
    // @ts-ignore
    variables: { sub: currentSession?.sub },
  });
  return (
    <Box position="relative">
      <Stack spacing={2} direction="row" alignItems="center">
        <Box
          display="flex"
          width="108px"
          height="44px"
          borderRadius="100px"
          pl={2}
          pr={2}
          backgroundColor="#F5F5F5"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => {
            history.push('/support');
          }}
        >
          <QuestionIcon />
          <Text fontWeight={600} style={{ cursor: 'pointer' }}>
            Support
          </Text>
        </Box>
        <IconButton
          aria-label="notification"
          ref={notificationRef}
          onClick={() => {
            setNotificationDropdown(!showNotificationDropdown);
            setDropdown(false);
          }}
        >
          <NotificationsNoneIcon />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          ref={wrapperRef}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setDropdown(!showDropdown);
            setNotificationDropdown(false);
          }}
        >
          <Avatar sx={{ width: 32, height: 32 }} alt="avatar" src="" />
          <Text ml={2}>
            {
              // @ts-ignore
              walletUserData?.wallet_userBySub?.firstName ||
                walletUserData?.wallet_userBySub?.legalEntity?.legalName
            }
          </Text>
          <KeyboardArrowDownIcon />
        </Box>
      </Stack>

      <Box
        display={showDropdown ? 'block' : 'none'}
        p={20}
        width="200px"
        position="absolute"
        top={50}
        right={0}
        zIndex={1000}
        backgroundColor="#fafafa"
        borderRadius={18}
        boxShadow="0 0 10px rgba(0,0,0,0.1)"
      >
        <Box
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          display="flex"
          flexDirection="row"
        >
          <EditIcon style={{ marginRight: '10px' }} />
          <Button
            variant="text"
            style={{ color: '#3D4251', fontWeight: 600 }}
            onClick={() => {
              history.push('/editprofile');
            }}
          >
            Edit Profile
          </Button>
        </Box>
        <hr
          style={{
            color: '#B6C9DB',
            backgroundColor: '#B6C9DB',
            height: 0,
          }}
        />
        <Box
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          display="flex"
          flexDirection="row"
        >
          <PreferencesIcon style={{ marginRight: '10px' }} />
          <Button
            variant="text"
            style={{ color: '#3D4251', fontWeight: 600 }}
            onClick={() => {
              history.push('/preferences');
            }}
          >
            Preferences
          </Button>
        </Box>
        <hr
          style={{
            color: '#B6C9DB',
            backgroundColor: '#B6C9DB',
            height: 0,
          }}
        />

        <Box
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          display="flex"
          flexDirection="row"
        >
          <LogoutIcon style={{ marginRight: '10px' }} />
          <Button
            variant="text"
            style={{ color: '#3D4251', fontWeight: 600 }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Box>
      <Box
        display={showNotificationDropdown ? 'block' : 'none'}
        p={20}
        width="350px"
        position="absolute"
        top={50}
        right={80}
        backgroundColor="#fafafa"
        borderRadius={18}
        maxHeight={400}
        overflow="scroll"
        boxShadow="0 0 10px rgba(0,0,0,0.1)"
      >
        {notifications.length > 0 ? (
          notifications.map((e, index) => {
            return (
              <Box key={index} zIndex={100}>
                <Box
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                  display="flex"
                  flexDirection="row"
                >
                  <Box display="flex" alignItems="center">
                    <EllipseIcon style={{ marginRight: '10px' }} />
                    <Text fontSize={15} color="#000">
                      {
                        // @ts-ignore
                        e.shortDesc
                      }
                    </Text>
                  </Box>
                  <Text fontSize={12}>
                    {
                      // @ts-ignore
                      e.createdOn.split('T')[0]
                    }
                  </Text>
                </Box>
                <hr
                  style={{
                    color: '#B6C9DB',
                    backgroundColor: '#B6C9DB',
                    height: 0,
                  }}
                />
              </Box>
            );
          })
        ) : (
          <Text>No Notifications</Text>
        )}
      </Box>
    </Box>
  );
};

export default DesktopNav;
