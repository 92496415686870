import { message } from 'antd';
import React from 'react';

import Auth0, { handleClearTokens, handleLogout } from 'common/auth0';
import * as S from 'features/Authenticate/styles';
import localStorage from 'localStorage';
import { useHistory } from 'react-router-dom';

export default () => {
  const history = useHistory();
 
  React.useEffect(() => {
    Auth0.parseHash({ hash: history.location.hash }, (err, authResult) => {
      if (authResult && authResult.idToken) {
        const invoice = localStorage.getItem('invoice') || '';
        handleClearTokens();

        localStorage.setItem('auth', authResult.accessToken);
        localStorage.setItem('nerUserAddress', authResult.idTokenPayload.email);
        localStorage.setItem('invoice', invoice);
        if (invoice !== '') {
          history.push(`/checkout?invoice=${invoice}`);
        } else {
            history.push('/dashboard');
        }
      } else if (err) {
        message.error(err.errorDescription || 'Unable to Validate!');
        handleLogout();
        history.push('/login');
      }
    });
  }, [history]);

  return <S.Authenticate />;
};
