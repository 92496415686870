import React, { useState } from 'react';
import Box from 'components/Box';
import Text from 'components/Text';
import {
  Dialog,
  Stack,
  Button,
  DialogProps,
  experimentalStyled as styled,
} from '@mui/material';
import { ReactComponent as SvgBulkPay } from 'assets/svg/bulk-pay.svg';
import { ReactComponent as SvgPayNow } from 'assets/svg/pay-now.svg';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {
  TransactionPayConfigInput,
  useUpdateUserConfig,
} from 'generated/graphql';
import { selectWalletUser } from 'features/InvoicePay/slice';
import { useSelector } from 'react-redux';
import { message } from 'antd';

const BlurryDialog = styled(Dialog)<DialogProps>(() => ({
  backdropFilter: 'blur(5px)',
  // other styles here...
}));

const CurrentBalance = () => {
  const [showPayNow, setShowPayNow] = useState(false);
  const [showBulkPay, setShowBulkPay] = useState(false);
  const [updateUserConfig] = useUpdateUserConfig();
  const walletUser = useSelector(selectWalletUser);

  const handlePay = async (res) => {
    const createPayload: TransactionPayConfigInput = {
      userId: walletUser.id,
      payNow: res,
    };
    const { data } = await updateUserConfig({
      variables: { data: createPayload },
    });
    // @ts-ignore
    if (data?.wallet_updateUserPayConfig?.data) {
      setShowPayNow(false);
      message.info('Thank you for your Resoponse');
    }
  };
  const handleBulkPay = async (res) => {
    const createPayload: TransactionPayConfigInput = {
      userId: walletUser.id,
      bulkPay: res,
    };
    const { data } = await updateUserConfig({
      variables: { data: createPayload },
    });
    // @ts-ignore
    if (data?.wallet_updateUserPayConfig?.data) {
      setShowBulkPay(false);
      message.info('Thank you for your Resoponse!');
    }
  };
  return (
    <Box width={{ _: '100%', md: 470 }} ml={{ _: 0, md: 40 }}>
      <Box maxWidth={{ _: '100', md: 340 }}>
        <Stack direction="row" spacing={2}>
          <Box
            backgroundColor="#FFCBDE"
            height={64}
            borderRadius={8}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => setShowPayNow(true)}
            role="button"
            cursor="pointer"
            flex={1}
          >
            <SvgPayNow />
            <Text ml={1} mt="2px" color="#D32E69" fontSize={14}>
              Pay Now
            </Text>
          </Box>
          <Box
            backgroundColor="#FFD9B5"
            height={64}
            borderRadius={8}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => setShowBulkPay(true)}
            role="button"
            cursor="pointer"
            flex={1}
          >
            <SvgBulkPay />
            <Text ml={1} mt="2px" color="#C47F3E" fontSize={14}>
              Bulk Pay
            </Text>
          </Box>
        </Stack>
      </Box>

      <BlurryDialog onClose={() => setShowPayNow(false)} open={showPayNow}>
        <Box maxWidth={375} padding={40} borderRadius="16px">
          <Stack spacing={2} direction="column" alignItems="center">
            <Box display="flex" alignItems="center">
              <SvgPayNow />
              <Text ml={2} fontSize={18}>
                Pay Now
              </Text>
            </Box>
            <Text lineHeight="20px" textAlign="center" pb={2}>
              Pay anyone is launching soon.
            </Text>
            <Stack spacing={2} direction="row">
              <Button
                color="info"
                variant="contained"
                onClick={() => handlePay(false)}
                endIcon={<ThumbDownIcon />}
              >
                Naaah
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePay(true)}
                endIcon={<ThumbUpAltIcon />}
              >
                Upvote
              </Button>
            </Stack>
          </Stack>
        </Box>
      </BlurryDialog>
      <BlurryDialog onClose={() => setShowBulkPay(false)} open={showBulkPay}>
        <Box maxWidth={375} padding={40} borderRadius={8}>
          <Stack spacing={2} direction="column" alignItems="center">
            <Box display="flex" alignItems="center">
              <SvgBulkPay />
              <Text ml={2} fontSize={18}>
                Bulk Pay
              </Text>
            </Box>
            <Text lineHeight="20px" textAlign="center" pb={2}>
              {`Bulk pay 100's of recipients is launching soon.`}
            </Text>
            <Stack spacing={2} direction="row">
              <Button
                color="info"
                variant="contained"
                onClick={() => handleBulkPay(false)}
                endIcon={<ThumbDownIcon />}
              >
                Naaah
              </Button>
              <Button
                variant="contained"
                onClick={() => handleBulkPay(true)}
                endIcon={<ThumbUpAltIcon />}
              >
                Upvote
              </Button>
            </Stack>
          </Stack>
        </Box>
      </BlurryDialog>
    </Box>
  );
};

export default CurrentBalance;
