import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities/policies/pagination';
import { GRAPHQL_API, GRAPHQL_SUBSCRIPTION_API } from 'common/constants';
import { getUTCNonce } from 'common/utils';
import cryptoJS from 'crypto-js';
import { GraphQLError } from 'graphql';
// import { ResponseError } from 'types/globalTypes';
import { handleLogout } from 'common/auth0';
import { v4 as uuidv4 } from 'uuid';

enum JWTError {
  invalid = 'InvalidTokenError',
  invalidSignature = 'InvalidSignatureError',
  expired = 'ExpiredSignatureError',
}

const errorLink = onError((error: any) => {
  const { networkError } = error;
  const graphQLErrors = error?.graphQLErrors || [];
  const statusCode = networkError?.statusCode;

  // check if any error codes match JWT specific codes
  const isJwtError: boolean = graphQLErrors.some((gqlError: GraphQLError) => {
    return (<any>Object)
      .values(JWTError)
      .includes(gqlError.extensions?.exception?.code);
  });

  if (statusCode === 401 || isJwtError) {
    handleLogout();
  }
});

const authLink = setContext(async ({ variables }, context) => {
  const payload = JSON.stringify(variables);
  const authToken = localStorage.getItem('auth');
  const nonce = getUTCNonce();

  const apiKey = process.env.REACT_APP_API_PUBLIC_KEY;
  const secretKey = process.env.REACT_APP_API_SECRET_KEY!;
  const textCombination =
    payload.length > 2
      ? `${nonce}::${payload}::${authToken || apiKey}`
      : `${nonce}::${apiKey}::${authToken || apiKey}`;
  const sign = cryptoJS.HmacSHA256(textCombination, secretKey).toString();
  const GUID = uuidv4();

  return {
    ...context,
    headers: {
      ...context.headers,
      // 'x-api-key': process.env.REACT_APP_API_PUBLIC_KEY,
      'x-api-key': apiKey,
      'x-nonce': nonce,
      'x-sign': sign,
      'payyd-requestid': GUID,
      Authorization: authToken ? `Bearer ${authToken}` : '',
    },
  };
});

const httpLink = new HttpLink({ uri: GRAPHQL_API });
const httpSubLink = new HttpLink({ uri: GRAPHQL_SUBSCRIPTION_API });

const additiveLinks = from([errorLink, authLink, httpLink]);
const additiveSubLinks = from([errorLink, authLink, httpSubLink]);
export default new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'subscribe',
    // the string "third-party" can be anything you want,
    // we will use it in a bit
    additiveSubLinks, // <= apollo will send to this if clientName is "third-party"
    additiveLinks, // <= otherwise will send to this
  ),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // Reusable helper function to generate a field
          // policy for the Query.search field, keyed by
          // search query:
          // products: relayStylePagination(['query']),
          categories: relayStylePagination(['query']),
        },
      },
    },
  }),
});
