import auth0 from 'auth0-js';
import jwt from 'jsonwebtoken';
import localStorage from 'localStorage';

// curl
// --request POST
// --url "https://payydmoney.us.auth0.com/oauth/token"
// --header "content-type: application/x-www-form-urlencoded"
// --data "grant_type=client_credentials"
// --data "client_id=KBvlwo6p1Y4VC3CoZmF62AdKLDI5fSEg"
// --data "client_secret=NlILalVZNKfPuscT2TBwRw2DZoXcrwVS6vQK3k_2QySCoa0XIuKQbsr4DA_B6vEJ"
// --data "audience=https://payydmoney.us.auth0.com/api/v2/"

const auth0Config = {
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid profile email',
  leeway: 30,
};

const Auth0 = new auth0.WebAuth(auth0Config);

export default Auth0;

function handleClearTokens() {
  localStorage.clear();
  const cookies = document.cookie.split(';');

  if (cookies.length) {
    // eslint-disable-next-line array-callback-return
    cookies.map((cookie) => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  }
}

function handleLogin() {
  Auth0.authorize({
    auth_database: process.env.REACT_APP_AUTH0_DATABASE,
    redirectUri: `${window.location.origin}/auth`,
  });
}

function handleLogout() {
  Auth0.logout({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    returnTo: process.env.REACT_APP_BASE_URL,
  });
  handleClearTokens();
}

function handleValidateToken() {
  const idTokenFromLocalStorage = localStorage.getItem('auth');
  try {
    const idToken = jwt.decode(idTokenFromLocalStorage);
    if (idToken) {
      // if idToken is expired
      if (new Date(new Date().getTime()) > new Date(idToken.exp * 1000)) {
        handleClearTokens();
        return false;
      }

      return true;
    }
    // if idToken is not a valid jwt
    handleClearTokens();
    return false;
  } catch (err) {
    // if idToken jwt is tampered
    handleClearTokens();
    return false;
  }
}

function handleGetSession() {
  const isValidToken = handleValidateToken();
  if (isValidToken) {
    try {
      const idTokenFromLocalStorage = localStorage.getItem('auth');
      return jwt.decode(idTokenFromLocalStorage);
    } catch (e) {
      return null;
    }
  }
  return null;
}

export {
  handleLogin,
  handleLogout,
  handleClearTokens,
  handleValidateToken,
  handleGetSession,
};
