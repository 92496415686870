// import { Paper } from '@mui/material';
import { Box } from '@mui/material';
import PayForm from 'features/PayForm';
import { useMediaQuery } from 'hooks/useMediaQuery';
import backgoundImg from '../../assets/backgound.png';
import backgoundWhite from '../../assets/backgoundWhite.png';

import BottomNav from 'components/BottomNav';
import Logo from 'components/Logo';

const stylesDesk = {
  paperContainer: {
    width: '100%',
    // maxWidth:"1500px",
    position: 'relative',
    overflow: 'hidden',
    height: '100vh',
    backgroundImage: `url(${backgoundImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    paddingLeft: '100px',
    paddingRight: '100px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#0048BE',
  },
};
const stylesMob = {
  paperContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'scroll',
    backgroundImage: `url(${backgoundWhite})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // paddingLeft: '20px',
    // paddingRight: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#fff',
  },
};

function PayPage() {
  const { isMobile } = useMediaQuery();
  return (
    <Box sx={!isMobile ? stylesDesk.paperContainer : stylesMob.paperContainer}>
      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            left: 20,
          }}
        >
          <Logo light />
        </Box>
      )}
      <PayForm />
      <BottomNav />
    </Box>
  );
}

export default PayPage;
